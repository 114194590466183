import EducationTopMenu from "../../components/education/EducationTopMenu";
import EducationMainMenuWithBackground from "../../components/education/EducationMainMenuWithBackground";
import EducationFooter from "../../components/education/EducationFooter";
import {Link} from "react-router-dom";
import routes from "../../constants/routes";

export default function Events() {
    return (
        <>
            <section id="hero">
                <EducationTopMenu/>
                <EducationMainMenuWithBackground background="/assets/images/edu_events_bg.png" height="25rem"/>
            </section>
            <main className="my-10">
                <div className="max-w-1170 mx-auto">
                    <h2 className="text-3xl font-bold mb-6 text-black">EVENTS</h2>
                    <div className="grid grid-cols-2 gap-2">
                        <Link to={`${routes.organization}/white-house-college/events/some-event`}>
                            <article className="bg-gray-100 p-4 flex">
                                <div style={{backgroundImage: "url(/assets/images/event_thumb1.png)"}}
                                     className="w-96 mr-6 bg-center"/>
                                {/*<img src="" alt="" className="w-52 mr-6"/>*/}
                                <div className="space-y-3 text-sm">
                                    <h3 className="font-bold text-xl">School Cleaning</h3>
                                    <p className="font-medium">
                                        Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                                    </p>
                                    <ul className="max-w-sm">
                                        <li className="flex items-baseline">
                                            <img className="inline-block w-3" src="/assets/images/calender.svg"
                                                 alt=""/>
                                            <span className="ml-2">15 Jul, 2021-10 Aug, 2021</span>
                                        </li>
                                        <li className="flex items-baseline">
                                            <img className="inline-block w-3" src="/assets/images/user_org.svg"
                                                 alt=""/>
                                            <span className="ml-2">Himalayan College Department</span>
                                        </li>
                                        <li className="flex items-baseline">
                                            <img className="inline-block w-3" src="/assets/images/time.svg"
                                                 alt=""/>
                                            <span className="ml-2">10:00 AM - 5:00 PM</span>
                                        </li>
                                        <li className="flex items-baseline">
                                            <img className="inline-block w-2.5" src="/assets/images/location_pin.svg"
                                                 alt=""/>
                                            <span className="ml-2">Chyasal, Lalitpur</span>
                                        </li>
                                    </ul>
                                    <div className="flex space-x-2 items-center text-center">
                                        <span
                                            className="block py-2 bg-education rounded-md text-white flex-grow">Ongoing</span>
                                        <span className="block py-2  bg-gray-400 rounded-md text-white flex-grow">
                                            <img src="/assets/images/share_white.svg" alt="" className="inline w-4 mr-2"/>
                                            Share
                                        </span>
                                    </div>
                                </div>
                            </article>
                        </Link>
                        <Link to={`${routes.organization}/white-house-college/events/some-event`}>
                            <article className="bg-gray-100 p-4 flex">
                                <div style={{backgroundImage: "url(/assets/images/event_thumb2.png)"}}
                                     className="w-96 mr-6 bg-center"/>
                                {/*<img src="" alt="" className="w-52 mr-6"/>*/}
                                <div className="space-y-3 text-sm">
                                    <h3 className="font-bold text-xl">School Cleaning</h3>
                                    <p className="font-medium">
                                        Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                                    </p>
                                    <ul className="max-w-sm">
                                        <li className="flex items-baseline">
                                            <img className="inline-block w-3" src="/assets/images/calender.svg"
                                                 alt=""/>
                                            <span className="ml-2">15 Jul, 2021-10 Aug, 2021</span>
                                        </li>
                                        <li className="flex items-baseline">
                                            <img className="inline-block w-3" src="/assets/images/user_org.svg"
                                                 alt=""/>
                                            <span className="ml-2">Himalayan College Department</span>
                                        </li>
                                        <li className="flex items-baseline">
                                            <img className="inline-block w-3" src="/assets/images/time.svg"
                                                 alt=""/>
                                            <span className="ml-2">10:00 AM - 5:00 PM</span>
                                        </li>
                                        <li className="flex items-baseline">
                                            <img className="inline-block w-2.5" src="/assets/images/location_pin.svg"
                                                 alt=""/>
                                            <span className="ml-2">Chyasal, Lalitpur</span>
                                        </li>
                                    </ul>
                                    <div className="flex space-x-2 items-center text-center">
                                        <span
                                            className="block py-2 bg-education rounded-md text-white flex-grow">Ongoing</span>
                                        <span className="block py-2  bg-gray-400 rounded-md text-white flex-grow">
                                            <img src="/assets/images/share_white.svg" alt="" className="inline w-4 mr-2"/>
                                            Share
                                        </span>
                                    </div>
                                </div>
                            </article>
                        </Link>
                        <Link to={`${routes.organization}/white-house-college/events/some-event`}>
                            <article className="bg-gray-100 p-4 flex">
                                <div style={{backgroundImage: "url(/assets/images/event_thumb2.png)"}}
                                     className="w-96 mr-6 bg-center"/>
                                {/*<img src="" alt="" className="w-52 mr-6"/>*/}
                                <div className="space-y-3 text-sm">
                                    <h3 className="font-bold text-xl">School Cleaning</h3>
                                    <p className="font-medium">
                                        Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                                    </p>
                                    <ul className="max-w-sm">
                                        <li className="flex items-baseline">
                                            <img className="inline-block w-3" src="/assets/images/calender.svg"
                                                 alt=""/>
                                            <span className="ml-2">15 Jul, 2021-10 Aug, 2021</span>
                                        </li>
                                        <li className="flex items-baseline">
                                            <img className="inline-block w-3" src="/assets/images/user_org.svg"
                                                 alt=""/>
                                            <span className="ml-2">Himalayan College Department</span>
                                        </li>
                                        <li className="flex items-baseline">
                                            <img className="inline-block w-3" src="/assets/images/time.svg"
                                                 alt=""/>
                                            <span className="ml-2">10:00 AM - 5:00 PM</span>
                                        </li>
                                        <li className="flex items-baseline">
                                            <img className="inline-block w-2.5" src="/assets/images/location_pin.svg"
                                                 alt=""/>
                                            <span className="ml-2">Chyasal, Lalitpur</span>
                                        </li>
                                    </ul>
                                    <div className="flex space-x-2 items-center text-center">
                                        <span
                                            className="block py-2 bg-education rounded-md text-white flex-grow">Ongoing</span>
                                        <span className="block py-2  bg-gray-400 rounded-md text-white flex-grow">
                                            <img src="/assets/images/share_white.svg" alt="" className="inline w-4 mr-2"/>
                                            Share
                                        </span>
                                    </div>
                                </div>
                            </article>
                        </Link>
                        <Link to={`${routes.organization}/white-house-college/events/some-event`}>
                            <article className="bg-gray-100 p-4 flex">
                                <div style={{backgroundImage: "url(/assets/images/event_thumb1.png)"}}
                                     className="w-96 mr-6 bg-center"/>
                                {/*<img src="" alt="" className="w-52 mr-6"/>*/}
                                <div className="space-y-3 text-sm">
                                    <h3 className="font-bold text-xl">School Cleaning</h3>
                                    <p className="font-medium">
                                        Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                                    </p>
                                    <ul className="max-w-sm">
                                        <li className="flex items-baseline">
                                            <img className="inline-block w-3" src="/assets/images/calender.svg"
                                                 alt=""/>
                                            <span className="ml-2">15 Jul, 2021-10 Aug, 2021</span>
                                        </li>
                                        <li className="flex items-baseline">
                                            <img className="inline-block w-3" src="/assets/images/user_org.svg"
                                                 alt=""/>
                                            <span className="ml-2">Himalayan College Department</span>
                                        </li>
                                        <li className="flex items-baseline">
                                            <img className="inline-block w-3" src="/assets/images/time.svg"
                                                 alt=""/>
                                            <span className="ml-2">10:00 AM - 5:00 PM</span>
                                        </li>
                                        <li className="flex items-baseline">
                                            <img className="inline-block w-2.5" src="/assets/images/location_pin.svg"
                                                 alt=""/>
                                            <span className="ml-2">Chyasal, Lalitpur</span>
                                        </li>
                                    </ul>
                                    <div className="flex space-x-2 items-center text-center">
                                        <span
                                            className="block py-2 bg-education rounded-md text-white flex-grow">Ongoing</span>
                                        <span className="block py-2  bg-gray-400 rounded-md text-white flex-grow">
                                            <img src="/assets/images/share_white.svg" alt="" className="inline w-4 mr-2"/>
                                            Share
                                        </span>
                                    </div>
                                </div>
                            </article>
                        </Link>
                    </div>
                </div>
            </main>
            <EducationFooter/>
        </>
    )
}