import {useState} from "react";
import {Link} from "react-router-dom";
import routes from "../constants/routes";
import UserMenu from "./UserMenu";

export default function HomeHeader() {

    const [showMegaMenu, setShowMegaMenu] = useState(false);

    const handleMegaMenu = () => {
        setShowMegaMenu((prevState) => !prevState)
    }

    return (
        <header className="mx-4 my-4 md:mx-8">
            <div className="flex justify-between items-center h-10">
                <div className="sm:hidden">
                    <button onClick={handleMegaMenu}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24"
                             stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                  d="M4 6h16M4 12h16M4 18h7"/>
                        </svg>
                    </button>
                </div>
                <nav className="space-x-4 hidden sm:block">
                    <Link to={routes.voice} className="px-3 py-1 rounded-full hover:bg-gray-100">Voice</Link>
                    <a href={`//news.${process.env.REACT_APP_BASE_DOMAIN}`}
                       className="px-3 py-1 rounded-full hover:bg-gray-100">News</a>
                    {/*<a href={process.env.NODE_ENV === "development" ? "http://news.react.main.staging.findall.com.np:3000/" : `//news.${process.env.REACT_APP_BASE_DOMAIN}`} className="px-3 py-1 rounded-full hover:bg-gray-100">News</a>*/}
                    <Link to={routes.jobs} className="px-3 py-1 rounded-full hover:bg-gray-100">Jobs</Link>
                    {process.env.NODE_ENV === "development" &&
                        <Link to={routes.shop} className="px-3 py-1 rounded-full bg-bColor text-white">Shop Now</Link>
                    }
                </nav>
                <UserMenu/>
            </div>
            {showMegaMenu &&
                <Drawer handleMenu={handleMegaMenu}/>
            }
        </header>
    )
}

function Drawer({handleMenu}) {
    return (
        <div
            className="h-screen w-screen fixed top-0 left-0 bottom-0 z-50 py-8 px-4 bg-purple-900 bg-gradient-to-r from-purple-100 to-indigo-100">
            <div className="relative flex items-center justify-center h-full">
                <p className="absolute top-4 left-6 text-2xl">- f -</p>
                <div className="absolute top-4 right-6">
                    <button onClick={handleMenu}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 align-middle inline" fill="none"
                             viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                  d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"/>
                        </svg>
                    </button>
                </div>
                <div className="flex items-center justify-center flex-col">
                    <span className="text-xs tracking-widest mb-8 ">MAIN MENU</span>
                    <div className="space-y-2 flex flex-col items-center justify-center text-xl" onClick={handleMenu}>
                        <Link to={routes.voice} className="px-3 py-1 rounded-full hover:text-gray-500">Voice</Link>
                        <Link to={routes.news} className="px-3 py-1 rounded-full hover:text-gray-500">News</Link>
                        <Link to={routes.jobs} className="px-3 py-1 rounded-full hover:text-gray-500">Jobs</Link>
                        <Link to={routes.shop} className="px-3 py-1 rounded-full bg-bColor text-white">Shop Now</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}