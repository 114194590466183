import {Helmet} from "react-helmet-async";

export default function GenericOrganizationAbout({data}) {
    return (
        <>
            <Helmet>
                <meta property="og:title" content={`About ${data.name}`}/>
                <meta property="og:url" content={`https://findallnepal.com/${data.slug}/about`}/>
                <link rel="canonical" href={`https://findallnepal.com/${data.slug}/about`}/>
                <title>{`About ${data.name}`}</title>
            </Helmet>
            <main>
                <section id="hero">
                    <div className="bg-gray-400 w-full h-64 relative">
                        <div className="bg-transparent w-full h-64 bg-center bg-no-repeat filter brightness-50"
                             style={{backgroundImage: `url(${data.featuredImage?.path})` || ""}}/>
                        <h1 className="text-center text-4xl font-bold text-white absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2">
                            About
                        </h1>
                    </div>
                </section>
                <section id="about">
                    <div className="mx-auto max-w-1000 mb-16 my-14">
                        <div dangerouslySetInnerHTML={{__html: data.description}}/>
                    </div>
                </section>
            </main>
        </>
    )
}