import {Link} from "react-router-dom";
import routes from "../../constants/routes";

export default function ShopHeader() {
    return (
        <header className="bg-bColor text-white px-4 py-1 text-sm">
            <div className="flex justify-between items-center max-w-screen-2xl mx-auto">
                <Link to="/" className="font-playfair text-lg">Nepal's Online Shopping Destination</Link>
                <nav className="space-x-8">
                    <a href="/" className="rounded-lg text-bColor px-3 py-1 bg-white">Download App</a>
                    <a href="/">Sell</a>
                    <Link to={routes.wishList}>Wishlist</Link>
                    <a href="/">Feedback</a>
                    <a href="/">Help</a>
                    <a href="/">Feedback</a>
                </nav>
                <div className="h-10 flex items-center">
                    <span>Nirajan Rawal</span>
                    <img className="inline ml-3 w-10 " src="/assets/images/dp.png" alt=""/>
                </div>
            </div>
        </header>
    )
}