import {Link} from "react-router-dom";

export default function EducationFooter() {
    return (
        <footer className="bg-education">
            <div className="max-w-1170 mx-auto py-12 text-white">
                <div className="grid grid-cols-3 gap-4">
                    <div className="mr-12">
                        <h3 className="text-xl font-medium mb-4 uppercase">Product</h3>
                        <ul className="grid grid-cols-2">
                            <li className="edu_list leading-none"><Link to="#" className="ml-2">Events</Link></li>
                            <li className="edu_list leading-none"><Link to="#" className="ml-2">About Us</Link></li>
                            <li className="edu_list leading-none"><Link to="#" className="ml-2">Job</Link></li>
                            <li className="edu_list leading-none"><Link to="#" className="ml-2">Contact Us</Link></li>
                            <li className="edu_list leading-none"><Link to="#" className="ml-2">Gallery</Link></li>
                            <li className="edu_list leading-none"><Link to="#" className="ml-2">Review</Link></li>
                            <li className="edu_list leading-none"><Link to="#" className="ml-2">Teams</Link></li>
                        </ul>
                    </div>
                    <div className="justify-center">
                        <h3 className="text-xl font-medium mb-4 uppercase">Contact Us</h3>
                        <ul className="max-w-sm space-y-1.5">
                            <li className="flex items-baseline"><img className="inline-block w-3" src="/assets/images/edu_location.svg"
                                                  alt=""/><span className="ml-4">Chyasal, Lalitpur</span></li>
                            <li className="flex items-baseline"><img className="inline-block w-3" src="/assets/images/edu_phone.svg"
                                                  alt=""/><span className="ml-4">01-5522484, 01-5522484, 9867254986, 9823050306</span>
                            </li>
                            <li className="flex items-baseline"><img className="inline-block w-3" src="/assets/images/edu_mail.svg"
                                                  alt=""/><span className="ml-4">info@himalayacollege.edu.np</span></li>
                        </ul>
                    </div>
                    <div className="justify-self-end">
                        <div className="flex items-center ">
                            <span className="mr-8 text-xl">Follow us:</span>
                            <a href="/"><img src="/assets/images/instagram.svg" alt="" width={20}
                                             className="inline-block mr-2"/></a>
                            <a href="/"><img src="/assets/images/facebook.svg" alt="" width={20}
                                             className="inline-block mr-2"/></a>
                            <a href="/"><img src="/assets/images/linkedin.svg" alt="" width={20}
                                             className="inline-block mr-2"/></a>
                            <a href="/"><img src="/assets/images/twitter.svg" alt="" width={20}
                                             className="inline-block mr-2"/></a>
                            <a href="/"><img src="/assets/images/youtube.svg" alt="" width={22}
                                             className="inline-block mr-2"/></a>
                        </div>
                        <div className="mt-6">
                            <input type="email" className="rounded-l-md bg-white text-black px-4 py-2" placeholder="Your email"/>
                            <button className="rounded-r-md px-5 py-2 bg-gray-700 text-white">SUBSCRIBE</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mx-5 border-t border-blue-300">
                <div className="max-w-1170 mx-auto py-6 text-white text-sm flex justify-between">
                    <span>© Copyrights 2021 <strong><Link to="#">Himalayan Engineering College</Link></strong>. All rights reserved.</span>
                    <span>Powered by: <strong><Link to="#">Find All Nepal</Link></strong></span>
                </div>
            </div>
        </footer>
    )
}