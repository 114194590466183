import ShopHeader from "../../components/shop/ShopHeader";
import {Route, Routes} from "react-router-dom";
import routes from "../../constants/routes";
import ShopHome from "./ShopHome";
import Category from "./Category";
import Product from "./Product";
import Cart from "./Cart";
import WishList from "./WishList";
import CheckoutInformation from "./CheckoutInformation";
import CheckoutPreview from "./CheckoutPreview";
import Payment from "./Payment";
import OrderConfirmed from "./OrderConfirmed";
import MainFooter from "../../components/MainFooter";

export default function Shop() {

    return (
        <>
            <div className="flex-grow">
                <ShopHeader/>
                <Routes>
                    <Route path={`${routes.orderConfirmed}`} element={<OrderConfirmed/>}/>
                    <Route path={`${routes.payment}`} element={<Payment/>}/>
                    <Route path={`${routes.checkoutPreview}`} element={<CheckoutPreview/>}/>
                    <Route path={`${routes.checkoutInformation}`} element={<CheckoutInformation/>}/>
                    <Route path={`${routes.wishList}`} element={<WishList/>}/>
                    <Route path={`${routes.cart}`} element={<Cart/>}/>
                    <Route path={`${routes.product}/:productName`} element={<Product/>}/>
                    <Route path={`${routes.productCategory}/:catName`} element={<Category/>}/>
                    <Route path={"/"} element={<ShopHome/>}/>
                </Routes>
            </div>
            <MainFooter/>
        </>
    )
}