import HomeHeader from "../components/HomeHeader";
import MainFooter from "../components/MainFooter";
import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import {
    useGetAllStateListQuery,
    useGetDirectoryListQuery,
    useGetDistrictListQuery,
    useGetNagarListQuery
} from "../services/directoryApi";
import ThreeDotsLoader from "../components/ThreeDotsLoader";
import clsx from "clsx";
import ReactPaginate from "react-paginate";
import DirectionDialog from "../components/DirectionDialog";
import SearchbarOnly2 from "../components/SearchbarOnly2";
import {useQuery} from "../others/useQuery";
import {Helmet} from "react-helmet-async";

const itemsPerPage = 10;

export default function SearchByQuery() {

    const query = useQuery();
    const [pageCount, setPageCount] = useState(0);

    const [isOpen, setIsOpen] = useState(false)

    const [itemOffset, setItemOffset] = useState(0);

    const [filter, setFilter] = useState({q: query.get("q"), places: "", extras: "&address&phone&email", page: 1});

    const {data: orgList, isFetching, isLoading} = useGetDirectoryListQuery(filter);
    const [filteredOrgList, setFilteredOrgList] = useState([]);

    const [selectedProvince, setSelectedProvince] = useState(null);
    const [selectedDistrict, setSelectedDistrict] = useState(null);
    const [selectedNagar, setSelectedNagar] = useState(null);

    const {data: stateList, isFetching: isFetchingState} = useGetAllStateListQuery();
    const {data: districtList, isFetching: isFetchingDistrict} = useGetDistrictListQuery(selectedProvince);
    const {data: nagarList, isFetching: isFetchingNagar} = useGetNagarListQuery(selectedDistrict);


    useEffect(() => {
        setFilteredOrgList(orgList)
    }, [orgList])

    useEffect(() => {
        setFilter({...filter, q: query.get("q")})
    }, [query])

    useEffect(() => {
        if (!isFetching) {
            const endOffset = Math.min(orgList.page?.current * orgList.page?.perPage, orgList.page?.total);
            // const endOffset = itemOffset + itemsPerPage;
            console.log(`Loading items from ${itemOffset} to ${endOffset}`);
            setPageCount(Math.ceil(orgList.page.total / itemsPerPage));
        }
    }, [itemOffset, isFetching]);

    const handleFilter = (address) => {
        if (address?.type === "state") {
            setSelectedProvince(address.value)
            setSelectedDistrict(null)
            setSelectedNagar(null)
        }
        if (address?.type === "district") {
            setSelectedDistrict(address.value)
            setSelectedNagar(null)
        }
        if (address?.type === "nagar") setSelectedNagar(address?.value)
        // console.log(btoa(JSON.stringify(address)))
        if (!address) {
            setSelectedProvince(null)
            setSelectedDistrict(null)
        }
        setFilter({...filter, places: address && btoa(JSON.stringify(address))})
        // console.log(btoa(JSON.stringify("")))
    }

    const handlePageClick = (event) => {
        const newOffset = event.selected * itemsPerPage % orgList.page.total;
        // const newOffset = (orgList.page?.current - 1) * orgList.page?.perPage
        console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`);
        setFilter({...filter, page: event.selected + 1})
        console.log(event.selected)
        setItemOffset(newOffset);
    };

    const handleDirectionDialog = () => {
        setIsOpen(prevState => !prevState)
    }

    return (
        <>
            <Helmet>
                <meta property="og:title" content={`Results for "${query.get("q")}"`}/>
                <meta property="og:image" content="https://findallnepal.com/find_all.png"/>
                <title>{`Results for "${query.get("q")}"`}</title>
            </Helmet>
            <div className="flex-grow">
                <HomeHeader/>
                <div className="container max-w-1000 mx-auto my-16 space-y-10">
                    <div className="bg-gray-50 px-4 py-8">
                        <SearchbarOnly2 full placeholder="Search what you need"/>
                    </div>
                    <div>
                        {!isFetching &&
                            <p>Showing results
                                <span className="text-bColor">
                            {((orgList.page?.current - 1) * orgList.page?.perPage) + 1} to {Math.min(orgList.page?.current * orgList.page?.perPage, orgList.page?.total)} of {orgList.page?.total}
                        </span> for <span className="text-bColor">{`"${query.get("q") || "All"}"`}</span>
                            </p>
                        }
                    </div>
                    <section className="max-w-6xl mx-auto w-full" id="result-list">
                        <p className="font-bold">Search Locations Near by you</p>
                        <div className="bg-gray-100 space-x-8 px-8 py-4 mt-4 text-gray-500">
                            <button className="font-bold relative" onClick={() => handleFilter(null)}>
                                Show All
                                {selectedProvince === null &&
                                    <div className={"w-8 border-b-3 border-bColor absolute -bottom-1"}/>
                                }
                            </button>
                            {!isFetchingState
                                ? stateList?.address.map(item => (
                                    <button className="relative font-bold"
                                            key={item.slug}
                                            onClick={() => handleFilter(item)}>
                                        {item?.value}
                                        {selectedProvince === item.value &&
                                            <div className={"w-8 border-b-3 border-bColor absolute -bottom-1"}/>
                                        }
                                    </button>
                                ))
                                : <ThreeDotsLoader size={6}/>
                            }
                        </div>
                        <div className="mt-4 space-y-4">
                            {selectedProvince &&
                                <div className="flex items-start">
                                    <div className="flex items-center mr-4">
                                        <img src={`/assets/images/district.svg`} height="24" width="40" alt=""/>
                                        <span className="ml-4 block capitalize">district</span>
                                    </div>
                                    <ul className=" flex flex-wrap items-start justify-center">
                                        {(districtList && !isFetchingDistrict)
                                            ? districtList.address.map(item =>
                                                <li key={item.slug}
                                                    className={clsx("mb-2 mr-2 px-4 py-2 rounded-full font-medium cursor-pointer",
                                                        selectedDistrict === item.value ? "bg-gray-700 text-white" : "bg-gray-200"
                                                    )}
                                                    onClick={() => handleFilter(item)}>
                                                    {/*<button className="mb-2 px-4 py-2 bg-gray-200 rounded-full font-medium"*/}
                                                    {/*        onClick={() => handleFilter(item)}>*/}
                                                    {item.value}
                                                    {/*</button>*/}
                                                </li>
                                            )
                                            : <div className="mt-3"><ThreeDotsLoader size={10}/></div>
                                        }
                                    </ul>
                                </div>
                            }
                            {selectedDistrict &&
                                <div className="flex items-start">
                                    <div className="flex items-center mr-4">
                                        <img src={`/assets/images/place.svg`} height="24" width="40" alt=""/>
                                        <span className="mx-4 capitalize">place</span>
                                    </div>
                                    <ul className="flex flex-wrap items-start justify-center">
                                        {(nagarList && !isFetchingNagar)
                                            ? nagarList.address.map(item =>
                                                <li key={item.slug}
                                                    className={clsx("mb-2 mr-2 px-4 py-2 rounded-full font-medium cursor-pointer",
                                                        selectedNagar === item.value ? "bg-gray-700 text-white" : "bg-gray-200"
                                                    )}
                                                    onClick={() => handleFilter(item)}
                                                >
                                                    {item.value}
                                                </li>
                                            )
                                            : <div className="mt-3"><ThreeDotsLoader size={10}/></div>
                                        }
                                    </ul>
                                </div>
                            }
                        </div>
                        {isFetching
                            ? <div className="flex justify-center items-center flex-col mt-8">
                                <img src="/assets/bars-loader.svg" className="w-10" alt=""/>
                                <span className="text-sm text-gray-400 mt-4 block">loading</span>
                            </div>
                            : !filteredOrgList?.organization?.length ? (
                                    <div className="flex justify-center w-full">No results.</div>)
                                : <div className="mt-8 grid gap-6">
                                    {filteredOrgList?.organization?.map(current => (
                                        <article className="shadow bg-gray-50 rounded-2xl p-6 space-y-6"
                                                 key={current.slug}>
                                            <div className="space-x-4 flex">
                                                <div className="flex space-x-2">
                                                    <img src={"/assets/images/phone_circle.svg"} alt=""
                                                         className="w-4"/>
                                                    <div className="flex w-full space-x-2">
                                                        {current.phone.length > 0
                                                            ? current.phone.filter((item, index) => index < 2).map((item, i) =>
                                                                <span key={item.value} className="block">
                                                                    <a href={`tel:${item.value}`}>{item.value}</a>
                                                                    {current.phone.length === 1 ? "" : i === 0 ? "," : ""}
                                                                </span>
                                                            )
                                                            : "n.a."
                                                        }
                                                    </div>
                                                </div>
                                                <div className="flex space-x-2">
                                                    <img src="/assets/images/mail_circle.svg" alt="" className="w-4"/>
                                                    <div className="flex w-full space-x-2">
                                                        {current.email.length > 0
                                                            ? current.email.filter((item, index) => index < 2).map((item, i) =>
                                                                <span key={item.value} className="block">
                                                                    <a href={`tel:${item.value}`}>{item.value}</a>
                                                                    {current.email.length === 1 ? "" : i === 0 ? "," : ""}
                                                                </span>
                                                            )
                                                            : "n.a."
                                                        }
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="flex space-x-10 ">
                                                <img src={current.logo?.path || "/assets/images/no-image.png"} alt=""
                                                     width={80} height={80} className="w-20 h-20"/>
                                                <div className="space-y-3">
                                                    <p className="flex space-x-2.5 items-start">
                                                        <img src="/assets/images/people-outline.svg" alt="" width={20}
                                                             className="w-5 contrast-50 filter"/>
                                                        <Link to={{pathname: `/${current.slug}`}}>
                                                            <span>{current.name}</span>
                                                        </Link>
                                                    </p>
                                                    <p className="flex space-x-2.5 items-start">
                                                        <img src="/assets/images/pin.svg" alt="" width={20}
                                                             className="w-5 contrast-50 filter"/>
                                                        <span>
                                                            {`${current.address?.nagar_data?.name || ""} ${current.address?.ward_data?.name || ""}, ${current.address?.district_data?.name || ""}`}
                                                        </span>
                                                    </p>
                                                    <p className="flex space-x-2.5 items-start">
                                                        <img src="/assets/images/compass-outline.svg" alt="" width={20}
                                                             className="w-5 contrast-50 filter"/>
                                                        <button className="border-dotted border-b border-gray-500"
                                                                onClick={handleDirectionDialog}>
                                                            Get Direction
                                                        </button>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="text-right">
                                                <Link to={{pathname: `/${current.slug}`}}>Visit Profile</Link>
                                            </div>
                                        </article>
                                        // </Link>
                                    ))
                                    }
                                </div>
                        }
                    </section>
                    {!isLoading &&
                        <div className="text-gray-500">
                            <ReactPaginate
                                nextLabel="next >"
                                onPageChange={handlePageClick}
                                pageRangeDisplayed={3}
                                marginPagesDisplayed={2}
                                pageCount={pageCount}
                                previousLabel="< previous"
                                pageClassName="mx-2 rounded-lg py-2"
                                pageLinkClassName="border border-gray-200 py-2 px-3.5 rounded-lg text-bColor"
                                previousClassName="page-item"
                                previousLinkClassName="border border-gray-200 py-2 px-3.5 rounded-lg text-bColor"
                                nextClassName="page-item"
                                nextLinkClassName="border border-gray-200 py-2 px-3.5 rounded-lg text-bColor"
                                breakLabel="..."
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                                containerClassName="flex justify-center items-center"
                                activeClassName="bg-bColor-light"
                                renderOnZeroPageCount={null}
                            />
                        </div>
                    }
                </div>
            </div>
            <DirectionDialog handleDirectionDialog={handleDirectionDialog} isOpen={isOpen}/>
            <MainFooter/>
        </>
    )
}