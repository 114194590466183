import {Route, Routes} from "react-router-dom";
import GenericOrganizationHome from "./GenericOrganizationHome";
import GenericOrgHeader from "../components/GenericOrgHeader";
import GenericOrgFooter from "../components/GenericOrgFooter";
import GenericOrganizationProducts from "./GenericOrganizationProducts";
import GenericOrganizationJobs from "./GenericOrganizationJobs";
import GenericOrganizationGallery from "./GenericOrganizationGallery";
import GenericOrganizationTeams from "./GenericOrganizationTeams";
import GenericOrganizationAbout from "./GenericOrganizationAbout";
import GenericOrganizationEventsRoot from "./GenericOrganizationEventsRoot";
import FourOFour from "../components/FourOFour";
import {Helmet} from "react-helmet-async";

export default function GenericOrganizationLayout({data}) {
    return (
        <>
            <Helmet>
                <meta name="description" content=""/>
                <meta property="og:description" content=""/>
                <meta property="og:image" content={`${data.featuredImage?.path || ""}`}/>
            </Helmet>
            <div className="grid min-h-screen grid-rows-genericOrg3RowsGrid">
                <GenericOrgHeader data={data}/>
                <Routes>
                    <Route path={`about`} element={<GenericOrganizationAbout data={data}/>}/>
                    <Route path={`jobs`} element={<GenericOrganizationJobs data={data}/>}/>
                    <Route path={`teams`} element={<GenericOrganizationTeams data={data}/>}/>
                    <Route path={`gallery`} element={<GenericOrganizationGallery data={data}/>}/>
                    <Route path={`events/*`} element={<GenericOrganizationEventsRoot data={data}/>}/>
                    <Route path={`products`} element={<GenericOrganizationProducts data={data}/>}/>
                    <Route path={`/`} exact element={<GenericOrganizationHome data={data}/>}/>
                    <Route path={`/*`} element={<FourOFour/>}/>
                </Routes>
                <GenericOrgFooter data={data}/>
            </div>
        </>
    )
}