import {Link} from "react-router-dom";
import routes from "../constants/routes";

const homeCardItems = [
    {
        name: "education",
        slug: "education",
        placeholder: "Search educational organizations",
        image: "EDUCATION"
    }, {
        name: "building",
        slug: "construction-company",
        placeholder: "Search construction companies",
        image: "BUILDING"
    }, {
        name: "health",
        slug: "health",
        placeholder: "Search health organizations",
        image: "HEALTH"
    }, {
        name: "agriculture",
        slug: "agriculture",
        placeholder: "Search agricultural organizations",
        image: "AGRICULTURE",
    }, {
        name: "shop",
        slug: "shop",
        placeholder: "Search for shops",
        image: "SHOP"
    }, {
        name: "repairs",
        slug: "repairs",
        placeholder: "Search for work shops, ",
        image: "REPAIRS"
    }, {
        name: "Automobile",
        slug: "garage-showroom",
        placeholder: "Search for auto showrooms",
        image: "AUTO"
    }, {
        name: "government office",
        slug: "government",
        placeholder: "Search for Governmental offices",
        image: "GOVERNMENT"
    }, {
        name: "historical places",
        slug: "historical-places",
        placeholder: "Search historical places",
        image: "HISTORICAL"
    }, {
        name: "tourist area",
        slug: "tourist-area",
        placeholder: "Search tourist areas",
        image: "TOURIST"
    },
]

export default function HomeCards() {
    return (
        <div className="my-24 flex flex-wrap justify-center text-center">
            {homeCardItems.map(current => {
                return (
                    <div className="mx-10 mb-8" key={current.name}>
                        <Link to={`${routes.searchByCat}/${current.slug}`}>
                            <div className="p-4 bg-gray-100">
                                <img className="w-16 pointer-events-none"
                                     src={`assets/images/ICON_${current.image}.svg`} width={64}
                                     height={64} alt=""/>
                            </div>
                            <span className="mt-2 block text-xs capitalize">{current.name}</span>
                        </Link>
                    </div>
                )
            })
            }
        </div>
    )
}