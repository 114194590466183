import HomeHeader from "../components/HomeHeader";
import MainFooter from "../components/MainFooter";
import {Helmet} from "react-helmet-async";
import React from "react";
import routes from "../constants/routes";

export default function Tos() {
    return (
        <>
            <Helmet>
                <meta property="og:title" content="Terms of services | Find All Nepal"/>
                <link rel="canonical" href={`https://findallnepal.com${routes.terms}`}/>
                <title>Terms of services | Find All Nepal</title>
            </Helmet>
            <div className="flex-grow">
                <HomeHeader/>
                <div className="container max-w-1000 mx-auto my-16 space-y-10">
                    <div className="relative inline-block">
                        <h1 className="font-medium text-3xl relative">
                            Terms Of Services
                        </h1>
                        <span className="border-b-3 border-bColor absolute -bottom-4 w-32"/>
                    </div>
                    <p className="text-sm">The objective of Find All Nepal is to provide a web based, service-oriented
                        information to
                        the public & Mobile based platform for easy exchange of information and interaction between
                        Client, Advertisers and Users, or between Advertisers and users themselves in Nepal.
                    </p>
                    <main>
                        <section className="mt-16">
                            <h2 className="text-2xl">
                                Terms used in this agreement:
                            </h2>
                            <div className="mt-8 space-y-4 text-sm">
                                <p>Any persons, who accesses Find All Nepal for different purposes can be termed as
                                    user/client. These may include searching for information regarding organization
                                    category, company or product and services. This person may or may not be registered
                                    with Find All Nepal.
                                </p>
                                <p>
                                    Clients/Advertiser is a person, who is either an owner or a representative of a
                                    business organization registered in Nepal, who will be listed on Find All Nepal and
                                    will be using its various services either free or paid. Content Administrator is a
                                    person appointed by Find All Nepal to check the validity of data uploaded to its
                                    website by the Clients / Advertisers and also approve their content. Website
                                    Administrator is a person who is managing and administering all the technical
                                    aspects of findallnepal.com.
                                </p>
                            </div>
                        </section>
                        <section className="mt-16">
                            <h2 className="text-2xl">
                                Terms of Contract:
                            </h2>
                            <div className="mt-8 text-sm">
                                <ol className="list-decimal space-y-4 ml-5">
                                    <li>This contract is a legal agreement between Find All Nepal and the Clients/
                                        Advertisers
                                        and is supposed to have been accepted by both parties once the
                                        Clients/Advertiser has
                                        registered with Find All Nepal either online or offline.
                                    </li>
                                    <li>All registrations and advertisements will have to be approved by Find All
                                        Nepal before
                                        being published to the Website by the Content Administrator.
                                    </li>
                                    <li>While Find All Nepal will keep in view the preference of the Advertiser, the
                                        final
                                        decision on how and where to place the advertisements on its Website will be
                                        taken by
                                        the Content Administrator.
                                    </li>
                                    <li>The Advertiser warrants that the advertisement he/she is posting to the
                                        Website does not
                                        hinder any statute regulations made there under, nor is it in any way defamatory
                                        or illegal
                                        or an infringement of any other party's rights or laws of Nepal.
                                    </li>
                                    <li>In no event shall the liability of Find All Nepal for any breach of contract
                                        or in fault
                                        exceed the price paid by the Clients/Advertiser for the advertisement and in no
                                        event
                                        shall Find All Nepal take any liability for any consequential loss or damage to
                                        the
                                        Clients/ Advertiser.
                                    </li>
                                    <li>The Clients/Advertiser will be responsible for regularly updating his
                                        business
                                        organizations profile on the Website with new information and they will remove
                                        any old
                                        content like expired special offers, services, organizations details and so on.
                                    </li>
                                    <li>The Advertiser is allowed to post details about his/her products and services
                                        on our
                                        Website. This information can be viewed on the Website by the targeted public
                                        and may
                                        not be removed by Find All Nepal, so whatever information the
                                        Clients/Advertisers post
                                        on the website will be at their own risk.
                                    </li>
                                    <li>The Clients/Advertiser will compensate and keep compensated Find All Nepal in
                                        full
                                        against any loss or claim that may arise due to the Clients/Advertiser's content
                                        or
                                        advertisements.
                                    </li>
                                    <li>The Users of the Website have the right to review any business based on their
                                        experience.
                                        The Advertiser will have to accept this review unless it is a fake review in
                                        which case the
                                        Advertiser can notify the Content Administrator by email and have it removed.
                                    </li>
                                    <li>Find All Nepal privacy policy may be changed periodically without noticing
                                        client/
                                        advertiser so you will be responsible for checking these improvements. All
                                        modified
                                        terms will become effective upon our posting to the Website, and any use of the
                                        Website
                                        after such improvements have been posted will signify your consent to these
                                        changes.
                                    </li>
                                    <li>The subscription charges for any service may be revised by Find All Nepal
                                        from time to
                                        time, at its own responsibility.
                                    </li>
                                </ol>
                            </div>
                        </section>
                    </main>
                </div>
            </div>
            <MainFooter/>
        </>
    )
}