import {useGetOrgEventsQuery} from "../services/directoryApi";
import {Link, useParams} from "react-router-dom";
import PageTransitionLoader from "../components/PageTransitionLoader";
import {Helmet} from "react-helmet-async";

export default function GenericOrganizationEvents({data}) {

    const param = useParams();

    const {data: eventList, isFetching} = useGetOrgEventsQuery(param.slug);

    if (isFetching) return (
        <EventLayout data={data}>
            <div className="flex justify-center items-center flex-col h-72 bg-bColor-50">
                <PageTransitionLoader size={28}/>
                <span className="text-sm">LOADING</span>
            </div>
        </EventLayout>
    )

    if (!isFetching && eventList.organizationWithEvents) {
        return (
            <EventLayout data={data}>
                <section id="events">
                    <div className="mx-auto max-w-1000 my-14">
                        {/*<GenericOrgEventsTab/>*/}
                        <div className="grid grid-cols-2 gap-12 w-full">
                            {eventList.organizationWithEvents.events?.map((current, index) => {
                                return (
                                    <article className="grid grid-rows-1 gap-6 justify-items-start" key={index}>
                                        <div className="flex space-x-10 items-start">
                                            <img src={current.featuredImage?.path || "/assets/images/event_thumb2.png"}
                                                 alt="" width={130} height={130}
                                                 className="rounded-2xl pointer-events-none w-32 h-32"/>
                                            <div>
                                                <h3 className="font-bold text-2xl mb-5">{current.name}</h3>
                                                <div className="space-y-3 text-gray-500">
                                                    <p className="flex space-x-4 items-start"><img
                                                        src="/assets/images/event_org.png" alt=""
                                                        width={17}/><span>{data.name}</span>
                                                    </p>
                                                    <p className="flex space-x-4 items-start"><img
                                                        src="/assets/images/event_start.png" alt=""
                                                        width={17}/><span>{new Date(current.from).toDateString()}</span>
                                                    </p>
                                                    <p className="flex space-x-4 items-start"><img
                                                        src="/assets/images/event_end.png" alt=""
                                                        width={17}/><span>{new Date(current.to).toDateString()}</span>
                                                    </p>
                                                    <p className="flex space-x-4 items-start"><img
                                                        src="/assets/images/event_location.png" alt=""
                                                        width={15}/><span>{current.location}</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-gray-400 space-x-4">
                                            <Link to={`/${param.slug}/events/${current.slug}`}
                                                  className="rounded-full py-3 px-8 font-bold text-bColor bg-bColor-light">Details</Link>
                                            <button
                                                className="rounded-full py-3 px-8 font-bold text-gray-700 bg-gray-100">Share
                                            </button>
                                        </div>
                                    </article>
                                )
                            })
                            }
                        </div>
                    </div>
                </section>
            </EventLayout>
        )
    }

    return (
        <EventLayout data={data}>
            <div className="mx-auto max-w-1000 justify-center items-center flex mt-4"> Nothing to show.</div>
        </EventLayout>
    )
}

function EventLayout({data, children}) {
    return (
        <>
            <Helmet>
                <meta property="og:title" content={`Events | ${data.name}`}/>
                <meta property="og:url" content={`https://findallnepal.com/${data.slug}/events`}/>
                <link rel="canonical" href={`https://findallnepal.com/${data.slug}/events`}/>
                <title>{`Events | ${data.name}`}</title>
            </Helmet>
            <main>
                <section id="hero">
                    <div className="bg-gray-400 w-full h-64 relative">
                        <div className="bg-transparent w-full h-64 bg-center bg-no-repeat filter brightness-50"
                             style={{backgroundImage: `url(${data.featuredImage?.path})` || ""}}/>
                        <h1 className="text-center text-4xl font-bold text-white absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2">
                            Events
                        </h1>
                    </div>
                </section>
                {children}
            </main>
        </>
    )
}
