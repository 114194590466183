import React, {Fragment, useEffect, useState} from "react";
import {Dialog, Transition} from "@headlessui/react";
import {useDispatch, useSelector} from "react-redux";
import millify from "millify";
import {doGetMoreComments, doLikeUnLikeCommentPopup, doPostCommentPopUp} from "../../store/voiceSlice";
import VoiceDisplayPic from "./VoiceDisplayPic";


export default function CommentsPopup({setToggleComment, toggleComment, slug}) {

    const dispatch = useDispatch();

    const {comments} = useSelector(state => state.voice);

    useEffect(() => {
    }, [])

    const handleLikeUnLikeComment = (slug, id, isLiked, path, index) => {
        console.log("liked/unliked")
        dispatch(doLikeUnLikeCommentPopup({id, slug, action: isLiked ? "unlike" : "like", path, index}))
    }

    const handleGetMoreComments = () => {
        dispatch(doGetMoreComments({slug, token: comments.fetch.list.next}))
    }


    return (
        <Transition appear show={toggleComment} as={Fragment}>
            <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={() => setToggleComment(false)}>
                <div className="min-h-screen px-4 text-center">
                    <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0"
                                      enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100"
                                      leaveTo="opacity-0">
                        <Dialog.Overlay className="fixed inset-0 bg-black opacity-60"/>
                    </Transition.Child>
                    {/* This element is to trick the browser into centering the modal contents. */}
                    <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 scale-95"
                                      enterTo="opacity-100 scale-100" leave="ease-in duration-200"
                                      leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95"
                    >
                        <div
                            className="inline-block w-full max-w-5xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-md">
                            <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                                Comments on this post
                            </Dialog.Title>
                            <div className="mt-2">
                                <div className="bg-white p-4 rounded-md space-y-2">
                                    {(!comments.fetch.isLoading && comments.fetch.list?.comments?.length)
                                        ? <>
                                            {comments.fetch.list?.hasMore &&
                                                <button
                                                    className="mb-4 font-bold text-sm flex justify-center items-center space-x-2 hover:underline"
                                                    onClick={handleGetMoreComments}>
                                                    <span>
                                                        View previous comments
                                                    </span>
                                                    {comments.fetch.isFetchingMore &&
                                                        <img src="/assets/round-loader.svg" alt="" className="w-4 h-4"/>
                                                    }
                                                </button>}
                                            <PostCommentsTree comments={comments.fetch?.list.comments} path="comments"
                                                              handleLikeUnLikeComment={handleLikeUnLikeComment}/>
                                        </>
                                        : (!comments.fetch.isLoading && !comments.fetch.list?.comments?.length) &&
                                        <div>No comments on this post.</div>
                                    }
                                    {comments.fetch.isLoading && <div>...fetching comments</div>}
                                    {comments.fetch.isError.status && <div>{comments.fetch.isError.message}</div>}
                                    <PostCommentFormBox slug={slug} path={"comments"}/>
                                </div>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    )
}

function PostCommentsTree({comments, handleLikeUnLikeComment, path}) {

    return comments.map((item, index) => {
        return (
            // <div key={item.id} className={`space-y-2 ${item.parent ? "ml-8" : ""}`}>
            <PostCommentTreeItem handleLikeUnLikeComment={handleLikeUnLikeComment} item={item}
                                 key={item.id} index={index} path={path}/>
        )
    }).reverse()
}

function PostCommentTreeItem({item, handleLikeUnLikeComment, index, path}) {

    const [replyBox, setReplyBox] = useState(false);

    const handleReplyBox = () => {
        setReplyBox(prevState => !prevState)
    }
    return (
        <div className={`space-y-4 ${item.parent ? "ml-8" : ""}`}>
            <div className="space-y-2">
                <div className="flex items-start gap-1 relative">
                    <img src={item?.commenter?.image?.path || ""} alt="" height={28} width={28}
                         className="h-7 w-7 rounded-full"/>
                    <div className="w-full text-sm">
                        <div className="rounded-md bg-gray-100 px-3 py-2">
                            <div className="flex items-center space-x-2 text-sm">
                                <a href={item.commenter.profile} className="font-bold" target="_blank"
                                   rel="noreferrer">
                                    {item.commenter.name}
                                </a>
                                <span className="w-[3px] h-[3px] bg-gray-400 rounded-full"/>
                                <span className="font-light text-gray-500 text-xs"> {item.datetime_human} </span>
                            </div>
                            <span className="break-all text-gray-600 tracking-wide">{item.comment}</span>
                        </div>
                        <div className="space-x-1">
                            <button className="px-2 py-0.5 hover:underline"
                                    onClick={() => handleLikeUnLikeComment(item.owner.slug, item.id, item.isLiked, path, index)}>
                                {item.isLiked ? "Unlike" : "Like"}
                            </button>
                            <button className="px-2 py-0.5" onClick={handleReplyBox}>Reply</button>
                        </div>
                    </div>
                    {item.likes
                        ? <div className="flex justify-center items-center space-x-1 absolute right-2 top-2">
                            <svg xmlns="http://www.w3.org/2000/svg"
                                 className="h-5 w-5 rounded-full bg-gray-200 p-0.5"
                                 viewBox="0 0 24 24" stroke={item.isLiked ? "" : "currentColor"}
                                 strokeWidth={item.isLiked ? "" : "2"}
                                 fill={item.isLiked ? "#92278f" : "transparent"}>
                                <path
                                    d="M2 10.5a1.5 1.5 0 113 0v6a1.5 1.5 0 01-3 0v-6zM6 10.333v5.43a2 2 0 001.106 1.79l.05.025A4 4 0 008.943 18h5.416a2 2 0 001.962-1.608l1.2-6A2 2 0 0015.56 8H12V4a2 2 0 00-2-2 1 1 0 00-1 1v.667a4 4 0 01-.8 2.4L6.8 7.933a4 4 0 00-.8 2.4z"/>
                            </svg>
                            <div
                                className={`leading-none text-xs ${item.isLiked ? "text-bColor" : "text-inherit"}`}>
                                {item.likes ? millify(item.likes) : ""}
                            </div>
                        </div>
                        : ""
                    }
                </div>
                {replyBox &&
                    <div className="ml-7">
                        <PostCommentFormBox id={item.id} slug={item.owner.slug} path={`${path}[${index}]`}/>
                    </div>
                }
            </div>
            {item.reply?.length ?
                <PostCommentsTree comments={item.reply} handleLikeUnLikeComment={handleLikeUnLikeComment}
                                  path={path + "[" + index + "].reply"}/> : ""}
        </div>
    )
}

function PostCommentFormBox({slug, id, path}) {

    const dispatch = useDispatch();
    const [comments, setComments] = useState({text: "", isLoading: false, error: {status: false, message: ""}});

    const handleCommentChange = (event) => {
        setComments(prevState => ({isLoading: false, error: {status: false, message: ""}, text: event.target.value})
        )
    }

    const handleCommentSubmit = (event, slug, id) => {
        event.preventDefault();
        setComments(prevState => ({...prevState, isLoading: true}))
        const commentText = comments.text.trim();
        if (commentText.length > 0)
            dispatch(doPostCommentPopUp({slug, comment: comments.text, id: id || "", path}))
                .unwrap()
                .then((result) => {
                    setComments({text: "", isLoading: false, error: {status: false, message: ""}})
                }).catch(error => {
                setComments(prevState => ({
                    ...prevState, isLoading: false, error: {status: true, message: "Error occurred, Please try again."}
                }))
            })
    }

    return (
        <form onSubmit={(event) => handleCommentSubmit(event, slug, id)}
              className="gap-1 flex items-start">
            <VoiceDisplayPic/>
            <div className="space-y-4 flex-grow">
                <input type="text" value={comments.text} disabled={comments.isLoading} onChange={handleCommentChange}
                       className="text-sm rounded-md bg-gray-100 w-full p-2" placeholder="Write your comment.."
                       required/>
                <button className="text-sm bg-slate-500 text-white px-4 py-1 rounded-md hidden" type="submit">
                    Post
                </button>
            </div>
        </form>
    )
}
