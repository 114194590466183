import React from "react";
import {Helmet} from "react-helmet-async";
import {Link, useParams} from "react-router-dom";
import {useGetTodaysOldNewsListQuery} from "../services/newsApi";
import PageTransitionLoader from "../components/PageTransitionLoader";
import {getStartEndDates, timeAgo} from "../others/newsUtils";
import NewsArticle from "../components/NewsArticleCard";
import ContentLoader from "react-content-loader";


export default function NewsHome() {

    const param = useParams();

    const {data: newsList, isFetching: isFetchingNewsList} = useGetTodaysOldNewsListQuery({
        category: param?.category || null, start_date: getStartEndDates("start"),
        end_date: getStartEndDates("end"), q: "",
    });

    // const parseDetail = (detail) => {
    //     const parsedDetail = new DOMParser().parseFromString(detail, 'text/html').body.innerText.trim();
    //     return parsedDetail.slice(0, 150).concat('…')
    // }

    return (
        <>
            {isFetchingNewsList ? (
                    // <div className="flex justify-center items-center flex-col h-screen bg-bColor-50 flex-grow">
                    //     <PageTransitionLoader size={28}/>
                    //     <span className="text-sm">LOADING</span>
                    // </div>
                    <div className="bg-white flex-grow my-8">
                        <ContentLoader
                            speed={2}
                            width={900}
                            height={800}
                            viewBox="0 0 900 800"
                            backgroundColor="#f3f3f3"
                            foregroundColor="#ecebeb"
                        >
                            <rect x="30" y="20" rx="0" ry="0" width="130" height="23"/>
                            <rect x="30" y="60" rx="0" ry="0" width="400" height="120"/>
                            <rect x="30" y="189" rx="0" ry="0" width="350" height="15"/>
                            <rect x="30" y="211" rx="0" ry="0" width="290" height="15"/>

                            <rect x="500" y="60" rx="0" ry="0" width="400" height="120"/>
                            <rect x="500" y="189" rx="0" ry="0" width="350" height="15"/>
                            <rect x="500" y="211" rx="0" ry="0" width="290" height="15"/>

                            <rect x="30" y="280" rx="0" ry="0" width="130" height="23"/>
                            <rect x="30" y="320" rx="0" ry="0" width="400" height="120"/>
                            <rect x="30" y="450" rx="0" ry="0" width="350" height="15"/>
                            <rect x="30" y="474" rx="0" ry="0" width="290" height="15"/>

                            <rect x="500" y="320" rx="0" ry="0" width="400" height="120"/>
                            <rect x="500" y="450" rx="0" ry="0" width="350" height="15"/>
                            <rect x="500" y="474" rx="0" ry="0" width="290" height="15"/>

                            <rect x="500" y="520" rx="0" ry="0" width="400" height="120"/>
                            <rect x="500" y="650" rx="0" ry="0" width="350" height="15"/>
                            <rect x="500" y="675" rx="0" ry="0" width="290" height="15"/>

                            <rect x="30" y="520" rx="0" ry="0" width="400" height="120"/>
                            <rect x="30" y="650" rx="0" ry="0" width="350" height="15"/>
                            <rect x="30" y="675" rx="0" ry="0" width="290" height="15"/>
                        </ContentLoader>
                    </div>
                )
                : <>
                    <Helmet>
                        <meta property="og:title"
                              content={`${param.category?.toUpperCase() || ""} News | Find All Nepal`}/>
                        <title>{param.category?.toUpperCase() || ""} News | Find All Nepal</title>
                    </Helmet>
                    <main className="flex-1">
                        {/*todays news todas news todays news*/}
                        <section>
                            <div className="flex items-center">
                                <h2 className="uppercase text-2xl font-light mr-3 my-6">Today's News</h2>
                                <div className="h-1.5 border-t-3 border-b border-gray-200 flex-grow"/>
                                <Link to={`/todays?category=${param.category || ""}`}
                                      className="flex text-white py-2 px-4 bg-bColor">
                                    <span>View All</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 ml-2" fill="none"
                                         viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                              d="M14 5l7 7m0 0l-7 7m7-7H3"/>
                                    </svg>
                                </Link>
                            </div>
                            <div className="grid grid-cols-2 gap-2">
                                {newsList?.todays?.data?.news?.length > 0
                                    ? newsList?.todays?.data?.news?.map(current => (
                                        <NewsArticle current={current} key={current.id}/>
                                    ))
                                    : <div className="max-w-1170 my-12"><p>No news to show.</p></div>
                                }
                            </div>
                        </section>
                        {/*old news*/}
                        <section>
                            <div className="flex items-center">
                                <h2 className="uppercase text-2xl font-light mr-3 my-6">Old News</h2>
                                <div className="h-1.5 border-t-3 border-b border-gray-200 flex-grow"/>
                                <Link to={`/old?category=${param.category || ""}`}
                                      className="flex text-white py-2 px-4 bg-bColor">
                                    <span>View All</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 ml-2" fill="none"
                                         viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                              d="M14 5l7 7m0 0l-7 7m7-7H3"/>
                                    </svg>
                                </Link>
                            </div>
                            <div className="grid grid-cols-2 gap-2">
                                {newsList?.old?.data?.news?.length > 0
                                    ? newsList?.old?.data?.news?.map(current => (
                                        <NewsArticle current={current} key={current.id}/>
                                    ))
                                    : <div className="max-w-1170 my-12"><p>No news to show.</p></div>
                                }
                            </div>
                        </section>
                    </main>
                </>

            }
        </>
    )
}

