import ShopHomeSearch from "../../components/shop/ShopHomeSearch";
import {Carousel} from "react-responsive-carousel";
import {Link} from "react-router-dom";
import routes from "../../constants/routes";

export default function ShopHome() {

    const arrowStyles = {
        position: 'absolute',
        zIndex: 20,
        top: 'calc(50% - 15px)',
        width: 30,
        height: 30,
        cursor: 'pointer',
    }

    return (
        <div className="px-4">
            <ShopHomeSearch/>
            <section id="category">
                <div className="shadow-custom mt-16 py-2">
                    <div className="max-w-screen-xl mx-auto flex text-xs shop-categories-home">
                        <Link to={`${routes.productCategory}/womens-fashion`}
                           className="border border-black w-32 px-4 py-10 flex justify-center items-center flex-col text-center">
                            <img className="w-16 mb-4" src="/assets/images/ICON_WOMENS-FASHION.svg" alt=""/>
                            <span>Women's Fashion</span>
                        </Link>
                        <Link to={`${routes.productCategory}/womens-fashion`}
                           className="border border-black w-32 px-4 py-10 flex justify-center items-center flex-col text-center">
                            <img className="w-16 mb-4" src="/assets/images/ICON_WOMENS-FASHION.svg" alt=""/>
                            <span>Women's Fashion</span>
                        </Link>
                        <Link to={`${routes.productCategory}/womens-fashion`}
                           className="border border-black w-32 px-4 py-10 flex justify-center items-center flex-col text-center">
                            <img className="w-16 mb-4" src="/assets/images/ICON_WOMENS-FASHION.svg" alt=""/>
                            <span>Women's Fashion</span>
                        </Link>
                        <Link to={`${routes.productCategory}/womens-fashion`}
                           className="border border-black w-32 px-4 py-10 flex justify-center items-center flex-col text-center">
                            <img className="w-16 mb-4" src="/assets/images/ICON_WOMENS-FASHION.svg" alt=""/>
                            <span>Women's Fashion</span>
                        </Link>
                        <Link to={`${routes.productCategory}/womens-fashion`}
                           className="border border-black w-32 px-4 py-10 flex justify-center items-center flex-col text-center">
                            <img className="w-16 mb-4" src="/assets/images/ICON_WOMENS-FASHION.svg" alt=""/>
                            <span>Women's Fashion</span>
                        </Link>
                        <Link to={`${routes.productCategory}/womens-fashion`}
                           className="border border-black w-32 px-4 py-10 flex justify-center items-center flex-col text-center">
                            <img className="w-16 mb-4" src="/assets/images/ICON_WOMENS-FASHION.svg" alt=""/>
                            <span>Women's Fashion</span>
                        </Link>
                        <Link to={`${routes.productCategory}/womens-fashion`}
                           className="border border-black w-32 px-4 py-10 flex justify-center items-center flex-col text-center">
                            <img className="w-16 mb-4" src="/assets/images/ICON_WOMENS-FASHION.svg" alt=""/>
                            <span>Women's Fashion</span>
                        </Link>
                        <Link to={`${routes.productCategory}/womens-fashion`}
                           className="border border-black w-32 px-4 py-10 flex justify-center items-center flex-col text-center">
                            <img className="w-16 mb-4" src="/assets/images/ICON_WOMENS-FASHION.svg" alt=""/>
                            <span>Women's Fashion</span>
                        </Link>
                        <Link to={`${routes.productCategory}/womens-fashion`}
                           className="border border-black w-32 px-4 py-10 flex justify-center items-center flex-col text-center">
                            <img className="w-16 mb-4" src="/assets/images/ICON_WOMENS-FASHION.svg" alt=""/>
                            <span>Women's Fashion</span>
                        </Link>
                        <Link to={`${routes.productCategory}/womens-fashion`}
                           className="border border-black w-32 px-4 py-10 flex justify-center items-center flex-col text-center">
                            <img className="w-16 mb-4" src="/assets/images/ICON_WOMENS-FASHION.svg" alt=""/>
                            <span>Women's Fashion</span>
                        </Link>
                    </div>
                </div>
            </section>
            <section id="slider">
                <div className="max-w-screen-xl  mt-6 mx-auto flex">
                    <div className="flex-grow mr-4 flex justify-center items-center"
                         style={{
                             backgroundImage: "url('/assets/images/shop_silder_left.png')",
                             backgroundSize: "cover",
                             backgroundRepeat: "no-repeat",
                         }}>
                        <p className="text-7xl text-center text-yellow-500">Lets Grow up Together</p>
                    </div>
                    <div className="max-w-3xl m-auto">
                        <Carousel showThumbs={false} showStatus={false} showIndicators={true}
                                  swipeable emulateTouch
                                  renderArrowPrev={(onClickHandler, hasPrev, label) =>
                                      hasPrev && (
                                          <button type="button" onClick={onClickHandler} title={label}
                                                  style={{...arrowStyles, left: 10}}>
                                              <img src="/assets/images/arrow_r.png" alt="featured"/>
                                          </button>
                                      )
                                  }
                                  renderArrowNext={(onClickHandler, hasNext, label) =>
                                      hasNext && (
                                          <button type="button" onClick={onClickHandler} title={label}
                                                  style={{...arrowStyles, right: 10}}>
                                              <img src="/assets/images/arrow_l.png" alt="featured"/>
                                          </button>
                                      )
                                  }
                                  autoPlay infiniteLoop
                        >
                            <div>
                                <img src="/assets/images/shop_slider-image.png" alt="featured"/>
                            </div>
                            <div>
                                <img src="/assets/images/shop_slider-image.png" alt="featured"/>
                            </div>
                        </Carousel>
                    </div>
                </div>
            </section>
        </div>
    )
}