import {Route, Routes} from "react-router-dom";
import Events from "./Events";
import SingleEducationHome from "./SingleEducationHome";
import Career from "./Career";
import Team from "./Team";
import About from "./About";
import Gallery from "./Gallery";
import EventDetailsPage from "./EventDetailsPage";
import CareerDetailsPage from "./CareerDetailsPage";
import FourOFour from "../../components/FourOFour";

export default function EducationRoot() {
    return (
        <Routes>
            <Route path={`gallery`} element={<Gallery/>}/>
            <Route path={`about`} element={<About/>}/>
            <Route path={`team`} element={<Team/>}/>
            <Route path={`career/:careerTitle`} element={<CareerDetailsPage/>}/>
            <Route path={`career`} element={<Career/>}/>
            <Route path={`events/:eventName`} element={<EventDetailsPage/>}/>
            <Route path={`events`} element={<Events/>}/>
            <Route path={`/`} element={<SingleEducationHome/>}/>
            <Route path={`/*`} element={<FourOFour/>}/>
        </Routes>
    )
}