import ShopSecondaryHeader from "../../components/shop/ShopSecondaryHeader";
import BreadCrumbs from "../../components/shop/BreadCrumbs";
import {Carousel} from "react-responsive-carousel";
import {useState} from "react";
import ProductCard from "../../components/shop/ProductCard";

export default function Product() {

    const [quantity, setQuantity] = useState(1);

    const handleQuantityDecrease = () => {
        setQuantity(prevState => prevState - 1)
    }
    const handleQuantityIncrease = () => {
        setQuantity(prevState => prevState + 1)
    }

    return (
        <div className="max-w-screen-xl mx-auto">
            <ShopSecondaryHeader/>
            <BreadCrumbs/>
            <div className="flex mt-8 space-x-8 border-b border-gray-200 pb-8">
                <section className="max-w-md">
                    <div id="slider">
                        <Carousel showThumbs={true} showStatus={false} showIndicators={false} showArrows={false}
                                  animationHandler="fade" swipeable={false}>
                            <div>
                                <img src="/assets/images/product_image.png" alt="featured"/>
                            </div>
                            <div>
                                <img src="/assets/images/product_image.png" alt="featured"/>
                            </div>
                        </Carousel>
                    </div>
                    <div id="buttons" className="flex space-x-4">
                        <button className="w-1/2 px-12 py-2 text-white bg-yellow-600">Buy Now</button>
                        <button className="w-1/2 px-12 py-2 text-white bg-bColor">Add to Cart</button>
                    </div>
                </section>
                <section className="flex-grow">
                    <div id="name-stock-price">
                        <div className="flex justify-between">
                            <div id="header" className="border-b border-gray-200 mb-1 pb-1">
                                <h1 className="text-xl">Black Color Hoodie for Men</h1>
                                <div>
                                    <div className="Stars mt-1" style={{"--rating": "4", "--star-size": "1rem"}}
                                         aria-label="Rating of this product.">
                                        <span className="text-xs">4 Star Rating</span>
                                    </div>
                                    <span className="mx-2">|</span>
                                    <span className="text-xs">1 Reviews</span>
                                </div>
                            </div>
                            <button>
                                <img className="w-6" src="/assets/images/ICON_SHARE.svg" alt=""/>
                            </button>
                        </div>
                        <div className="mt-2 font-bold space-x-2">
                            <span className="inline-block">Rs. 2500</span>
                            <span className="inline-block text-gray-400 line-through text-sm">Rs. 2800</span>
                            <strong className="text-bColor">10% off</strong>
                        </div>
                        <div id="quantity-selector" className="flex items-center mt-2 space-x-2">
                            <span className="text-gray-500 text-sm">Quantity</span>
                            <div className="space-x-3">
                                <button className="bg-gray-200 px-3 py-2 inline-block leading-none disabled:opacity-50"
                                        disabled={quantity <= 1} onClick={handleQuantityDecrease}>-
                                </button>
                                <span>{quantity}</span>
                                <button className="bg-gray-200 px-3 py-2 inline-block leading-none"
                                        onClick={handleQuantityIncrease}>+
                                </button>
                            </div>
                            <span className="text-gray-500 text-sm">Only 2 items left</span>
                        </div>
                    </div>
                    <div id="product-details" className="bg-gray-100 mt-6 px-4 py-2 text-sm">
                        <h2 className="text-bColor font-bold mb-3">Product Details:</h2>
                        <ul className="space-y-1">
                            <li className="flex">
                                <span className="text-gray-500 w-36">Ideal for</span>
                                <span className="flex-grow text-black">Men</span>
                            </li>
                            <li className="flex">
                                <span className="text-gray-500 w-36">Pattern</span>
                                <span className="flex-grow text-black">None</span>
                            </li>
                            <li className="flex">
                                <span className="text-gray-500 w-36">Pack of</span>
                                <span className="flex-grow text-black">1</span>
                            </li>
                            <li className="flex">
                                <span className="text-gray-500 w-36">Color</span>
                                <span className="flex-grow text-black">Red</span>
                            </li>
                            <li className="flex">
                                <span className="text-gray-500 w-36">Available Size</span>
                                <span className="flex-grow text-black">S, M, X, XX,</span>
                            </li>
                            <li className="flex">
                                <span className="text-gray-500 w-36">Fabric</span>
                                <span className="flex-grow text-black">Cotton</span>
                            </li>
                            <li className="flex">
                                <span className="text-gray-500 w-36">Type</span>
                                <span className="flex-grow text-black">Hoodie</span>
                            </li>
                        </ul>
                    </div>
                    <div id="delivery-shipping" className="bg-gray-100 mt-2 px-4 py-2 text-sm">
                        <div id="address" className="flex justify-between">
                            <div>
                                <strong className="text-xs font-bold block mb-2">Delivery Details</strong>
                                <img src="/assets/images/ICON_LOCATION.svg" className="w-5 inline-block mr-1" alt=""/>
                                <span>Bagmati, Kathmandu Metro 22-Newroad Area, Nepal</span>
                            </div>
                            <a href="/" className="text-bColor font-bold self-end">CHANGE</a>
                        </div>
                        <div id="delivery-charges"
                             className="flex justify-between border-t border-b border-gray-200 my-3 py-3">
                            <div>
                                <strong className="text-xs font-bold block ">Home Delivery</strong>
                                <span className="text-gray-400">5 - 7 Days</span>
                            </div>
                            <a href="/" className="text-bColor font-bold self-end">Rs. 100</a>
                        </div>
                        <div id="services">
                            <strong className="text-xs font-bold block mb-2">Services</strong>
                            <div className="flex justify-between">
                                <div>
                                    <img src="/assets/images/cod.svg" className="w-4 inline-block mr-2" alt=""/>
                                    <span>Cash on Delivery available</span>
                                </div>
                                <span>7 Days Returnback Policy</span>
                                <span>Warranty not available</span>
                            </div>
                        </div>
                    </div>
                    <div id="sold-by" className="flex justify-between my-4">
                        <p>Sold by:<a href="/"><span className="ml-2 font-bold text-bColor">Agrata Shopping Mall</span></a>
                        </p>
                        <a href="/" className="px-6 text-white bg-bColor rounded-full">Visit Store</a>
                    </div>
                    <div id="rating-reviews" className="bg-gray-100 mt-2 px-4 py-2 text-sm">
                        <div className="flex justify-between">
                            <div>
                                <strong className="font-bold block mb-1 text-bColor">Ratings & Reviews</strong>
                                <div className="flex items-center">
                                    <div className="Stars mr-2" style={{"--rating": "4", "--star-size": "1.6rem"}}
                                         aria-label="Rating of this product."/>
                                    <span className="">Rating & 1 Reviews</span>
                                </div>
                            </div>
                            <a href="/" className="text-yellow-500 font-bold self-start">Rate</a>
                        </div>
                    </div>
                    <div id="q-a" className="bg-gray-100 mt-2 p-4 text-sm">
                        <strong className="font-bold blsock mb-1 text-bColor">Ask Questions</strong>
                        <div className="ml-2">
                            <div className="flex flex-col">
                                <article className="flex flex-col border-b border-gray-200 pb-2">
                                    <div className="flex mt-4">
                                        <img className="self-start m-1" src="/assets/images/question.svg"  alt=""/>
                                        <div className="ml-2">
                                            <p className="text-base">Kati piece milxa</p>
                                            <div className="text-xs text-gray-400 w-80 flex justify-between">
                                                <span>Nirajan Rawal</span>
                                                <span>10 Min. ago</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex mt-4">
                                        <img className="self-start m-1" src="/assets/images/answer.svg"  alt=""/>
                                        <div className="ml-2">
                                            <p className="text-base">yo akdam milayara ho hajur</p>
                                            <div className="text-xs text-gray-400 w-80 flex justify-between">
                                                <span>Agrata Shopping Mall</span>
                                                <span>10 Min. ago</span>
                                            </div>
                                        </div>
                                    </div>
                                </article>
                                <article className="flex flex-col border-b border-gray-200 pb-2">
                                    <div className="flex mt-4">
                                        <img className="self-start m-1" src="/assets/images/question.svg"  alt=""/>
                                        <div className="ml-2">
                                            <p className="text-base">Kati piece milxa</p>
                                            <div className="text-xs text-gray-400 w-80 flex justify-between">
                                                <span>Nirajan Rawal</span>
                                                <span>10 Min. ago</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex mt-4">
                                        <img className="self-start m-1" src="/assets/images/answer.svg"  alt=""/>
                                        <div className="ml-2">
                                            <p className="text-base">yo akdam milayara ho hajur</p>
                                            <div className="text-xs text-gray-400 w-80 flex justify-between">
                                                <span>Agrata Shopping Mall</span>
                                                <span>10 Min. ago</span>
                                            </div>
                                        </div>
                                    </div>
                                </article>
                            </div>
                        </div>
                        <p className="text-center mt-6">
                            <a href="/"
                               className="px-4 py-1 text-white bg-bColor rounded-full text-sm">
                                See More
                            </a>
                        </p>
                    </div>
                </section>
            </div>
            <section id="related">
                <div className="flex justify-between my-4 text-xl">
                    <p>From this Store</p>
                    <a href="/" className="text-bColor font-bold">Visit Store</a>
                </div>
                <div className="products-grid">
                    <ProductCard/>
                    <ProductCard/>
                    <ProductCard/>
                    <ProductCard/>
                    <ProductCard/>
                </div>
            </section>
            <section id="recommended">
                <div className="flex justify-between my-4 text-xl">
                    <p>Recommended for you</p>
                </div>
                <div className="products-grid">
                    <ProductCard/>
                    <ProductCard/>
                    <ProductCard/>
                    <ProductCard/>
                    <ProductCard/>
                </div>
            </section>
        </div>
    )
}