import {Tab} from "@headlessui/react";

export default function GenericOrgProductTab({items}) {

    const list = items.filter(current => current.items?.length);

    if (!list.length) return null

    return (
        // <div className="my-14">
        <section id="products" className="py-20">
            <div className="mx-auto max-w-1000">
            <Tab.Group>
                <Tab.List className="space-x-8 flex justify-center">
                    {items.filter(current => current.items?.length).map(current => {
                        return <Tab
                            className={({selected}) => `font-bold text-xl rounded-full px-8 py-2 ${selected ? " bg-gray-300" : "bg-gray-100"}`}
                            key={current.name}>
                            {current.name}
                        </Tab>
                    })}
                </Tab.List>
                <Tab.Panels className="mt-12">
                    {items.filter(current => current.items?.length).map(current => {
                        return (
                            <Tab.Panel className="flex flex-col items-center space-y-16" key={current.name}>
                                <div className="grid grid-cols-3 gap-6 content-center text-center w-full">
                                    {current.items?.map(item => {
                                        return (
                                            <div className="space-y-6 max-w-sm w-full flex flex-col items-center"
                                                 key={item.name}>
                                                <img src={item.image?.path || "/assets/images/megaphone.png"} alt=""
                                                     width={70} height={70}
                                                     className="rounded-2xl w-16 h-16 pointer-events-none"/>
                                                <h3 className="font-bold text-xl">{item.name}</h3>
                                                <p className="text-gray-400"/>
                                            </div>
                                        )
                                    })
                                    }
                                </div>
                            </Tab.Panel>
                        )
                    })}
                </Tab.Panels>
            </Tab.Group>
            </div>
        </section>
    )
}