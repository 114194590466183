// import SearchbarOnly from "./SearchbarOnly";
import SearchbarOnly2 from "./SearchbarOnly2";

export default function HomeSearchBar() {

    return (
        <>
            <div className="my-24 flex justify-center items-center flex-col">
                <div>
                    <img src="/logo.svg" alt="find all logo" className="w-20"/>
                </div>
                <p className="mt-4 text-indigo-900 font-black text-5xl">FIND ALL <span
                    className="text-bColor">NEPAL</span></p>
            </div>
            <div className="mx-4 md:mx-8">
                <SearchbarOnly2 full placeholder="Search what you need"/>
            </div>
        </>
    )
}
