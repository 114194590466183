import EducationTopMenu from "../../components/education/EducationTopMenu";
import EducationMainMenuWithBackground from "../../components/education/EducationMainMenuWithBackground";
import {useEffect, useState} from "react";
import EducationFooter from "../../components/education/EducationFooter";
import {SRLWrapper} from "simple-react-lightbox";

export default function Gallery() {

    function getRandomSize(min, max) {
        return Math.round(Math.random() * (max - min) + min);
    }

    const [sizes, setSizes] = useState([]);

    useEffect(() => {
        const temp = []
        for (let i = 0; i < 10; i++) {
            let width = getRandomSize(200, 400);
            let height = getRandomSize(200, 400);
            temp.push({width, height})
        }
        setSizes(temp)
    }, [])

    return (
        <>
            <section id="hero">
                <EducationTopMenu/>
                <EducationMainMenuWithBackground background="/assets/images/edu_events_bg.png" height="25rem"/>
            </section>
            <main className="my-10">
                <div className="max-w-1170 mx-auto">
                    <section id="gallery">
                        <div className="flex space-x-4 mb-8">
                            <div
                                className="tab-bubble flex justify-center items-center flex-1 tab-bubble-left active-tab-bubble">
                                <span className="block p-6 text-3xl font-bold uppercase">Photo Gallery</span>
                            </div>
                            <div className="tab-bubble flex justify-center items-center flex-1 tab-bubble-right">
                                <span className="block p-6 text-3xl font-bold uppercase">Video Gallery</span>
                            </div>
                        </div>
                        <div className="gallery-grid">
                            <SRLWrapper>
                                {sizes.map(item => {
                                    return (
                                        // <a href={`https://lorempixel.com/${item.width}/${item.height}/food`}>
                                            <img key={item.width}
                                                 src={`https://lorempixel.com/${item.width}/${item.height}/food`}
                                                 alt="" loading="lazy"/>
                                        // </a>
                                    )
                                })}
                            </SRLWrapper>
                        </div>
                    </section>
                </div>
            </main>
            <EducationFooter/>
        </>
    )
}