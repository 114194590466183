import {Link, NavLink} from "react-router-dom";
import UserMenu from "./UserMenu";

export default function GenericOrgHeader({data}) {
    return (
        <header className="max-w-1000 w-full mx-auto bg-white px-4 py-4">
            <div className="flex justify-between items-center">
                <Link to={`/${data.slug}`}>
                    <img src={data.logo?.path || "/logo_only.jpg"} alt="" height={50} width={50} title={data.name}
                         className="w-12 h-12"/>
                </Link>
                <div className="flex items-center space-x-8">
                    <nav className="space-x-8">
                        {/*<NavLink activeClassName={"generic-org-active-nav"} className="relative inline-block" to={`/${data.slug}/products`}>Products</NavLink>*/}
                        <NavLink className={({isActive}) => `relative inline-block ${isActive ? "generic-org-active-nav" : ""}`}
                                 to={`/${data.slug}`} end>Home</NavLink>
                        <NavLink className={({isActive}) => `relative inline-block ${isActive ? "generic-org-active-nav" : ""}`}
                                 to={`/${data.slug}/events`}>Events</NavLink>
                        <NavLink className={({isActive}) => `relative inline-block ${isActive ? "generic-org-active-nav" : ""}`}
                                 to={`/${data.slug}/gallery`}>Gallery</NavLink>
                        <NavLink className={({isActive}) => `relative inline-block ${isActive ? "generic-org-active-nav" : ""}`}
                                 to={`/${data.slug}/teams`}>Teams</NavLink>
                        <NavLink className={({isActive}) => `relative inline-block ${isActive ? "generic-org-active-nav" : ""}`}
                                 to={`/${data.slug}/jobs`}>Jobs</NavLink>
                        <NavLink className={({isActive}) => `relative inline-block ${isActive ? "generic-org-active-nav" : ""}`}
                                 to={`/${data.slug}/about`}>About</NavLink>
                    </nav>
                    <UserMenu/>
                </div>
            </div>
        </header>
    )
}