import ShopSecondaryHeader from "../../components/shop/ShopSecondaryHeader";
import {useState} from "react";
import {Link} from "react-router-dom";
import routes from "../../constants/routes";

export default function Payment() {

    const [paymentMethod, setPaymentMethod] = useState("online");

    const handleCodChange = event => {
        setPaymentMethod(event.target.name)
    }

    return (
        <div className="max-w-screen-xl mx-auto">
            <ShopSecondaryHeader/>
            <h2 className="mb-4 text-lg">Checkout Information</h2>
            <div className="font-bold">
                <div className="mb-2">
                    <label>
                        <input className="mr-2" type="radio" name="cod" checked={paymentMethod === "cod"}
                               onChange={handleCodChange}/>
                        <span>Cash on Delivery</span>
                    </label>
                </div>
                <div>
                    <label>
                        <input className="mr-2" type="radio" name="online" checked={paymentMethod === "online"}
                               onChange={handleCodChange}/>
                        <span>Pay Online Through</span>
                    </label>
                </div>
                {paymentMethod === "online" &&
                <div className="flex space-x-4 mt-6 ml-4">
                    <div className="border border-gray-300 rounded-xl p-2 cursor-pointer w-24 h-24 flex items-center">
                        <img src="/assets/images/fonepay.png" className="w-full" alt=""/>
                    </div>
                    <div className="border border-gray-300 rounded-xl p-2 cursor-pointer  w-24 h-24 flex items-center">
                        <img src="/assets/images/khalti.png" className="w-full" alt=""/>
                    </div>
                    <div className="border border-gray-300 rounded-xl p-2 cursor-pointer w-24 h-24 flex items-center">
                        <img src="/assets/images/ICON_CREDIT-CARD.svg" className="w-20"  alt=""/>
                    </div>
                </div>
                }
                <div className="my-6">
                    <Link to={routes.orderConfirmed} className="rounded-lg py-2 px-8 bg-bColor text-white inline-block" >
                        Confirm your order
                    </Link>
                </div>
            </div>
        </div>
    )
}