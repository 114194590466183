import HomeSearchBar from "../components/HomeSearchBar";
import HomeCards from "../components/HomeCards";
import HomeHeader from "../components/HomeHeader";
import MainFooter from "../components/MainFooter";
import {Helmet} from "react-helmet-async";

export default function Home() {
    return (
        <>
            <Helmet>
                <meta property="og:title" content="Search what you need - Find All Nepal"/>
                <meta name="description"
                      content="List your business, accurate and reliable information, analysis and filtration of information, software company, search engine"/>
                <meta property="og:description"
                      content="List your business, accurate and reliable information, analysis and filtration of information, software company, search engine"/>
                <meta property="og:url" content="https://findallnepal.com"/>
                <meta property="og:image" content="https://findallnepal.com/find_all.png"/>
                <link rel="canonical" href="https://findallnepal.com"/>
                <title>Search what you need - Find All Nepal</title>
            </Helmet>
            <div className="flex-grow">
                <HomeHeader/>
                <div className="container max-w-4xl mx-auto">
                    <HomeSearchBar/>
                    <HomeCards/>
                </div>
            </div>
            <MainFooter/>
        </>
    )
}