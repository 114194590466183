import {Link} from "react-router-dom";
import routes from "../../constants/routes";

export default function ProductCard() {
    return (
        <article className="relative">
            <Link className="block bg-gray-100 p-3" to={`${routes.product}/some-hoodie`}>
                <figure className="flex flex-col">
                    <img src="/assets/images/product_image.png" alt=""/>
                    <figcaption className="mt-2">Hoodie for Men</figcaption>
                </figure>
                <div className="mt-2 font-bold text-sm space-x-2">
                    <span className="inline-block">Rs. 2500</span>
                    <span className="inline-block text-gray-400 line-through">Rs. 2800</span>
                    <strong className="text-bColor">10% off</strong>
                </div>
                <div className="Stars mt-1" style={{"--rating": "2.5", "--star-size": "1.7rem"}}
                     aria-label="Rating of this product."/>
            </Link>
            <p className="absolute top-4 right-4 cursor-pointer">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none"
                     viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1}
                          d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"/>
                </svg>
            </p>
        </article>
    )
}