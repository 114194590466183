import React, {useEffect, useRef, useState} from "react";
import SimpleReactLightbox, {SRLWrapper} from "simple-react-lightbox";
import {timeAgo} from "../../others/newsUtils";
import {useParams} from "react-router-dom";
import VoiceSkeleton from "../../components/VoiceSkeleton";
import {useDispatch, useSelector} from "react-redux";
import {
    doGetComments, doGetPosts, doLikeUnLikeComment, doLikeUnLikePost, doPostComment, doReGetPosts, doToggleLoginPopup
} from "../../store/voiceSlice";
import {useInView} from "react-intersection-observer";
import millify from "millify";
import VoiceDisplayPic from "../../components/voice/VoiceDisplayPic";
import CommentsPopup from "../../components/voice/CommentsPopup";

export const postTypes = {literature: "nt", jokes: "ha", status: "np", opinion: "wa", politics: "no", idea: "na",};

export default function VoiceHomeNew() {

    const {type} = useParams();
    const [toggleComment, setToggleComment] = useState(false);
    const [currentPostSlug, setCurrentPostSlug] = useState("");

    const {ref, inView} = useInView({
        threshold: 0
    })

    const dispatch = useDispatch();

    const {data: postList, isLoading, showLoginPopup} = useSelector(state => state.voice);
    const {data, isLoading: isLoadingUser} = useSelector(state => state.user);

    useEffect(() => {
        if (!isLoadingUser && data?.user) {
            dispatch(doGetPosts({type: postTypes[type] || "all", auth: true}))
        } else if (!isLoadingUser && !data?.user) {
            dispatch(doGetPosts({type: postTypes[type] || "all", auth: false}))
        }
    }, [isLoadingUser, type]);

    useEffect(() => {
        if (inView) {
            if (!isLoadingUser && data?.user) {
                dispatch(doReGetPosts({type: postTypes[type] || "all", next: postList.next, auth: true}))
            } else if (!isLoadingUser && !data?.user) {
                dispatch(doReGetPosts({type: postTypes[type] || "all", next: postList.next, auth: false}))
            }
        }
    }, [inView]);

    const handleLikeUnLikePost = (currentPost) => {
        if (data?.user) {
            dispatch(doLikeUnLikePost({slug: currentPost.slug, action: currentPost.is_heart ? "unlike" : "like"}))
        } else {
            dispatch(doToggleLoginPopup(!showLoginPopup))
        }
    }

    const handleLikeUnLikeComment = (slug, id, isLiked, path, index) => {
        if (data?.user) {
            dispatch(doLikeUnLikeComment({id, slug, action: isLiked ? "unlike" : "like", path, index}))
        } else {
            dispatch(doToggleLoginPopup(!showLoginPopup))
        }
    }

    const handleToggleComment = (slug) => {
        if (data?.user) {
            setToggleComment(prevState => !prevState)
            setCurrentPostSlug(slug)
            dispatch(doGetComments({slug}))
        } else {
            dispatch(doToggleLoginPopup(!showLoginPopup))
        }
    }

    if (isLoading) return (
        <div className="space-y-4 max-w-[636px]">
            <div className="bg-white p-4">
                <VoiceSkeleton height={150}/>
            </div>
            <div className="bg-white p-4">
                <VoiceSkeleton height={200}/>
            </div>
            <div className="bg-white p-4">
                <VoiceSkeleton height={200}/>
            </div>
        </div>
    )

    if (!isLoading && postList) {
        return (
            <main className="space-y-4 max-w-[636px]">
                {postList.posts.map((current, postIndex) => {
                    // if (current.slug === "Q2kgCX5MzozpMYMiVkQ3se4LYoTJJmE6U8h8SL1xawiyKwj8676w8v92PjCosf5S")
                    //     console.log(current)
                    return (
                        <article key={current.slug} className="space-y-0.5">
                            <div className="bg-white rounded-md">
                                <div className="p-4 space-y-3">
                                    <div className="flex items-center space-x-2">
                                        <img src={current.authorImage || "https://placekitten.com/32/32"} alt=""
                                             className="w-8 h-8 rounded-full pointer-events-none"
                                             width={32} height={32}/>
                                        <div>
                                            <strong className="font-medium"><a
                                                href={current.authorProfile}>{current.author}</a></strong>
                                            <div
                                                className="flex items-center space-x-2 text-xs text-gray-400 font-light">
                                                <span>{current.type}</span>
                                                <span
                                                    className="border border-gray-400 h-1 w-1 block rounded-full bg-gray-400"/>
                                                <span>{timeAgo(current.timestamp)}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="break-all">{current.post || null}</p>
                                </div>
                                <PostImages imageList={current.image}/>
                                <div className="p-2 flex justify-between border-t border-t-slate-200">
                                    <div className="flex space-x-6">
                                        <button title={current.is_heart ? "UnHeart this post" : "Heart this post"}
                                                onClick={(() => handleLikeUnLikePost(current))}
                                                className="flex justify-center items-end space-x-1.5 hover:bg-slate-100 p-2 rounded-md">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5"
                                                 viewBox="0 0 20 20"
                                                 stroke={current.is_heart ? "" : "currentColor"}
                                                 strokeWidth={current.is_heart ? "" : "2"}
                                                 fill={current.is_heart ? "#92278f" : "transparent"}>
                                                <path
                                                    d="M2 10.5a1.5 1.5 0 113 0v6a1.5 1.5 0 01-3 0v-6zM6 10.333v5.43a2 2 0 001.106 1.79l.05.025A4 4 0 008.943 18h5.416a2 2 0 001.962-1.608l1.2-6A2 2 0 0015.56 8H12V4a2 2 0 00-2-2 1 1 0 00-1 1v.667a4 4 0 01-.8 2.4L6.8 7.933a4 4 0 00-.8 2.4z"/>
                                            </svg>
                                            <div
                                                className={`leading-none ${current.is_heart ? "text-bColor" : "text-inherit"}`}>{current.heart_count ? millify(current.heart_count) : ""}</div>
                                        </button>
                                        <button
                                            className="flex justify-center items-end space-x-2 hover:bg-slate-100 p-2 rounded-md"
                                            title="Comment on this post"
                                            onClick={() => handleToggleComment(current.slug)}>
                                            <img src="/assets/comment.svg" alt="" className="pointer-events-none"/>
                                            <div
                                                className="leading-none">{current.comment_count ? millify(current.comment_count) : ""}</div>
                                        </button>
                                    </div>
                                    <button title="Share this post"
                                            className="flex justify-center items-end space-x-2 hover:bg-slate-100 p-2 rounded-md">
                                        <img src="/assets/share.svg" alt="" className="pointer-events-none"/>
                                    </button>
                                </div>
                            </div>
                            {/*comments section*/}

                            <div className="bg-white p-4 space-y-4 rounded-sm">
                                {current.comments.length > 0 &&
                                    <>
                                        {(current.comment_count > 2) &&
                                            <button className="hover:underline text-sm" title="View all comments"
                                                    onClick={() => handleToggleComment(current.slug)}>
                                                View all comments
                                            </button>
                                        }
                                        <PostCommentsTree comments={current.comments} slug={current.slug}
                                                          path={"[" + postIndex + "].comments"}
                                                          handleLikeUnLikeComment={handleLikeUnLikeComment}/>
                                    </>
                                }
                                {data?.user &&
                                    <PostCommentFormBox slug={current.slug} path={"[" + postIndex + "].comments"}/>
                                }
                            </div>
                        </article>
                    )
                })}
                {postList.hasMore
                    ? <div className="space-y-4 max-w-[636px]" ref={ref}>
                        <div className="bg-white p-4">
                            <VoiceSkeleton height={150}/>
                        </div>
                        <div className="bg-white p-4">
                            <VoiceSkeleton height={200}/>
                        </div>
                    </div>
                    : <div className="space-y-4 max-w-[636px] text-center bg-gray-200 p-4">
                        <span>You are all caught up!</span>
                    </div>
                }
                <CommentsPopup setToggleComment={setToggleComment} toggleComment={toggleComment}
                               slug={currentPostSlug}/>
            </main>
        );
    }

    return <div>nothing to show</div>

}

function PostImages({imageList}) {
    if (imageList.length > 1) {
        return <SimpleReactLightbox>
            <SRLWrapper>
                <div
                    className="grid grid-cols-[repeat(auto-fill,_minmax(260px,_1fr))] grid-rows-[repeat(auto-fit,_260px)] grid-dense h-full overflow-hidden bg-slate-800">
                    {imageList.map((item, i) =>
                        <ImageGridItem src={item} className="w-full h-auto cursor-pointer" key={i + item}/>
                    )}
                </div>
            </SRLWrapper>
        </SimpleReactLightbox>
    }
    if (imageList.length === 1) {
        return <SimpleReactLightbox>
            <SRLWrapper>
                <div className="overflow-hidden">
                    {imageList.map((item, i) =>
                        <img src={item} alt="" className="w-full h-auto cursor-pointer"
                             key={i + item}/>
                    )}
                </div>
            </SRLWrapper>
        </SimpleReactLightbox>
    }
    return null
}


function ImageGridItem({src, className}) {
    const styles = {
        gridColumnEnd: `span ${getSpanEstimate(() => getMeta(src, (w, h) => w))}`,
        gridRowEnd: `span ${getSpanEstimate(() => getMeta(src, (w, h) => h))}`,
    }
    return <img className={`${className}`} style={styles} src={src} alt=""/>
}

function getSpanEstimate(size) {
    if (size > 250) {
        return 2
    }
    return 1
}

function getMeta(url, callback) {
    const img = new Image();
    img.src = url;
    img.onload = function () {
        console.log(this.naturalWidth, this.naturalHeight)
        callback(this.naturalWidth, this.naturalHeight)
    };
}

function PostCommentsTree({comments, handleLikeUnLikeComment, slug, path}) {

    return comments.map((item, index) => {
        return (
            // <div key={item.id} className={`space-y-2 ${item.parent ? "ml-8" : ""}`}>
            <PostCommentTreeItem slug={slug} handleLikeUnLikeComment={handleLikeUnLikeComment} item={item}
                                 key={item.id} index={index} path={path}/>
        )
    }).reverse()
}

function PostCommentTreeItem({item, handleLikeUnLikeComment, slug, index, path}) {

    const [replyBox, setReplyBox] = useState(false);

    const {data, isLoading: isLoadingUser} = useSelector(state => state.user);
    const {showLoginPopup} = useSelector(state => state.voice);

    const dispatch = useDispatch();

    const handleReplyBox = () => {
        if (data?.user) {
            setReplyBox(prevState => !prevState)
        } else {
            dispatch(doToggleLoginPopup(!showLoginPopup))
        }
    }


    return (
        <div className={`space-y-4 ${item.parent ? "ml-8" : ""}`}>
            <div className="space-y-2">
                <div className="flex items-start gap-1 relative">
                    <img src={item?.commenter?.image?.path || ""} alt="" height={28} width={28}
                         className="h-7 w-7 rounded-full"/>
                    <div className="w-full text-sm">
                        <div className="rounded-md bg-gray-100 px-3 py-2">
                            <div className="flex items-center space-x-2 text-sm">
                                <a href={item.commenter.profile} className="font-bold" target="_blank"
                                   rel="noreferrer">
                                    {item.commenter.name}
                                </a>
                                <span className="w-[3px] h-[3px] bg-gray-400 rounded-full"/>
                                <span className="font-light text-gray-500 text-xs"> {item.datetime_human} </span>
                            </div>
                            <span className="break-all text-gray-600 tracking-wide">{item.comment}</span>
                        </div>
                        <div className="space-x-1">
                            <button className="px-2 py-0.5 hover:underline"
                                    onClick={() => handleLikeUnLikeComment(slug, item.id, item.isLiked, path, index)}>
                                {item.isLiked ? "Unlike" : "Like"}
                            </button>
                            <button className="px-2 py-0.5" onClick={handleReplyBox}>Reply</button>
                        </div>
                    </div>
                    {item.likes
                        ? <div className="flex justify-center items-center space-x-1 absolute right-2 top-2">
                            <svg xmlns="http://www.w3.org/2000/svg"
                                 className="h-5 w-5 rounded-full bg-gray-200 p-0.5"
                                 viewBox="0 0 24 24" stroke={item.isLiked ? "" : "currentColor"}
                                 strokeWidth={item.isLiked ? "" : "2"}
                                 fill={item.isLiked ? "#92278f" : "transparent"}>
                                <path
                                    d="M2 10.5a1.5 1.5 0 113 0v6a1.5 1.5 0 01-3 0v-6zM6 10.333v5.43a2 2 0 001.106 1.79l.05.025A4 4 0 008.943 18h5.416a2 2 0 001.962-1.608l1.2-6A2 2 0 0015.56 8H12V4a2 2 0 00-2-2 1 1 0 00-1 1v.667a4 4 0 01-.8 2.4L6.8 7.933a4 4 0 00-.8 2.4z"/>
                            </svg>
                            <div
                                className={`leading-none text-xs ${item.isLiked ? "text-bColor" : "text-inherit"}`}>
                                {item.likes ? millify(item.likes) : ""}
                            </div>
                        </div>
                        : ""
                    }
                </div>
                {replyBox &&
                    <div className="ml-7">
                        <PostCommentFormBox id={item.id} slug={slug} path={`${path}[${index}]`}/>
                    </div>
                }
            </div>
            {item.reply?.length ?
                <PostCommentsTree comments={item.reply} handleLikeUnLikeComment={handleLikeUnLikeComment}
                                  path={path + "[" + index + "].reply"}
                                  slug={slug}/> : ""}
        </div>
    )
}

function PostCommentFormBox({slug, id, path}) {

    const dispatch = useDispatch();
    const [comments, setComments] = useState({text: "", isLoading: false, error: {status: false, message: ""}});

    const handleCommentChange = (event) => {
        setComments(prevState => ({isLoading: false, error: {status: false, message: ""}, text: event.target.value})
        )
    }

    const handleCommentSubmit = (event, slug, id) => {
        console.log(path)
        event.preventDefault();
        setComments(prevState => ({...prevState, error: {status: false, message: ""}, isLoading: true}))
        const commentText = comments.text.trim();
        if (commentText.length > 0)
            dispatch(doPostComment({slug, comment: comments.text, id: id || "", path}))
                .unwrap()
                .then((result) => {
                    console.log(result)
                    setComments({text: "", isLoading: false, error: {status: false, message: ""}})
                }).catch(error => {
                setComments(prevState => ({
                    ...prevState,
                    isLoading: false,
                    error: {status: true, message: "Error occurred, Refresh and try again."}
                }))
            })
    }

    return (
        <>
            <form onSubmit={(event) => handleCommentSubmit(event, slug, id)}
                  className="gap-1 flex items-start">
                <VoiceDisplayPic/>
                <div className="space-y-4 flex-grow">
                    <input type="text" value={comments.text} disabled={comments.isLoading}
                           onChange={handleCommentChange}
                           className="text-sm rounded-md bg-gray-100 w-full p-2" placeholder="Write your comment.."
                           required/>
                    <button className="text-sm bg-slate-500 text-white px-4 py-1 rounded-md hidden" type="submit">
                        Post
                    </button>
                </div>
            </form>
            {comments.error.status &&
                <span className="text-xs text-red-700 ml-8">{comments.error.message}</span>
            }
        </>
    )
}