import EducationTopMenu from "../../components/education/EducationTopMenu";
import EducationMainMenuWithBackground from "../../components/education/EducationMainMenuWithBackground";
import EducationFooter from "../../components/education/EducationFooter";
import EducationSectionHeading from "../../components/education/EducationSectionHeading";

export default function Team() {
    return (
        <>
            <section id="hero">
                <EducationTopMenu/>
                <EducationMainMenuWithBackground background="/assets/images/edu_events_bg.png" height="25rem"/>
            </section>
            <main className="my-10">
                <div className="max-w-1170 mx-auto">
                    <EducationSectionHeading text="Meet Our"/>
                    <span className="block text-5xl font-bold text-black mb-12 uppercase">Team Members</span>
                    <section id="bod" className="mb-16">
                        <div className="relative">
                            <h3 className="font-bold text-lg relative bg-white z-10 inline-block pr-2">BOD (Board of
                                Director)</h3>
                            <div className="border border-gray-300 absolute top-1/2 w-full"/>
                        </div>
                        <div className="grid grid-cols-3 gap-3 mt-10">
                            <div className="relative rounded-sm edu_team_member transition">
                                <img src="/assets/images/team_member1.png" alt=""/>
                                <div className="absolute bottom-4 left-10 right-10 text-center py-2 rounded-sm z-10">
                                    <div className="p-3 bg-white rounded-sm">
                                        <strong className="block leading-none text-xl">Samayak Sharma</strong>
                                        <span className="text-sm text-gray-400">Chairperson</span>
                                    </div>
                                </div>
                                <div
                                    className="bg-education absolute top-0 bottom-0 left-0 right-0 rounded-sm z-20 hidden edu_team_member_hover_content">
                                    <div
                                        className="text-white flex justify-evenly items-center flex-col h-full px-10 py-6">
                                        <div className="flex-grow mt-10">
                                            <div className="flex justify-center space-x-4 mb-6">
                                                <img src="/assets/images/about_email.png" alt=""/>
                                                <img src="/assets/images/about_phone.png" alt=""/>
                                            </div>
                                            <div className="flex items-center ">
                                                <a href="/"><img src="/assets/images/instagram.svg" alt="" width={30}
                                                                 className="inline-block mr-2"/></a>
                                                <a href="/"><img src="/assets/images/facebook.svg" alt="" width={30}
                                                                 className="inline-block mr-2"/></a>
                                                <a href="/"><img src="/assets/images/linkedin.svg" alt="" width={30}
                                                                 className="inline-block mr-2"/></a>
                                                <a href="/"><img src="/assets/images/twitter.svg" alt="" width={30}
                                                                 className="inline-block mr-2"/></a>
                                                <a href="/"><img src="/assets/images/youtube.svg" alt="" width={36}
                                                                 className="inline-block mr-2"/></a>
                                            </div>
                                        </div>
                                        <div className="p-3 bg-white rounded-sm text-center w-full justify-self-end">
                                            <strong className="block leading-none text-xl text-black">Samayak
                                                Sharma</strong>
                                            <span className="text-sm text-gray-400">Chairperson</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="relative rounded-sm edu_team_member transition">
                                <img src="/assets/images/team_member1.png" alt=""/>
                                <div className="absolute bottom-4 left-10 right-10 text-center py-2 rounded-sm z-10">
                                    <div className="p-3 bg-white rounded-sm">
                                        <strong className="block leading-none text-xl">Samayak Sharma</strong>
                                        <span className="text-sm text-gray-400">Chairperson</span>
                                    </div>
                                </div>
                                <div
                                    className="bg-education absolute top-0 bottom-0 left-0 right-0 rounded-sm z-20 hidden edu_team_member_hover_content">
                                    <div
                                        className="text-white flex justify-evenly items-center flex-col h-full px-10 py-6">
                                        <div className="flex-grow mt-10">
                                            <div className="flex justify-center space-x-4 mb-6">
                                                <img src="/assets/images/about_email.png" alt=""/>
                                                <img src="/assets/images/about_phone.png" alt=""/>
                                            </div>
                                            <div className="flex items-center ">
                                                <a href="/"><img src="/assets/images/instagram.svg" alt="" width={30}
                                                                 className="inline-block mr-2"/></a>
                                                <a href="/"><img src="/assets/images/facebook.svg" alt="" width={30}
                                                                 className="inline-block mr-2"/></a>
                                                <a href="/"><img src="/assets/images/linkedin.svg" alt="" width={30}
                                                                 className="inline-block mr-2"/></a>
                                                <a href="/"><img src="/assets/images/twitter.svg" alt="" width={30}
                                                                 className="inline-block mr-2"/></a>
                                                <a href="/"><img src="/assets/images/youtube.svg" alt="" width={36}
                                                                 className="inline-block mr-2"/></a>
                                            </div>
                                        </div>
                                        <div className="p-3 bg-white rounded-sm text-center w-full justify-self-end">
                                            <strong className="block leading-none text-xl text-black">Samayak
                                                Sharma</strong>
                                            <span className="text-sm text-gray-400">Chairperson</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="relative rounded-sm edu_team_member transition">
                                <img src="/assets/images/team_member1.png" alt=""/>
                                <div className="absolute bottom-4 left-10 right-10 text-center py-2 rounded-sm z-10">
                                    <div className="p-3 bg-white rounded-sm">
                                        <strong className="block leading-none text-xl">Samayak Sharma</strong>
                                        <span className="text-sm text-gray-400">Chairperson</span>
                                    </div>
                                </div>
                                <div
                                    className="bg-education absolute top-0 bottom-0 left-0 right-0 rounded-sm z-20 hidden edu_team_member_hover_content">
                                    <div
                                        className="text-white flex justify-evenly items-center flex-col h-full px-10 py-6">
                                        <div className="flex-grow mt-10">
                                            <div className="flex justify-center space-x-4 mb-6">
                                                <img src="/assets/images/about_email.png" alt=""/>
                                                <img src="/assets/images/about_phone.png" alt=""/>
                                            </div>
                                            <div className="flex items-center ">
                                                <a href="/"><img src="/assets/images/instagram.svg" alt="" width={30}
                                                                 className="inline-block mr-2"/></a>
                                                <a href="/"><img src="/assets/images/facebook.svg" alt="" width={30}
                                                                 className="inline-block mr-2"/></a>
                                                <a href="/"><img src="/assets/images/linkedin.svg" alt="" width={30}
                                                                 className="inline-block mr-2"/></a>
                                                <a href="/"><img src="/assets/images/twitter.svg" alt="" width={30}
                                                                 className="inline-block mr-2"/></a>
                                                <a href="/"><img src="/assets/images/youtube.svg" alt="" width={36}
                                                                 className="inline-block mr-2"/></a>
                                            </div>
                                        </div>
                                        <div className="p-3 bg-white rounded-sm text-center w-full justify-self-end">
                                            <strong className="block leading-none text-xl text-black">Samayak
                                                Sharma</strong>
                                            <span className="text-sm text-gray-400">Chairperson</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section id="teach-staff">
                        <div className="relative">
                            <h3 className="font-bold text-lg relative bg-white z-10 inline-block pr-2">Teacher &
                                Staffs</h3>
                            <div className="border border-gray-300 absolute top-1/2 w-full"/>
                        </div>
                        <div className="grid grid-cols-3 gap-3 mt-10">
                            <div className="relative rounded-sm edu_team_member transition">
                                <img src="/assets/images/team_member1.png" alt=""/>
                                <div className="absolute bottom-4 left-10 right-10 text-center py-2 rounded-sm z-10">
                                    <div className="p-3 bg-white rounded-sm">
                                        <strong className="block leading-none text-xl">Samayak Sharma</strong>
                                        <span className="text-sm text-gray-400">Chairperson</span>
                                    </div>
                                </div>
                                <div
                                    className="bg-education absolute top-0 bottom-0 left-0 right-0 rounded-sm z-20 hidden edu_team_member_hover_content">
                                    <div
                                        className="text-white flex justify-evenly items-center flex-col h-full px-10 py-6">
                                        <div className="flex-grow mt-10">
                                            <div className="flex justify-center space-x-4 mb-6">
                                                <img src="/assets/images/about_email.png" alt=""/>
                                                <img src="/assets/images/about_phone.png" alt=""/>
                                            </div>
                                            <div className="flex items-center ">
                                                <a href="/"><img src="/assets/images/instagram.svg" alt="" width={30}
                                                                 className="inline-block mr-2"/></a>
                                                <a href="/"><img src="/assets/images/facebook.svg" alt="" width={30}
                                                                 className="inline-block mr-2"/></a>
                                                <a href="/"><img src="/assets/images/linkedin.svg" alt="" width={30}
                                                                 className="inline-block mr-2"/></a>
                                                <a href="/"><img src="/assets/images/twitter.svg" alt="" width={30}
                                                                 className="inline-block mr-2"/></a>
                                                <a href="/"><img src="/assets/images/youtube.svg" alt="" width={36}
                                                                 className="inline-block mr-2"/></a>
                                            </div>
                                        </div>
                                        <div className="p-3 bg-white rounded-sm text-center w-full justify-self-end">
                                            <strong className="block leading-none text-xl text-black">Samayak
                                                Sharma</strong>
                                            <span className="text-sm text-gray-400">Chairperson</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="relative rounded-sm edu_team_member transition">
                                <img src="/assets/images/team_member1.png" alt=""/>
                                <div className="absolute bottom-4 left-10 right-10 text-center py-2 rounded-sm z-10">
                                    <div className="p-3 bg-white rounded-sm">
                                        <strong className="block leading-none text-xl">Samayak Sharma</strong>
                                        <span className="text-sm text-gray-400">Chairperson</span>
                                    </div>
                                </div>
                                <div
                                    className="bg-education absolute top-0 bottom-0 left-0 right-0 rounded-sm z-20 hidden edu_team_member_hover_content">
                                    <div
                                        className="text-white flex justify-evenly items-center flex-col h-full px-10 py-6">
                                        <div className="flex-grow mt-10">
                                            <div className="flex justify-center space-x-4 mb-6">
                                                <img src="/assets/images/about_email.png" alt=""/>
                                                <img src="/assets/images/about_phone.png" alt=""/>
                                            </div>
                                            <div className="flex items-center ">
                                                <a href="/"><img src="/assets/images/instagram.svg" alt="" width={30}
                                                                 className="inline-block mr-2"/></a>
                                                <a href="/"><img src="/assets/images/facebook.svg" alt="" width={30}
                                                                 className="inline-block mr-2"/></a>
                                                <a href="/"><img src="/assets/images/linkedin.svg" alt="" width={30}
                                                                 className="inline-block mr-2"/></a>
                                                <a href="/"><img src="/assets/images/twitter.svg" alt="" width={30}
                                                                 className="inline-block mr-2"/></a>
                                                <a href="/"><img src="/assets/images/youtube.svg" alt="" width={36}
                                                                 className="inline-block mr-2"/></a>
                                            </div>
                                        </div>
                                        <div className="p-3 bg-white rounded-sm text-center w-full justify-self-end">
                                            <strong className="block leading-none text-xl text-black">Samayak
                                                Sharma</strong>
                                            <span className="text-sm text-gray-400">Chairperson</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="relative rounded-sm edu_team_member transition">
                                <img src="/assets/images/team_member1.png" alt=""/>
                                <div className="absolute bottom-4 left-10 right-10 text-center py-2 rounded-sm z-10">
                                    <div className="p-3 bg-white rounded-sm">
                                        <strong className="block leading-none text-xl">Samayak Sharma</strong>
                                        <span className="text-sm text-gray-400">Chairperson</span>
                                    </div>
                                </div>
                                <div
                                    className="bg-education absolute top-0 bottom-0 left-0 right-0 rounded-sm z-20 hidden edu_team_member_hover_content">
                                    <div
                                        className="text-white flex justify-evenly items-center flex-col h-full px-10 py-6">
                                        <div className="flex-grow mt-10">
                                            <div className="flex justify-center space-x-4 mb-6">
                                                <img src="/assets/images/about_email.png" alt=""/>
                                                <img src="/assets/images/about_phone.png" alt=""/>
                                            </div>
                                            <div className="flex items-center ">
                                                <a href="/"><img src="/assets/images/instagram.svg" alt="" width={30}
                                                                 className="inline-block mr-2"/></a>
                                                <a href="/"><img src="/assets/images/facebook.svg" alt="" width={30}
                                                                 className="inline-block mr-2"/></a>
                                                <a href="/"><img src="/assets/images/linkedin.svg" alt="" width={30}
                                                                 className="inline-block mr-2"/></a>
                                                <a href="/"><img src="/assets/images/twitter.svg" alt="" width={30}
                                                                 className="inline-block mr-2"/></a>
                                                <a href="/"><img src="/assets/images/youtube.svg" alt="" width={36}
                                                                 className="inline-block mr-2"/></a>
                                            </div>
                                        </div>
                                        <div className="p-3 bg-white rounded-sm text-center w-full justify-self-end">
                                            <strong className="block leading-none text-xl text-black">Samayak
                                                Sharma</strong>
                                            <span className="text-sm text-gray-400">Chairperson</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="relative rounded-sm edu_team_member transition">
                                <img src="/assets/images/team_member1.png" alt=""/>
                                <div className="absolute bottom-4 left-10 right-10 text-center py-2 rounded-sm z-10">
                                    <div className="p-3 bg-white rounded-sm">
                                        <strong className="block leading-none text-xl">Samayak Sharma</strong>
                                        <span className="text-sm text-gray-400">Chairperson</span>
                                    </div>
                                </div>
                                <div
                                    className="bg-education absolute top-0 bottom-0 left-0 right-0 rounded-sm z-20 hidden edu_team_member_hover_content">
                                    <div
                                        className="text-white flex justify-evenly items-center flex-col h-full px-10 py-6">
                                        <div className="flex-grow mt-10">
                                            <div className="flex justify-center space-x-4 mb-6">
                                                <img src="/assets/images/about_email.png" alt=""/>
                                                <img src="/assets/images/about_phone.png" alt=""/>
                                            </div>
                                            <div className="flex items-center ">
                                                <a href="/"><img src="/assets/images/instagram.svg" alt="" width={30}
                                                                 className="inline-block mr-2"/></a>
                                                <a href="/"><img src="/assets/images/facebook.svg" alt="" width={30}
                                                                 className="inline-block mr-2"/></a>
                                                <a href="/"><img src="/assets/images/linkedin.svg" alt="" width={30}
                                                                 className="inline-block mr-2"/></a>
                                                <a href="/"><img src="/assets/images/twitter.svg" alt="" width={30}
                                                                 className="inline-block mr-2"/></a>
                                                <a href="/"><img src="/assets/images/youtube.svg" alt="" width={36}
                                                                 className="inline-block mr-2"/></a>
                                            </div>
                                        </div>
                                        <div className="p-3 bg-white rounded-sm text-center w-full justify-self-end">
                                            <strong className="block leading-none text-xl text-black">Samayak
                                                Sharma</strong>
                                            <span className="text-sm text-gray-400">Chairperson</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="relative rounded-sm edu_team_member transition">
                                <img src="/assets/images/team_member1.png" alt=""/>
                                <div className="absolute bottom-4 left-10 right-10 text-center py-2 rounded-sm z-10">
                                    <div className="p-3 bg-white rounded-sm">
                                        <strong className="block leading-none text-xl">Samayak Sharma</strong>
                                        <span className="text-sm text-gray-400">Chairperson</span>
                                    </div>
                                </div>
                                <div
                                    className="bg-education absolute top-0 bottom-0 left-0 right-0 rounded-sm z-20 hidden edu_team_member_hover_content">
                                    <div
                                        className="text-white flex justify-evenly items-center flex-col h-full px-10 py-6">
                                        <div className="flex-grow mt-10">
                                            <div className="flex justify-center space-x-4 mb-6">
                                                <img src="/assets/images/about_email.png" alt=""/>
                                                <img src="/assets/images/about_phone.png" alt=""/>
                                            </div>
                                            <div className="flex items-center ">
                                                <a href="/"><img src="/assets/images/instagram.svg" alt="" width={30}
                                                                 className="inline-block mr-2"/></a>
                                                <a href="/"><img src="/assets/images/facebook.svg" alt="" width={30}
                                                                 className="inline-block mr-2"/></a>
                                                <a href="/"><img src="/assets/images/linkedin.svg" alt="" width={30}
                                                                 className="inline-block mr-2"/></a>
                                                <a href="/"><img src="/assets/images/twitter.svg" alt="" width={30}
                                                                 className="inline-block mr-2"/></a>
                                                <a href="/"><img src="/assets/images/youtube.svg" alt="" width={36}
                                                                 className="inline-block mr-2"/></a>
                                            </div>
                                        </div>
                                        <div className="p-3 bg-white rounded-sm text-center w-full justify-self-end">
                                            <strong className="block leading-none text-xl text-black">Samayak
                                                Sharma</strong>
                                            <span className="text-sm text-gray-400">Chairperson</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="relative rounded-sm edu_team_member transition">
                                <img src="/assets/images/team_member1.png" alt=""/>
                                <div className="absolute bottom-4 left-10 right-10 text-center py-2 rounded-sm z-10">
                                    <div className="p-3 bg-white rounded-sm">
                                        <strong className="block leading-none text-xl">Samayak Sharma</strong>
                                        <span className="text-sm text-gray-400">Chairperson</span>
                                    </div>
                                </div>
                                <div
                                    className="bg-education absolute top-0 bottom-0 left-0 right-0 rounded-sm z-20 hidden edu_team_member_hover_content">
                                    <div
                                        className="text-white flex justify-evenly items-center flex-col h-full px-10 py-6">
                                        <div className="flex-grow mt-10">
                                            <div className="flex justify-center space-x-4 mb-6">
                                                <img src="/assets/images/about_email.png" alt=""/>
                                                <img src="/assets/images/about_phone.png" alt=""/>
                                            </div>
                                            <div className="flex items-center ">
                                                <a href="/"><img src="/assets/images/instagram.svg" alt="" width={30}
                                                                 className="inline-block mr-2"/></a>
                                                <a href="/"><img src="/assets/images/facebook.svg" alt="" width={30}
                                                                 className="inline-block mr-2"/></a>
                                                <a href="/"><img src="/assets/images/linkedin.svg" alt="" width={30}
                                                                 className="inline-block mr-2"/></a>
                                                <a href="/"><img src="/assets/images/twitter.svg" alt="" width={30}
                                                                 className="inline-block mr-2"/></a>
                                                <a href="/"><img src="/assets/images/youtube.svg" alt="" width={36}
                                                                 className="inline-block mr-2"/></a>
                                            </div>
                                        </div>
                                        <div className="p-3 bg-white rounded-sm text-center w-full justify-self-end">
                                            <strong className="block leading-none text-xl text-black">Samayak
                                                Sharma</strong>
                                            <span className="text-sm text-gray-400">Chairperson</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </main>
            <EducationFooter/>
        </>
    )
}