import {Link} from "react-router-dom";
import {timeAgo} from "../others/newsUtils";
import React from "react";

export default function NewsArticle({current}) {
    return (
        <article id="post-card" className="flex flex-col">
            <Link to={`/${current.slug}`}
                  className="flex items-start justify-between bg-white p-4 h-full">
                <div className="flex flex-col h-full mr-2">
                    <h3 className="text-lg mb-4">{current.title}</h3>
                    <div className="mb-4 text-sm flex-grow">
                        {/*{parseDetail(current.detail)}*/}
                        {current.summary?.slice(0, 100)}
                    </div>
                    <div className="flex text-gray-600">
                        <span className="mr-1 underline font-light text-sm">Read More</span>
                        <svg xmlns="http://www.w3.org/2000/svg"
                             className="h-5 w-5"
                             fill="none"
                             viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M13 7l5 5m0 0l-5 5m5-5H6"/>
                        </svg>
                    </div>
                </div>
                {/*<div className="w-40 h-40" style={{backgroundImage: `url(${current.featuredImage?.path || ""})`}}/>*/}
                <img src={`${current.featuredImage?.path}`} alt="" width="160" height="160"
                     loading="lazy" className="w-40 h-auto pointer-events-none"/>
            </Link>
            <div className="mt-2 flex items-center">
                <div className="h-0 border-t border-gray-300 flex-1 mx-4"/>
                <span
                    className="text-sm mr-4">{timeAgo(current.featuredImage?.created_at)}</span>
            </div>
        </article>
    )
}