import UserMenu from "../UserMenu";
import {Link, NavLink} from "react-router-dom";
import routes from "../../constants/routes";
import {useSelector} from "react-redux";

export default function VoiceHeaderNew({handleShowCreatePost, handleShowLoginPopup}) {

    const {data, isLoading} = useSelector(state => state.user);

        return (
        <header className="bg-white sticky top-0 shadow z-20">
            <div className="max-w-1920 px-10 py-2 grid grid-cols-[1fr_960px_minmax(200px,1fr)] items-center">
                <div className="flex justify-between space-x-20 col-span-2">
                    <div className="space-x-4 w-full flex items-center">
                        <Link to="/voice">
                            <img src="/assets/voice-logo.svg" alt="" className="pointer-events-none w-36"/>
                        </Link>
                        <input type="text" className="bg-gray-100 p-2.5 w-full"/>
                    </div>
                    <div className="flex space-x-4 items-center">
                        <nav className="text-center flex font-light text-sm text-gray-500">
                            <NavLink to={routes.voice} end
                                     className={({isActive}) => `px-4 py-1  flex flex-col items-center justify-between w-20 relative ${isActive ? "text-bColor bg-gray-100 voice-menu" : ""} transition-all hover:bg-gray-100`}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28"
                                     fill="currentColor">
                                    <g id="Group_7" data-name="Group 7" transform="translate(-30.468 -525.681)">
                                        <g id="Group_6" data-name="Group 6">
                                            <path id="Path_1" data-name="Path 1"
                                                  d="M30.468,540.064V539.3a3.888,3.888,0,0,1,1.262-1.956q5.2-5.176,10.381-10.38a3.916,3.916,0,0,1,1.974-1.281h.766a3.873,3.873,0,0,1,1.954,1.262q5.2,5.22,10.417,10.415a3.741,3.741,0,0,1,1.246,2v.656a.763.763,0,0,0-.047.125,2.555,2.555,0,0,1-2.545,2.121h-.412v.369c0,2.7.007,5.41-.006,8.115a2.983,2.983,0,0,1-2.1,2.8c-.172.053-.347.095-.521.143H47.8a.971.971,0,0,1-.653-1.042c.013-2.085.007-4.17,0-6.255a1.383,1.383,0,0,0-1.5-1.494q-1.161,0-2.322,0a1.393,1.393,0,0,0-1.556,1.547c0,2.066-.008,4.133.005,6.2a.973.973,0,0,1-.651,1.045H36.1a.2.2,0,0,0-.07-.039,3.061,3.061,0,0,1-2.57-3.149c.036-2.632.009-5.266.009-7.9v-.34c-.191,0-.344.007-.5,0a2.514,2.514,0,0,1-2.218-1.4A5.791,5.791,0,0,1,30.468,540.064Z"/>
                                        </g>
                                    </g>
                                </svg>
                                <span>Home</span>
                            </NavLink>
                            <NavLink to={`${routes.voice}/${routes.literature}`} end
                                     className={({isActive}) => `px-4 py-1  flex flex-col items-center justify-between w-20 relative ${isActive ? "text-bColor bg-gray-100 voice-menu" : ""} transition-all hover:bg-gray-100`}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20.159" height="28"
                                     viewBox="0 0 20.159 28" fill="currentColor">
                                    <g id="romantic-novel" transform="translate(-78.773 -525.961)">
                                        <path id="Path_398" data-name="Path 398"
                                              d="M83.728,546.184V525.961H82.38a3.62,3.62,0,0,0-3.6,3.617v17.78a4.663,4.663,0,0,1,3.1-1.175h1.855Z"/>
                                        <path id="Path_399" data-name="Path 399"
                                              d="M93.388,534.227,92.1,535.514l-1.287-1.287a.891.891,0,0,0-1.26,1.26l2.541,2.541,2.541-2.541a.891.891,0,1,0-1.26-1.26Z"/>
                                        <path id="Path_400" data-name="Path 400"
                                              d="M98.889,525.961H85.369v20.223h13.52ZM95.8,536.639l-3.7,3.7-3.7-3.7a2.528,2.528,0,0,1,3.578-3.571l.128.127.127-.127a2.525,2.525,0,0,1,3.572,3.571h0Z"/>
                                        <path id="Path_401" data-name="Path 401"
                                              d="M83.728,551.688v-1.642H97.79a4.542,4.542,0,0,1,1.139-2.275H81.872a3.1,3.1,0,1,0-.007,6.19H98.932a4.533,4.533,0,0,1-1.139-2.275Z"/>
                                    </g>
                                </svg>
                                <span>Literature</span>
                            </NavLink>
                            <NavLink to={`${routes.voice}/${routes.jokes}`} end
                                     className={({isActive}) => `px-4 py-1  flex flex-col items-center justify-between w-20 relative ${isActive ? "text-bColor bg-gray-100 voice-menu" : ""} transition-all hover:bg-gray-100`}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="27.997" height="27.995"
                                     viewBox="0 0 27.997 27.995" fill="currentColor">
                                    <g id="Group_28" data-name="Group 28" transform="translate(-15.002 -671.458)">
                                        <path id="joke"
                                              d="M33.484,692.127a1.094,1.094,0,0,1-1.094-1.093v-1.052a12.613,12.613,0,0,0,2.187-.661v1.709a1.094,1.094,0,0,1-1.092,1.1Zm-1.424-10.82a1.75,1.75,0,1,1,1.75,1.75h0a1.75,1.75,0,0,1-1.748-1.751Zm-9.624,0a1.75,1.75,0,1,1,1.751,1.749,1.75,1.75,0,0,1-1.751-1.749ZM38.9,675.56a14,14,0,1,0,0,19.79A14,14,0,0,0,38.9,675.56Zm-2.13,15.47a3.28,3.28,0,0,1-6.56,0v-.75c-.41.03-.84.04-1.29.04a12.965,12.965,0,0,1-8.06-2.22l1.4-1.68a11.326,11.326,0,0,0,6.66,1.72c4.23,0,6.16-1.53,6.33-1.68l1.47,1.57h.05Z"/>
                                    </g>
                                </svg>
                                <span>Jokes</span>
                            </NavLink>
                            <NavLink to={`${routes.voice}/${routes.opinion}`} end
                                     className={({isActive}) => `px-4 py-1  flex flex-col items-center justify-between w-20 relative ${isActive ? "text-bColor bg-gray-100 voice-menu" : ""} transition-all hover:bg-gray-100`}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 29.511"
                                     fill="currentColor">
                                    <g id="Group_24" data-name="Group 24" transform="translate(-169.533 -658.261)">
                                        <g id="Group_315" data-name="Group 315">
                                            <path id="Path_422" data-name="Path 422"
                                                  d="M189.513,687.772H177.587c0-1.112-.045-2.2.028-3.271a1.425,1.425,0,0,1,.591-.895,9.1,9.1,0,0,1,10.427-.207,1.754,1.754,0,0,1,.891,1.7c-.046.876-.011,1.758-.011,2.675"/>
                                            <path id="Path_423" data-name="Path 423"
                                                  d="M169.554,685.467c0-1.074-.064-2.112.033-3.136a1.733,1.733,0,0,1,.7-1.1,9.022,9.022,0,0,1,9.554-.646,4.48,4.48,0,0,1,.537.369c-.615.273-1.177.5-1.707.775a9.452,9.452,0,0,0-1.423.887,2.75,2.75,0,0,0-1.06,2.222v.633Z"/>
                                            <path id="Path_424" data-name="Path 424"
                                                  d="M190.934,685.456a2.856,2.856,0,0,1-.033-.37,2.934,2.934,0,0,0-1.553-2.837c-.82-.483-1.691-.9-2.617-1.379.547-.255,1.082-.547,1.641-.752a8.9,8.9,0,0,1,8.476,1.11,1.5,1.5,0,0,1,.683,1.342c-.008.949,0,1.9,0,2.885Z"/>
                                            <path id="Path_425" data-name="Path 425"
                                                  d="M183.516,670.232c-.8,0-1.6.032-2.389-.019a1.065,1.065,0,0,1-.686-.417c-.546-.757-1-1.577-1.556-2.326a5.775,5.775,0,1,1,9.084.257,18.336,18.336,0,0,0-1.316,2.056.752.752,0,0,1-.749.456c-.8-.021-1.593,0-2.39,0m1.676-1.351a.951.951,0,0,0,.594-.354c.418-.554.757-1.17,1.187-1.714a4.393,4.393,0,0,0,.925-3.75,4.462,4.462,0,0,0-8.8,1.42,10.193,10.193,0,0,0,1.266,2.938,4.617,4.617,0,0,0,1.077,1.321,1.1,1.1,0,0,0,.6.157Z"/>
                                            <g id="Group_25" data-name="Group 25">
                                                <path id="Path_2" data-name="Path 2"
                                                      d="M185.752,673.033h-4.48a.728.728,0,0,1,0-1.452h4.48a.728.728,0,0,1,0,1.452Z"/>
                                            </g>
                                            <path id="Path_426" data-name="Path 426"
                                                  d="M183.546,675.028a2.99,2.99,0,1,1-.035,0h.035"/>
                                            <path id="Path_427" data-name="Path 427"
                                                  d="M194.511,675.668a2.98,2.98,0,1,1-2.98-2.98h.01a2.974,2.974,0,0,1,2.97,2.979h0"/>
                                            <path id="Path_428" data-name="Path 428"
                                                  d="M172.558,675.688a2.969,2.969,0,1,1,0,.021v-.021"/>
                                            <path id="Path_431" data-name="Path 431"
                                                  d="M185.931,663.065l-2.328,4.29-.115-.02v-2.387l-2.365.511,2.211-4.311.122.026v2.368l2.475-.482"/>
                                        </g>
                                    </g>
                                </svg>
                                <span>Opinion</span>
                            </NavLink>
                            <NavLink to={`${routes.voice}/${routes.politics}`} end
                                     className={({isActive}) => `px-4 py-1  flex flex-col items-center justify-between w-20 relative ${isActive ? "text-bColor bg-gray-100 voice-menu" : ""} transition-all hover:bg-gray-100`}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24.606" height="27.927"
                                     viewBox="0 0 24.606 27.927" fill="currentColor">
                                    <g id="Group_297" data-name="Group 297" transform="translate(-214.724 -525.998)">
                                        <path id="Path_402" data-name="Path 402"
                                              d="M216.363,550.67v1.622h-1.628v1.633H239.3v-1.65h-1.65V550.67Z"/>
                                        <g id="Group_296" data-name="Group 296">
                                            <g id="Group_3" data-name="Group 3">
                                                <g id="Group_295" data-name="Group 295">
                                                    <path id="Path_403" data-name="Path 403"
                                                          d="M214.735,537.508h24.6a16.627,16.627,0,0,1-.124,2.1,1.536,1.536,0,0,1-1.593,1.158h-21.04a1.661,1.661,0,0,1-1.838-1.462,1.687,1.687,0,0,1,0-.376Z"/>
                                                    <path id="Path_404" data-name="Path 404"
                                                          d="M232.736,526v4.94h-4.9a10.56,10.56,0,0,1-.011,1.343c-.063.494.152.7.57.9,1.726.832,3.436,1.7,5.146,2.561.04.019.079.042.165.091H220.33c.121-.064.2-.106.275-.148l5.361-2.676a.351.351,0,0,0,.23-.37c-.009-2.1,0-4.2,0-6.3V526Z"/>
                                                    <rect id="Rectangle_447" data-name="Rectangle 447" width="3.246"
                                                          height="6.578" transform="translate(218.027 542.425)"/>
                                                    <rect id="Rectangle_448" data-name="Rectangle 448" width="3.244"
                                                          height="6.572" transform="translate(222.94 542.421)"/>
                                                    <rect id="Rectangle_449" data-name="Rectangle 449" width="3.243"
                                                          height="6.572" transform="translate(227.853 542.419)"/>
                                                    <rect id="Rectangle_450" data-name="Rectangle 450" width="3.243"
                                                          height="6.572" transform="translate(232.762 542.43)"/>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                                <span>Politics</span>
                            </NavLink>
                            <NavLink to={`${routes.voice}/${routes.status}`} end
                                     className={({isActive}) => `px-4 py-1  flex flex-col items-center justify-between w-20 relative ${isActive ? "text-bColor bg-gray-100 voice-menu" : ""} transition-all hover:bg-gray-100`}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="28.004" height="28"
                                     viewBox="0 0 28.004 28" fill="currentColor">
                                    <g id="Group_4" data-name="Group 4" transform="translate(-260.763 -525.961)">
                                        <g id="Group_299" data-name="Group 299">
                                            <g id="Group_298" data-name="Group 298">
                                                <path id="Path_405" data-name="Path 405"
                                                      d="M278.024,535.806a4.061,4.061,0,0,1-6.522,0,4.052,4.052,0,0,0-.84,2.461v1.641a.821.821,0,0,0,.82.821h6.564a.821.821,0,0,0,.82-.821v-1.641A4.06,4.06,0,0,0,278.024,535.806Z"/>
                                            </g>
                                        </g>
                                        <g id="Group_301" data-name="Group 301">
                                            <g id="Group_300" data-name="Group 300">
                                                <path id="Path_406" data-name="Path 406"
                                                      d="M274.764,530.884a2.462,2.462,0,1,0,2.462,2.462h0A2.466,2.466,0,0,0,274.764,530.884Z"/>
                                            </g>
                                        </g>
                                        <g id="Group_303" data-name="Group 303">
                                            <g id="Group_302" data-name="Group 302">
                                                <path id="Path_407" data-name="Path 407"
                                                      d="M287.924,530.884a4.06,4.06,0,0,1-6.522,0,4.067,4.067,0,0,0-.84,2.462v1.641a.82.82,0,0,0,.821.82h6.563a.82.82,0,0,0,.821-.82v-1.641A4.068,4.068,0,0,0,287.924,530.884Z"/>
                                            </g>
                                        </g>
                                        <g id="Group_305" data-name="Group 305">
                                            <g id="Group_304" data-name="Group 304">
                                                <path id="Path_408" data-name="Path 408"
                                                      d="M284.663,525.961a2.461,2.461,0,1,0,2.461,2.462h0A2.464,2.464,0,0,0,284.663,525.961Z"/>
                                            </g>
                                        </g>
                                        <g id="Group_307" data-name="Group 307">
                                            <g id="Group_306" data-name="Group 306">
                                                <path id="Path_409" data-name="Path 409"
                                                      d="M268.125,540.729a4.061,4.061,0,0,1-6.522,0,4.052,4.052,0,0,0-.84,2.461v1.641a.821.821,0,0,0,.82.821h6.563a.821.821,0,0,0,.82-.821V543.19A4.065,4.065,0,0,0,268.125,540.729Z"/>
                                            </g>
                                        </g>
                                        <g id="Group_309" data-name="Group 309">
                                            <g id="Group_308" data-name="Group 308">
                                                <path id="Path_410" data-name="Path 410"
                                                      d="M264.865,535.806a2.461,2.461,0,1,0,2.462,2.461h0A2.464,2.464,0,0,0,264.865,535.806Z"/>
                                            </g>
                                        </g>
                                        <g id="Group_311" data-name="Group 311">
                                            <g id="Group_310" data-name="Group 310">
                                                <path id="Path_411" data-name="Path 411"
                                                      d="M287.945,537.447h-6.562a.82.82,0,0,0-.821.82v14.056h-1.7v-9.136a.82.82,0,0,0-.82-.82h-6.564a.82.82,0,0,0-.82.82v9.133h-1.7v-4.211a.821.821,0,0,0-.82-.821h-6.563a.821.821,0,0,0-.82.821v5.032a.82.82,0,0,0,.82.82h26.362a.82.82,0,0,0,.82-.82V538.267A.82.82,0,0,0,287.945,537.447Z"/>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                                <span>Status</span>
                            </NavLink>
                            <NavLink to={`${routes.voice}/${routes.idea}`} end
                                     className={({isActive}) => `px-4 py-1  flex flex-col items-center justify-between w-20 relative ${isActive ? "text-bColor bg-gray-100 voice-menu" : ""} transition-all hover:bg-gray-100`}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="22.517" height="27.882"
                                     viewBox="0 0 22.517 27.882" fill="currentColor">
                                    <g id="Group_5" data-name="Group 5" transform="translate(-310.859 -525.961)">
                                        <g id="Group_313" data-name="Group 313">
                                            <path id="Path_417" data-name="Path 417"
                                                  d="M317.689,542.723v.429h-.428a.427.427,0,1,1,.428-.429"/>
                                            <path id="Path_418" data-name="Path 418"
                                                  d="M320.684,543.148h-.428v-.429a.427.427,0,1,1,.428.429"/>
                                            <path id="Path_419" data-name="Path 419"
                                                  d="M318.544,549.568h.856v-5.561h-.856Zm5.911-10.266a1.282,1.282,0,1,1-2.412,0h-3.07v-2.894a1.284,1.284,0,1,1,0-1.9v-3.761a8.127,8.127,0,0,0-3.425,15.491v3.755H322.4v-3.755a8.127,8.127,0,0,0,4.7-6.937ZM319.4,549.568v-5.561h-.856v5.561h-.855v-5.561h-.426a1.284,1.284,0,1,1,1.282-1.285h0v.429h.856v-.429a1.283,1.283,0,1,1,1.283,1.284h-.428v5.561Zm-.856,0h.856v-5.561h-.856Z"/>
                                            <rect id="Rectangle_454" data-name="Rectangle 454" width="2.567"
                                                  height="0.855" transform="translate(317.689 549.568)"/>
                                            <path id="Path_420" data-name="Path 420"
                                                  d="M323.344,551.706a1.276,1.276,0,0,1-.948,2.137h-6.85a2.3,2.3,0,0,1,0-4.275h6.85a1.278,1.278,0,0,1,.948,2.138"/>
                                            <path id="Path_421" data-name="Path 421"
                                                  d="M330.85,529.3q-1.078.464-2.145.959c-.713.324-1.419.659-2.125,1s-1.388.675-2.076,1.027q.4-.978.777-1.967c.539-1.426,1.048-2.866,1.465-4.353-.909,1.247-1.724,2.54-2.51,3.846s-1.529,2.633-2.245,3.973l-1.173,2.192,2.329-1.03c.716-.317,1.43-.636,2.14-.968s1.415-.667,2.119-1.008q.949-.465,1.891-.945-.344.711-.674,1.429c-.585,1.289-1.151,2.59-1.632,3.94.927-1.1,1.769-2.235,2.591-3.388q.613-.867,1.2-1.747c.391-.588.78-1.177,1.158-1.773l1.434-2.261Z"/>
                                        </g>
                                    </g>
                                </svg>
                                <span>Idea</span>
                            </NavLink>
                        </nav>
                        <button onClick={(!isLoading && data?.user) ? handleShowCreatePost : handleShowLoginPopup} className="bg-gray-100 hover:bg-gray-200 rounded-full transition-colors" title="Create new post">
                            <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44">
                                <g id="Group_658" data-name="Group 658" transform="translate(-1389 -14)">
                                    <rect id="Rectangle_16" data-name="Rectangle 16" width="44" height="44" rx="22" transform="translate(1389 14)" fill="transparent"/>
                                    <g id="Group_34" data-name="Group 34" transform="translate(1783.347 -3.645)">
                                        <g id="Group_33" data-name="Group 33">
                                            <path id="Path_8" data-name="Path 8" d="M-358.346,30.128V49.157a.875.875,0,0,0-.051.152,4.976,4.976,0,0,1-4.98,4.328q-8.965.018-17.93,0a4.664,4.664,0,0,1-2.092-.451,4.9,4.9,0,0,1-2.939-4.667c-.016-5.6,0-11.206,0-16.809,0-.41-.007-.82.006-1.23a4.968,4.968,0,0,1,3.272-4.52c.39-.14.8-.215,1.206-.319h19.029a1.026,1.026,0,0,0,.151.05,4.92,4.92,0,0,1,4.053,3.332A10.189,10.189,0,0,1-358.346,30.128Zm-25.995,9.5q0,4.387,0,8.775a4.211,4.211,0,0,0,.048.762,2.9,2.9,0,0,0,2.836,2.456c6.078.023,12.156.01,18.233.007a2.153,2.153,0,0,0,.408-.039,2.994,2.994,0,0,0,2.465-3.117q0-8.829,0-17.66a3.823,3.823,0,0,0-.057-.733,2.889,2.889,0,0,0-2.813-2.416q-9.131-.033-18.261,0a2.685,2.685,0,0,0-1.1.262,2.916,2.916,0,0,0-1.756,2.843Z" fill="#616770"/>
                                            <path id="Path_9" data-name="Path 9" d="M-373.34,38.648v-.359c0-1.212-.008-2.424.005-3.636a.985.985,0,0,1,1.27-.966.993.993,0,0,1,.713,1.014c.005,1.2,0,2.406,0,3.609v.338h.339c1.212,0,2.424-.007,3.636,0a.986.986,0,0,1,.99,1.252.991.991,0,0,1-1.025.733c-1.2,0-2.406,0-3.609,0h-.331v.318c0,1.221.007,2.442,0,3.663a.985.985,0,0,1-1.257.983,1,1,0,0,1-.727-1.031c0-1.2,0-2.406,0-3.609v-.324h-.352c-1.212,0-2.424.007-3.636,0a.987.987,0,0,1-.974-1.265.991.991,0,0,1,1.009-.719c1.2-.005,2.406,0,3.608,0Z" fill="#616770"/>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </button>
                    </div>
                </div>
                <div className="justify-self-end ml-4">
                    <UserMenu/>
                </div>
            </div>
        </header>
    );
}