import ShopSecondaryHeader from "../../components/shop/ShopSecondaryHeader";
import {Link} from "react-router-dom";
import routes from "../../constants/routes";
import {useState} from "react";
import ProductCard from "../../components/shop/ProductCard";

export default function WishList() {

    const [quantity, setQuantity] = useState(1);

    const handleQuantityDecrease = () => {
        setQuantity(prevState => prevState - 1)
    }
    const handleQuantityIncrease = () => {
        setQuantity(prevState => prevState + 1)
    }

    return (
        <div className="max-w-screen-xl mx-auto">
            <ShopSecondaryHeader/>
            <section className="mt-10">
                <div className="space-x-4">
                    <div className="products-grid rounded-xl border border-gray-100 p-4">
                        <article className="relative">
                            <Link className="block bg-gray-100 p-3" to={`${routes.product}/some-hoodie`}>
                                <figure className="flex flex-col">
                                    <img src="/assets/images/product_image.png" alt=""/>
                                    <figcaption className="mt-2">Hoodie for Men</figcaption>
                                </figure>
                                <div className="mt-2 font-bold text-sm space-x-2">
                                    <span className="inline-block">Rs. 2500</span>
                                    <span className="inline-block text-gray-400 line-through">Rs. 2800</span>
                                    <strong className="text-bColor">10% off</strong>
                                </div>
                            </Link>
                            <div id="quantity-selector" className="flex items-center justify-center mt-4 space-x-2">
                                <span className="text-gray-500 text-sm">Quantity</span>
                                <div className="space-x-3">
                                    <button
                                        className="bg-gray-200 px-3 py-2 inline-block leading-none disabled:opacity-50"
                                        disabled={quantity <= 1} onClick={handleQuantityDecrease}>-
                                    </button>
                                    <span>{quantity}</span>
                                    <button className="bg-gray-200 px-3 py-2 inline-block leading-none"
                                            onClick={handleQuantityIncrease}>+
                                    </button>
                                </div>
                            </div>
                            <div className="flex mt-4 text-bColor">
                                <button className="flex-grow rounded-xl py-2 shadow-custom mr-2">Add to Cart</button>
                                <button className="rounded-xl p-2 shadow-custom">
                                    <img className="w-6" src="/assets/images/ICON_SHARE.svg" alt=""/>
                                </button>
                            </div>
                            <p className="absolute top-4 right-4 cursor-pointer">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none"
                                     viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1}
                                          d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"/>
                                </svg>
                            </p>
                        </article>
                        <article className="relative">
                            <Link className="block bg-gray-100 p-3" to={`${routes.product}/some-hoodie`}>
                                <figure className="flex flex-col">
                                    <img src="/assets/images/product_image.png" alt=""/>
                                    <figcaption className="mt-2">Hoodie for Men</figcaption>
                                </figure>
                                <div className="mt-2 font-bold text-sm space-x-2">
                                    <span className="inline-block">Rs. 2500</span>
                                    <span className="inline-block text-gray-400 line-through">Rs. 2800</span>
                                    <strong className="text-bColor">10% off</strong>
                                </div>
                            </Link>
                            <div id="quantity-selector" className="flex items-center justify-center mt-4 space-x-2">
                                <span className="text-gray-500 text-sm">Quantity</span>
                                <div className="space-x-3">
                                    <button
                                        className="bg-gray-200 px-3 py-2 inline-block leading-none disabled:opacity-50"
                                        disabled={quantity <= 1} onClick={handleQuantityDecrease}>-
                                    </button>
                                    <span>{quantity}</span>
                                    <button className="bg-gray-200 px-3 py-2 inline-block leading-none"
                                            onClick={handleQuantityIncrease}>+
                                    </button>
                                </div>
                            </div>
                            <div className="flex mt-4 text-bColor">
                                <button className="flex-grow rounded-xl py-2 shadow-custom mr-2">Add to Cart</button>
                                <button className="rounded-xl p-2 shadow-custom">
                                    <img className="w-6" src="/assets/images/ICON_SHARE.svg" alt=""/>
                                </button>
                            </div>
                            <p className="absolute top-4 right-4 cursor-pointer">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none"
                                     viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1}
                                          d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"/>
                                </svg>
                            </p>
                        </article>
                        <article className="relative">
                            <Link className="block bg-gray-100 p-3" to={`${routes.product}/some-hoodie`}>
                                <figure className="flex flex-col">
                                    <img src="/assets/images/product_image.png" alt=""/>
                                    <figcaption className="mt-2">Hoodie for Men</figcaption>
                                </figure>
                                <div className="mt-2 font-bold text-sm space-x-2">
                                    <span className="inline-block">Rs. 2500</span>
                                    <span className="inline-block text-gray-400 line-through">Rs. 2800</span>
                                    <strong className="text-bColor">10% off</strong>
                                </div>
                            </Link>
                            <div id="quantity-selector" className="flex items-center justify-center mt-4 space-x-2">
                                <span className="text-gray-500 text-sm">Quantity</span>
                                <div className="space-x-3">
                                    <button
                                        className="bg-gray-200 px-3 py-2 inline-block leading-none disabled:opacity-50"
                                        disabled={quantity <= 1} onClick={handleQuantityDecrease}>-
                                    </button>
                                    <span>{quantity}</span>
                                    <button className="bg-gray-200 px-3 py-2 inline-block leading-none"
                                            onClick={handleQuantityIncrease}>+
                                    </button>
                                </div>
                            </div>
                            <div className="flex mt-4 text-bColor">
                                <button className="flex-grow rounded-xl py-2 shadow-custom mr-2">Add to Cart</button>
                                <button className="rounded-xl p-2 shadow-custom">
                                    <img className="w-6" src="/assets/images/ICON_SHARE.svg" alt=""/>
                                </button>
                            </div>
                            <p className="absolute top-4 right-4 cursor-pointer">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none"
                                     viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1}
                                          d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"/>
                                </svg>
                            </p>
                        </article>
                        <article className="relative">
                            <Link className="block bg-gray-100 p-3" to={`${routes.product}/some-hoodie`}>
                                <figure className="flex flex-col">
                                    <img src="/assets/images/product_image.png" alt=""/>
                                    <figcaption className="mt-2">Hoodie for Men</figcaption>
                                </figure>
                                <div className="mt-2 font-bold text-sm space-x-2">
                                    <span className="inline-block">Rs. 2500</span>
                                    <span className="inline-block text-gray-400 line-through">Rs. 2800</span>
                                    <strong className="text-bColor">10% off</strong>
                                </div>
                            </Link>
                            <div id="quantity-selector" className="flex items-center justify-center mt-4 space-x-2">
                                <span className="text-gray-500 text-sm">Quantity</span>
                                <div className="space-x-3">
                                    <button
                                        className="bg-gray-200 px-3 py-2 inline-block leading-none disabled:opacity-50"
                                        disabled={quantity <= 1} onClick={handleQuantityDecrease}>-
                                    </button>
                                    <span>{quantity}</span>
                                    <button className="bg-gray-200 px-3 py-2 inline-block leading-none"
                                            onClick={handleQuantityIncrease}>+
                                    </button>
                                </div>
                            </div>
                            <div className="flex mt-4 text-bColor">
                                <button className="flex-grow rounded-xl py-2 shadow-custom mr-2">Add to Cart</button>
                                <button className="rounded-xl p-2 shadow-custom">
                                    <img className="w-6" src="/assets/images/ICON_SHARE.svg" alt=""/>
                                </button>
                            </div>
                            <p className="absolute top-4 right-4 cursor-pointer">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none"
                                     viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1}
                                          d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"/>
                                </svg>
                            </p>
                        </article>
                    </div>
                </div>
                <div className="flex justify-between mt-6">
                    <div>
                        <button className="bg-bColor text-white py-1 px-4 rounded-lg text-sm mr-4 shadow-custom">
                            Move all to Cart
                        </button>
                    </div>
                    <div className="self-end">
                        <button className="py-1 px-4 rounded-lg text-sm border-2 border-gray-300 shadow-custom mr-2">
                            <img className="w-5 mr-2 inline" src="/assets/images/ICON_SHARE.svg" alt=""/>Share
                        </button>
                        <button className="py-1 px-4 rounded-lg text-sm border-2 border-gray-300 shadow-custom">
                            Clear List
                        </button>
                    </div>
                </div>
            </section>
            <section className="mt-16">
                <div className="border-t border-gray-200 mt-8">
                    <div className="flex justify-between my-4 text-xl">
                        <p>Similar products for you</p>
                    </div>
                    <div className="products-grid">
                        <ProductCard/>
                        <ProductCard/>
                        <ProductCard/>
                        <ProductCard/>
                        <ProductCard/>
                    </div>
                </div>
                <div className="border-t border-gray-200 mt-8">
                    <div className="flex justify-between my-4 text-xl">
                        <p>Your Searching History</p>
                    </div>
                    <div className="products-grid">
                        <ProductCard/>
                        <ProductCard/>
                        <ProductCard/>
                        <ProductCard/>
                        <ProductCard/>
                    </div>
                </div>
            </section>
        </div>
    )
}