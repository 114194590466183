export default function BreadCrumbs() {
    return (
        <div className="flex  breadcrumb border-b border-gray-200 pb-4">
            <a className="text-bColor inline-block" href="/">Home</a>
            <a className="text-bColor inline-block" href="/">Fashion</a>
            <a className="text-bColor inline-block" href="/">Men</a>
            <a className="text-bColor inline-block" href="/">Clothing</a>
            <span className="text-gray-500">Hoodies</span>
        </div>
    )
}