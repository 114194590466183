import {Link} from "react-router-dom";
import {Helmet} from "react-helmet-async";
import React from "react";

export default function FourOFour() {
    return (
        <>
            <Helmet>
                <title>Oops, page not found.</title>
            </Helmet>
            <div className="min-h-screen flex justify-center items-center flex-col space-y-14">
                <div
                    className="relative bg-gray-200 rounded-full flex items-center justify-center flex-col p-16 h-64 w-64 text-center relative">
                    <strong className="font-bold text-4xl">Oops!</strong>
                    <div className="mt-3 font-light">
                        <span className="block text-3xl font-light">Error 404</span>
                        <span className="block font-medium">Sorry, Page Not Found</span>
                    </div>
                    <div className="absolute -bottom-4 -right-4 bg-gray-200 h-10 w-10 rounded-full"/>
                </div>
                <Link to={"/"} className="px-8 py-3 rounded-full bg-bColor-light text-bColor uppercase font-bold">Back to Home</Link>
            </div>
        </>
    )
}