import {Link} from "react-router-dom";
import routes from "../constants/routes";
import {externalLinks} from "../constants/externalLinks";

export default function JobsHeader() {
    return (
        <div className="three-column-header-grid px-4 py-4 md:px-8 shadow-custom">
            <div className="flex">
                <Link to={routes.home} className="w-14 h-10 mr-4 block">
                    <img src="/logo.svg" alt="" className="w-14 h-10 block"/>
                </Link>
                <input type="text" name="search" placeholder="search colleges/schools"
                       className="rounded-full px-4 py-2 bg-gray-200"/>
            </div>
            <div className="flex justify-between">
                <div className="space-x-4 font-bold">
                    <Link to={"#"} className="text-bColor py-1 inline-block px-4 bg-purple-100 rounded-md">Recent Jobs</Link>
                    <Link to={"#"} className="px-4 py-1 inline-block">New Jobs</Link>
                    <Link to={"#"} className="px-4 py-1 inline-block">Old Jobs</Link>
                </div>
                <div>
                    <span className="mr-4 text-sm">Get Our App</span>
                    <a href={externalLinks.appGoolge} title="Get our app from Playstore"><img src="/assets/images/playstore.svg" className="inline" alt=""/></a>
                    <span className="mx-2">|</span>
                    <a href={externalLinks.appApple} title="Get our app from Appstore"> <img src="/assets/images/appstore.svg" className="inline" alt=""/></a>
                </div>
            </div>
            <div className="justify-self-end">
                <span className="mr-4 text-bColor">Nirajan Rawal</span>
                <img src="/assets/images/user.png" alt="NR"
                     className="inline w-8 rounded-full border border-gray-200 align-middle"/>
            </div>
        </div>
    )
}