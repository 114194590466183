import {useParams} from "react-router-dom";
import {useGetOrgBySlugQuery} from "../services/directoryApi";
import UnClaimed from "../pages/UnClaimed";
import PageTransitionLoader from "./PageTransitionLoader";
import PersonalRoot from "../pages/personal/PersonalRoot";
import GenericOrganizationLayout from "../pages/GenericOrganizationLayout";
import FourOFour from "./FourOFour";
import TouristAreaLayout from "../pages/TouristAreaLayout";

export default function OrgConditionalRoute() {

    const {slug} = useParams();
    const {data, isFetching} = useGetOrgBySlugQuery(slug);

    if (isFetching) {
        return <div className="flex justify-center items-center flex-col h-screen bg-bColor-50">
            <PageTransitionLoader size={28}/>
            <span className="text-sm">LOADING</span>
        </div>
    }

    if (!isFetching && data) {
        if (data?.organization?.subscription === 0) {
            return <UnClaimed data={data.organization}/>
        }


        // if (data.organization?.type === 0) {
        //     return <GenericOrganizationLayout data={data.organization}/>
        // } else if (data.organization?.type === 1) {
        //     return <PersonalRoot data={data.organization}/>
        // } else {
        //     return <div>{data.organization?.name}</div>
        // }
        switch (data.organization?.group) {
            case "tourist-area":
                return <TouristAreaLayout data={data.organization}/>
            default:
                return <GenericOrganizationLayout data={data.organization}/>
        }
    }

    if (!isFetching && !data) return <FourOFour/>
}