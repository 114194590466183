import JobsHeader from "../components/JobsHeader";
import routes from "../constants/routes";
import {Link} from "react-router-dom";
import SliderWithAds from "../components/SliderWithAds";
import MainFooter from "../components/MainFooter";

export default function Jobs() {
    return (
        <>
            <div className="flex-grow">
                <JobsHeader/>
                <SliderWithAds/>
                <main className="three-column-grid mx-4 md:mx-8">
                    {/*left sidebar*/}
                    <section id="left-sidebar" className="py-1 space-y-16 hidden xl:block">
                        <div>
                            <div className="bg-gray-200 rounded-md py-2 px-4 w-full max-w-xs">
                                <h2 className="font-bold text-xl">Today's Jobs</h2>
                            </div>
                            <ul className="font-medium space-y-6 mt-6">
                                <li>
                                    <a href="/">
                                        <img src="/assets/images/small_logo.png" alt=""
                                             className="w-8 rounded-full bg-gray-400 mr-2 inline"/>
                                        <span>Graphics Designer</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="/">
                                        <img src="/assets/images/small_logo.png" alt=""
                                             className="w-8 rounded-full bg-gray-400 mr-2 inline"/>
                                        <span>React Developer</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="/">
                                        <img src="/assets/images/small_logo.png" alt=""
                                             className="w-8 rounded-full bg-gray-400 mr-2 inline"/>
                                        <span>Product Designer</span>
                                    </a>
                                </li>
                            </ul>
                            <span className="mt-10 block">
                                <a href="/">
                                    <img src="/assets/images/show_more.png" alt=""
                                         className="w-8 rounded-full mr-2 inline"/>
                                    <span className="font-medium ">See More</span>
                                </a>
                            </span>
                        </div>
                        <div>
                            <div className="bg-gray-200 rounded-md py-2 px-4 w-full max-w-xs">
                                <h2 className="font-bold text-xl">Top Jobs</h2>
                            </div>
                            <ul className="font-medium space-y-6 mt-6">
                                <li>
                                    <a href="/">
                                        <img src="/assets/images/small_logo.png" alt=""
                                             className="w-8 rounded-full bg-gray-400 mr-2 inline"/>
                                        <span>Python Developer</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="/">
                                        <img src="/assets/images/small_logo.png" alt=""
                                             className="w-8 rounded-full bg-gray-400 mr-2 inline"/>
                                        <span>Core Rust Developer</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="/">
                                        <img src="/assets/images/small_logo.png" alt=""
                                             className="w-8 rounded-full bg-gray-400 mr-2 inline"/>
                                        <span>R. Manager</span>
                                    </a>
                                </li>
                            </ul>
                            <span className="mt-10 block">
                                <a href="/">
                                    <img src="/assets/images/show_more.png" alt=""
                                         className="w-8 rounded-full mr-2 inline"/>
                                    <span className="font-medium ">See More</span>
                                </a>
                            </span>
                        </div>
                    </section>

                    {/*main content*/}
                    <section id="content" className="py-1 space-y-10 max-w-2xl m-auto xl:m-0 w-full font-medium">
                        <article className="relative">
                            <Link to={`${routes.jobs}/graphics-designer`}
                                  className="block shadow-custom rounded-md p-4 flex space-x-4 w-full">
                                <img src="/assets/images/article_thumbnail.png" alt="thumbnail"
                                     className="w-80 rounded-md inline"/>
                                <div className="flex justify-between flex-col  w-full">
                                    <div>
                                        <h3 className="text-2xl font-bold">Graphic Designer</h3>
                                        <ul className="mt-6 ml-4 space-y-1">
                                            <li><img className="w-5 mr-2 inline align-middle" alt=""
                                                     src="/assets/images/ICON_COMPANY-NAME.svg"/>Find All Nepal Pvt.
                                                Ltd.
                                            </li>
                                            <li><img className="w-5 mr-2 inline align-middle" alt=""
                                                     src="/assets/images/ICON_SALARY-ICON.svg"/>25,000-40,000
                                            </li>
                                            <li><img className="w-5 mr-2 inline align-middle" alt=""
                                                     src="/assets/images/ICON_START-DATE.svg"/>25th Jul, 2021
                                            </li>
                                            <li><img className="w-5 mr-2 inline align-middle" alt=""
                                                     src="/assets/images/ICON_END-DATE.svg"/>8th aUG, 2021
                                            </li>
                                            <li><img className="w-5 mr-2 inline align-middle" alt=""
                                                     src="/assets/images/ICON_LOCATION.svg"/>Dang
                                            </li>
                                        </ul>
                                    </div>
                                    <div className=" mb-10 border-t border-gray-100 text-right"/>
                                </div>
                            </Link>
                            <span className="font-bold mt-4 text-bColor block absolute bottom-4 right-4">
                            <img className="w-5 mr-2 inline" src="/assets/images/ICON_SHARE.svg" alt=""/>
                            Share
                        </span>
                        </article>
                        <article className="relative">
                            <Link to={`${routes.jobs}/product-manager`}
                                  className="block shadow-custom rounded-md p-4 flex space-x-4 w-full">
                                <img src="/assets/images/article_thumbnail.png" alt="thumbnail"
                                     className="w-80 rounded-md inline"/>
                                <div className="flex justify-between flex-col  w-full">
                                    <div>
                                        <h3 className="text-2xl font-bold">Product Manager</h3>
                                        <ul className="mt-6 ml-4 space-y-1">
                                            <li><img className="w-5 mr-2 inline align-middle" alt=""
                                                     src="/assets/images/ICON_COMPANY-NAME.svg"/>Find All Nepal Pvt.
                                                Ltd.
                                            </li>
                                            <li><img className="w-5 mr-2 inline align-middle" alt=""
                                                     src="/assets/images/ICON_SALARY-ICON.svg"/>25,000-40,000
                                            </li>
                                            <li><img className="w-5 mr-2 inline align-middle" alt=""
                                                     src="/assets/images/ICON_START-DATE.svg"/>25th Jul, 2021
                                            </li>
                                            <li><img className="w-5 mr-2 inline align-middle" alt=""
                                                     src="/assets/images/ICON_END-DATE.svg"/>8th aUG, 2021
                                            </li>
                                            <li><img className="w-5 mr-2 inline align-middle" alt=""
                                                     src="/assets/images/ICON_LOCATION.svg"/>Dang
                                            </li>
                                        </ul>
                                    </div>
                                    <div className=" mb-10 border-t border-gray-100 text-right"/>
                                </div>
                            </Link>
                            <span className="font-bold mt-4 text-bColor block absolute bottom-4 right-4">
                            <img className="w-5 mr-2 inline" src="/assets/images/ICON_SHARE.svg" alt=""/>
                            Share
                        </span>
                        </article>
                        <article className="relative">
                            <Link to={`${routes.jobs}/game-designer`}
                                  className="block shadow-custom rounded-md p-4 flex space-x-4 w-full">
                                <img src="/assets/images/article_thumbnail.png" alt="thumbnail"
                                     className="w-80 rounded-md inline"/>
                                <div className="flex justify-between flex-col  w-full">
                                    <div>
                                        <h3 className="text-2xl font-bold">Game Designer</h3>
                                        <ul className="mt-6 ml-4 space-y-1">
                                            <li><img className="w-5 mr-2 inline align-middle" alt=""
                                                     src="./assets/images/ICON_COMPANY-NAME.svg"/>Find All Nepal Pvt.
                                                Ltd.
                                            </li>
                                            <li><img className="w-5 mr-2 inline align-middle" alt=""
                                                     src="/assets/images/ICON_SALARY-ICON.svg"/>25,000-40,000
                                            </li>
                                            <li><img className="w-5 mr-2 inline align-middle" alt=""
                                                     src="/assets/images/ICON_START-DATE.svg"/>25th Jul, 2021
                                            </li>
                                            <li><img className="w-5 mr-2 inline align-middle" alt=""
                                                     src="/assets/images/ICON_END-DATE.svg"/>8th aUG, 2021
                                            </li>
                                            <li><img className="w-5 mr-2 inline align-middle" alt=""
                                                     src="/assets/images/ICON_LOCATION.svg"/>Dang
                                            </li>
                                        </ul>
                                    </div>
                                    <div className=" mb-10 border-t border-gray-100 text-right"/>
                                </div>
                            </Link>
                            <span className="font-bold mt-4 text-bColor block absolute bottom-4 right-4">
                            <img className="w-5 mr-2 inline" src="/assets/images/ICON_SHARE.svg" alt=""/>
                            Share
                        </span>
                        </article>
                    </section>

                    {/*right sidebar*/}
                    <section id="right-sidebar"
                             className="justify-self-end self-start py-1 hidden xl:block max-w-xs w-full space-y-16">
                        <div>
                            <div className="bg-gray-200 rounded-md py-2 px-4 w-full max-w-xs">
                                <h2 className="font-bold text-xl">Education</h2>
                            </div>
                            <div className="flex space-x-4 mt-6 font-medium">
                                <a href="/" className="w-1/2">
                                    <div
                                        className="p-4 bg-gray-200 w-full h-auto rounded-md flex justify-center items-center">
                                        <img className="w-20" src="/assets/images/aaa.png" alt="logo"/>
                                    </div>
                                    <span className="mt-2 block">Nepalaya College</span>
                                </a>
                                <a href="/" className="w-1/2">
                                    <div
                                        className="p-4 bg-gray-200 w-full h-auto rounded-md flex justify-center items-center">
                                        <img className="w-20" src="/assets/images/aaa.png" alt="logo"/>
                                    </div>
                                    <span className="mt-2 block">White House College</span>
                                </a>
                            </div>
                            <span className="mt-10 block">
                                <Link to={routes.educationAll} className="font-medium">
                                    <img src="/assets/images/show_more.png" alt=""
                                         className="w-8 rounded-full mr-2 inline"/>
                                    <span>See More</span>
                                </Link>
                            </span>
                        </div>
                        <div>
                            <div className="bg-gray-200 rounded-md py-2 px-4 w-full max-w-xs">
                                <h2 className="font-bold text-xl">Health</h2>
                            </div>
                            <div className="flex space-x-4 mt-6 font-medium">
                                <a href="/" className="w-1/2">
                                    <div
                                        className="p-4 bg-gray-200 w-full h-auto rounded-md flex justify-center items-center">
                                        <img className="w-20" src="/assets/images/aaa.png" alt="logo"/>
                                    </div>
                                    <span className="mt-2 block">Tulsipur Pharmacy</span>
                                </a>
                                <a href="/" className="w-1/2">
                                    <div
                                        className="p-4 bg-gray-200 w-full h-auto rounded-md flex justify-center items-center">
                                        <img className="w-20" src="/assets/images/aaa.png" alt="logo"/>
                                    </div>
                                    <span className="mt-2 block">Churya Hills Hospital</span>
                                </a>
                            </div>
                            <span className="mt-10 block">
                                <a href="/">
                                    <img src="/assets/images/show_more.png" alt=""
                                         className="w-8 rounded-full mr-2 inline"/>
                                    <span className="font-medium ">See More</span>
                                </a>
                            </span>
                        </div>
                    </section>
                </main>
            </div>
            <MainFooter/>
        </>
    )
}