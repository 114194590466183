import EducationTopMenu from "../../components/education/EducationTopMenu";
import EducationMainMenuWithBackground from "../../components/education/EducationMainMenuWithBackground";
import {Link} from "react-router-dom";
import EducationFooter from "../../components/education/EducationFooter";

export default function CareerDetailsPage() {
    return (
        <>
            <section id="hero">
                <EducationTopMenu/>
                <EducationMainMenuWithBackground background="/assets/images/edu_events_bg.png" height="25rem"/>
            </section>
            <main className="my-10">
                <div className="max-w-1170 mx-auto bg-gray-100 py-10 px-8">
                    <div className="flex justify-between mb-6">
                        <div>
                            <h2 className="font-bold text-4xl text-black mb-4">Networking Teacher Wanted</h2>
                            <div className="text-xs font-light"><strong
                                className="text-education mr-1">Location:</strong>
                                <span>Chyasal, Lalitpur</span>
                            </div>
                        </div>
                        <div className="text-right">
                            <button
                                className="rounded-lg text-education py-2 px-3 border border-education bg-white mb-4">
                                <img src="/assets/images/edu_share.svg" alt="" className="w-4 h-4"/>
                            </button>
                            <div className="text-xs font-light">
                                <span>Posted 9 days ago ( 15 Jul, 2021)</span>
                            </div>
                        </div>
                    </div>
                    <section id="table" className="mt-10">
                        <img src="/assets/images/post-image.png" alt=""/>
                        <div
                            className="flex justify-between rounded-lg border border-gray-600 divide-x divide-gray-600 mt-10">
                            <div className="p-8 flex-1">
                                <span className="block">Experience</span>
                                <strong>Minimum 1 Years</strong>
                            </div>
                            <div className="p-8 flex-1">
                                <span className="block">Work Level</span>
                                <strong>Senior Level</strong>
                            </div>
                            <div className="p-8 flex-1">
                                <span className="block">Employee Type</span>
                                <strong>Full Time Jobs</strong>
                            </div>
                            <div className="p-8 flex-1">
                                <span className="block">Offer Salary</span>
                                <strong>20,000/Month</strong>
                            </div>
                        </div>
                    </section>
                    <section id="content" className="mt-10">
                        <div className="bg-white p-8 space-y-8">
                            <div>
                                <h3 className="text-xl font-bold mb-4">Requirements</h3>
                                <p>
                                    Job requirements are the skills, education, experience and traits that an employer
                                    expects someone to have to be successful in a job position. Hiring managers include
                                    job requirements in the posting to decide which candidates they will contact for an
                                    interview. Prospective candidates can use job requirements to determine whether they
                                    are qualified for the job. Current employees can use job requirements to understand
                                    the scope of their roles and managers and human resources professionals can use them
                                    to outline the steps employees need to take to take to change positions or earn
                                    promotions. Job requirements are the skills, education, experience and traits that
                                    an employer expects someone to have to be successful in a job position. Hiring
                                    managers include job requirements in the posting to decide which candidates they
                                    will contact for an interview. Prospective candidates can use job requirements to
                                    determine whether they are qualified for the job. Current employees can use job
                                    requirements to understand the scope of their roles and managers and human resources
                                    professionals can use them to outline the steps employees need to take to take to
                                    change positions or earn promotions. Job requirements are the skills, education,
                                    experience and traits that an employer expects someone to have to be successful in a
                                    job position. Hiring managers include job requirements in the posting to decide
                                    which candidates they will contact for an interview. Prospective candidates can use
                                    job requirements to determine whether they are qualified for the job. Current
                                    employees can use job requirements to understand the scope of their roles and
                                    managers and human resources professionals can use them to outline the steps
                                    employees need to take to take to change positions or earn promotions.
                                </p>
                            </div>
                            <div>
                                <h3 className="text-xl font-bold mb-4">Job Specification</h3>
                                <p>
                                    Job requirements are the skills, education, experience and traits that an employer
                                    expects someone to have to be successful in a job position. Hiring managers include
                                    job requirements in the posting to decide which candidates they will contact for an
                                    interview. Prospective candidates can use job requirements to determine whether they
                                    are qualified for the job. Current employees can use job requirements to understand
                                    the scope of their roles and managers and human resources professionals can use them
                                    to outline the steps employees need to take to take to change positions or earn
                                    promotions. Job requirements are the skills, education, experience and traits that
                                    an employer expects someone to have to be successful in a job position. Hiring
                                </p>
                            </div>
                            <div className="flex justify-between items-end">
                                <Link to="#" className="bg-education px-10 py-2 text-white rounded-md text-sm">Apply Now</Link>
                                <div className="text-xs font-light"><strong
                                    className="text-education mr-1">Appy Before:</strong>
                                    <span>18 Jul, 2021</span>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </main>
            <EducationFooter/>
        </>
    )
}