export default function EducationTopMenu() {
    return (
        <section id="top-menu" className="bg-education text-white p-2">
            <div className="max-w-1170 mx-auto flex justify-between text-sm">
                <div className="flex space-x-6">
                    <div className="flex items-center">
                        <img src="/assets/images/phone.svg" alt="" width={16} className="inline-block mr-2"/>
                        <span style={{lineHeight: "normal"}}>+977-9855070290, 01-144355489</span>
                    </div>
                    <div className="flex items-center">
                        <img src="/assets/images/mail.svg" alt="" width={16} className="inline-block mr-2"/>
                        <span style={{lineHeight: "normal"}}>info@himalayacollege.edu.np</span>
                    </div>
                </div>
                <div className="flex items-center ">
                    <span style={{lineHeight: "normal",marginRight: "1rem"}}>Follow us:</span>
                    <a href="/"><img src="/assets/images/instagram.svg" alt="" width={16}
                                     className="inline-block mr-2"/></a>
                    <a href="/"><img src="/assets/images/facebook.svg" alt="" width={16}
                                     className="inline-block mr-2"/></a>
                    <a href="/"><img src="/assets/images/linkedin.svg" alt="" width={16}
                                     className="inline-block mr-2"/></a>
                    <a href="/"><img src="/assets/images/twitter.svg" alt="" width={16}
                                     className="inline-block mr-2"/></a>
                    <a href="/"><img src="/assets/images/youtube.svg" alt="" width={20}
                                     className="inline-block mr-2"/></a>
                </div>
            </div>
        </section>
    )
}