import GenericOrgProductTab from "../components/GenericOrgProductTab";

export default function GenericOrganizationProducts() {
    return (
        <main>
            <section id="hero">
                <div className="bg-gray-400 relative">
                    <img src="https://picsum.photos/1000/150?grayscale" alt="" width={1000} height={150}
                         className="w-full filter brightness-50"/>
                    <h1 className="text-center text-4xl font-bold text-white absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2">Our
                        Products</h1>
                </div>
            </section>
            <section id="products">
                <div className="mx-auto max-w-1000 my-14">
                    <GenericOrgProductTab/>
                </div>
            </section>
        </main>
    )
}