import {Dialog, Transition} from "@headlessui/react";
import React, {Fragment} from "react";
import {postTypes} from "../pages/voice/VoiceLayout";
import {useSelector} from "react-redux";

export default function PleaseLoginPopup({showLoginPopup, handleShowLoginPopup}) {

    return (
        <Transition appear show={showLoginPopup} as={Fragment}>
            <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={handleShowLoginPopup}>
                <div className="min-h-screen px-4">
                    <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0"
                                      enterTo="opacity-100" leave="ease-in duration-200"
                                      leaveFrom="opacity-100" leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-black opacity-60"/>
                    </Transition.Child>
                    {/* This element is to trick the browser into centering the modal contents. */}
                    {/*<span className="inline-block h-screen align-middle" aria-hidden="true">*/}
                    {/*    &#8203;*/}
                    {/*</span>*/}
                    <Transition.Child as={Fragment} enter="ease-out duration-300"
                                      enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100"
                                      leave="ease-in duration-200" leaveFrom="opacity-100 scale-100"
                                      leaveTo="opacity-0 scale-95"
                    >
                        <div className="w-500 mx-auto max-w-1170 overflow-hidden transform">
                            <div className="bg-white mt-20 relative rounded-md">
                                <h2 className="flex items-center px-4 py-3 border-b border-b-gray-200 font-bold text-gray-800">
                                    <img src="/assets/info.svg" alt="" className="inline-block w-5 h-5 mr-2"/>
                                    <span>Please login !</span>
                                </h2>
                                <div className="flex flex-col space-y-8 items-center w-full justify-center px-4 py-8">
                                    <span>You need to be logged in to perform this action.</span>
                                    <div className="flex justify-center items-center gap-4">
                                        <a className="bg-bColor text-white py-2 px-8 rounded-full block hover:opacity-90 border-2 border-bColor w-28 text-center"
                                           href={`//account.${process.env.REACT_APP_BASE_DOMAIN}/signin?goto=${window.location.href}`}
                                        > Login
                                        </a>
                                        <span className="text-gray-500">- OR -</span>
                                        <a className="border-2 border-bColor text-bColor py-2 px-8 rounded-full block font-bold hover:opacity-90 w-28 text-center"
                                           href={`//account.${process.env.REACT_APP_BASE_DOMAIN}/signin?goto=${window.location.href}`}
                                        > Signup
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    )
        ;
}

