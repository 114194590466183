import {Link, Outlet} from "react-router-dom";
import React from "react";
import NewsNewHeader from "../components/NewsNewHeader";
import MainFooter from "../components/MainFooter";
import {useGetRecentNewsListQuery} from "../services/newsApi";
import ContentLoader from "react-content-loader";
import {doToggleLoginPopup} from "../store/voiceSlice";
import {useDispatch, useSelector} from "react-redux";
import PleaseLoginPopup from "../components/PleaseLoginPopup";

export default function NewsNewLayout() {

    const dispatch = useDispatch();
    const {showLoginPopup} = useSelector(state => state.voice);

    const handleShowLoginPopup = () => {
        dispatch(doToggleLoginPopup(!showLoginPopup))
        // setShowLoginPopup(prevState => !prevState)
    }


    return (
        <>
            <div className="bg-gray-100 relative flex-grow">
                <div className="bg-bColor">
                    <NewsNewHeader/>
                </div>
                <div className="relative">
                    <div className="max-w-1170 mx-auto">
                        <div className="flex space-x-10 items-start">
                            <Outlet/>
                            <aside className="max-w-200 w-full">
                                <div className="flex items-center">
                                    <h2 className="uppercase text-2xl font-light mr-3 my-6">Recent News</h2>
                                    <div className="h-1.5 border-t-3 border-b border-gray-200 flex-grow"/>
                                </div>
                                <div className="space-y-2">
                                    <RecentNews/>
                                </div>
                            </aside>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-gray-100">
                <MainFooter/>
            </div>
            <PleaseLoginPopup showLoginPopup={showLoginPopup} handleShowLoginPopup={handleShowLoginPopup}/>
        </>
    )
}

function RecentNews() {

    const {data, isFetching} = useGetRecentNewsListQuery(null);

    if (isFetching) {
        return (
            <div className="bg-white p-1">
                <ContentLoader speed={2} width={200} height={750} viewBox="0 0 200 750" backgroundColor="#f3f3f3"
                               foregroundColor="#ecebeb"
                >
                    <rect x="5" y="10" rx="5" ry="5" width="180" height="85"/>
                    <rect x="5" y="114" rx="3" ry="3" width="170" height="6"/>
                    <rect x="5" y="130" rx="3" ry="3" width="180" height="6"/>
                    <rect x="5" y="146" rx="3" ry="3" width="160" height="6"/>

                    <rect x="5" y="190" rx="5" ry="5" width="180" height="85"/>
                    <rect x="5" y="294" rx="3" ry="3" width="170" height="6"/>
                    <rect x="5" y="310" rx="3" ry="3" width="180" height="6"/>
                    <rect x="5" y="326" rx="3" ry="3" width="160" height="6"/>

                    <rect x="5" y="370" rx="5" ry="5" width="180" height="85"/>
                    <rect x="5" y="474" rx="3" ry="3" width="170" height="6"/>
                    <rect x="5" y="490" rx="3" ry="3" width="180" height="6"/>
                    <rect x="5" y="506" rx="3" ry="3" width="160" height="6"/>

                    <rect x="5" y="550" rx="5" ry="5" width="180" height="85"/>
                    <rect x="5" y="654" rx="3" ry="3" width="170" height="6"/>
                    <rect x="5" y="670" rx="3" ry="3" width="180" height="6"/>
                    <rect x="5" y="686" rx="3" ry="3" width="160" height="6"/>
                </ContentLoader>
            </div>
        )
    }

    return (
        <div>
            {!isFetching && data?.news?.filter((item, i) => i < 6).map(current => {
                return (
                    <Link className="block" to={`/${current.slug}`} key={current.slug}>
                        <article className="bg-white p-2.5 space-y-3">
                            <img src={`${current.featuredImage.path}`} alt="" width="180" height="150"
                                 className="pointer-events-none" loading="lazy"/>
                            <div className="p-2 space-y-2.5">
                                <h3 className="text-base" title={current.title}>{current.title.slice(0, 40)}...</h3>
                                <p className="text-xs">{current.summary}</p>
                                <div className="flex text-gray-600">
                                    <span className="mr-1 underline font-light text-sm">Read More</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none"
                                         viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                              d="M13 7l5 5m0 0l-5 5m5-5H6"/>
                                    </svg>
                                </div>
                            </div>
                        </article>
                    </Link>
                )
            })}
        </div>
    )
}