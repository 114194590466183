import routes from "../../constants/routes";
import {Link} from "react-router-dom";

export default function ShopSecondaryHeader() {
    return (
        <div className="flex my-8">
            <Link to={routes.shop}> <img src="/logo.svg" alt="find all logo" className="w-16"/></Link>
            <div className="flex mx-10 items-center justify-center flex-grow bg-gray-200 px-4 rounded-2xl">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6  text-bColor mr-3" fill="none"
                     viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                          d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"/>
                </svg>
                <input type="text" className="bg-gray-200 flex-grow h-full outline-none"
                       placeholder="Search products & services"/>
            </div>
            <div className="self-center relative">
                <Link to={routes.cart}> <img src="/assets/images/cart.svg" className="w-11" alt=""/>
                    <div
                        className="rounded-full h-6 w-6 bg-gray-200 p-1 flex justify-center items-center absolute -top-1 left-3.5">
                        <span className="text-xs">3</span>
                    </div>
                </Link>
            </div>
        </div>
    )
}