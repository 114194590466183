import {Helmet} from "react-helmet-async";

export default function GenericOrganizationJobs({data}) {
    return (
        <>
            <Helmet>
                <meta property="og:title" content={`Jobs | ${data.name}`}/>
                <meta property="og:url" content={`https://findallnepal.com/${data.slug}/jobs`}/>
                <link rel="canonical" href={`https://findallnepal.com/${data.slug}/jobs`}/>
                <title>{`Jobs | ${data.name}`}</title>
            </Helmet>
            <main>
                <section id="hero">
                    <div className="bg-gray-400 w-full h-64 relative">
                        <div className="bg-transparent w-full h-64 bg-center bg-no-repeat filter brightness-50"
                             style={{backgroundImage: `url(${data.featuredImage?.path})` || ""}}/>
                        <h1 className="text-center text-4xl font-bold text-white absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2">
                            Jobs
                        </h1>
                    </div>
                </section>
                <section id="Jobs">
                    <div className="mx-auto max-w-1000 mb-16">
                        <h1 className="text-center text-4xl font-bold my-16">
                            All Jobs
                        </h1>
                        <div className="grid grid-cols-2 gap-16 text-center w-full">
                            <div>No job listings.</div>
                            {/*<article className="grid grid-rows-1 gap-6 justify-items-start">*/}
                            {/*    <div className="flex space-x-10 items-start">*/}
                            {/*        <img src="https://picsum.photos/130/130" alt="" width={130} height={130}*/}
                            {/*             className="rounded-2xl"/>*/}
                            {/*        <div className="">*/}
                            {/*            <h3 className="font-bold text-2xl mb-5">Advertising and marketing</h3>*/}
                            {/*            <div className="space-y-3 text-gray-500">*/}
                            {/*                <p className="flex space-x-4 items-start"><img src="/assets/images/event_org.png" alt="" width={17}/><span>Find All Nepal Pvt. Ltd</span></p>*/}
                            {/*                <p className="flex space-x-4 items-start"><img src="/assets/images/event_start.png" alt="" width={17}/><span>Full Time</span></p>*/}
                            {/*                <p className="flex space-x-4 items-start"><img src="/assets/images/event_location.png" alt="" width={17}/><span>Baneshwor, Kathmandu</span></p>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*    <div className="text-gray-400 space-x-4">*/}
                            {/*        <button className="rounded-full py-3 px-8 font-bold text-bColor bg-bColor-light">Apply</button>*/}
                            {/*        <Link to={""} className="rounded-full py-3 px-8 font-bold text-gray-700 bg-gray-100">Details</Link>*/}
                            {/*        <button className="rounded-full py-3 px-8 font-bold text-gray-700 bg-gray-100">Share</button>*/}
                            {/*    </div>*/}
                            {/*</article>*/}
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}