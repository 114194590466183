import EducationTopMenu from "../../components/education/EducationTopMenu";
import EducationMainMenuWithBackground from "../../components/education/EducationMainMenuWithBackground";
import EducationSectionHeading from "../../components/education/EducationSectionHeading";
import EducationThemedButton from "../../components/education/EducationThemedButton";
import EducationFooter from "../../components/education/EducationFooter";

export default function About() {
    return (
        <>
            <section id="hero">
                <EducationTopMenu/>
                <EducationMainMenuWithBackground background="/assets/images/edu_events_bg.png" height="25rem"/>
            </section>
            <main className="my-10">
                <section id="about">
                    <div className="max-w-1170 mx-auto mt-16">
                        <div className="flex">
                            <div className="w-1/2">
                                <EducationSectionHeading text="About us"/>
                                <span className="block text-5xl font-bold text-black mb-6">WHO WE ARE ?</span>
                                <p className="leading-relaxed">Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                                    Architecto autem blanditiis
                                    commodi doloremque ea, est impedit iure maiores minus nulla numquam, officiis
                                    quaerat
                                    quam qui, reprehenderit similique temporibus vel voluptatem. Lorem ipsum dolor sit
                                    amet,
                                    consectetur adipisicing elit.
                                </p>
                                <EducationThemedButton link="#" label="Read More"/>
                            </div>
                            <img src="/assets/images/about_building.png" alt="" className="ml-12" width="50%"/>
                        </div>
                    </div>
                </section>
                <section id="mvo">
                    <div style={{backgroundImage: "url(/assets/images/edu_mission_bg.png)"}}
                         className="relative bg-cover mt-16">
                        <div className="absolute top-0 bottom-0 left-0 right-0 bg-education opacity-90"/>
                        <div className="max-w-1170 mx-auto text-white relative">
                            <div className="py-16 flex space-x-16">
                                <article className="space-y-5 flex-1">
                                    <span className="text-gray-400 text-5xl font-light">01</span>
                                    <h3 className="font-bold">MISSION</h3>
                                    <p className="text-sm">To provide quality as well as practical education at an
                                        affordable cost in the
                                        multidisciplinary programs that will help our students in becoming independent.
                                    </p>
                                    <div className="flex items-center space-x-3">
                                        <span className="underline font-light">Learn more</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none"
                                             viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                                  d="M13 7l5 5m0 0l-5 5m5-5H6"/>
                                        </svg>
                                    </div>
                                </article>
                                <article className="space-y-5 flex-1">
                                    <span className="text-gray-400 text-5xl font-light">02</span>
                                    <h3 className="font-bold">VISION</h3>
                                    <p className="text-sm">
                                        After the completion of our students studies from any disciplines, there will be
                                        astonishing transformation in the students' attitudes. We are more conscious and
                                        well informed about our students' overall enhancement, i.e. self-respect, social
                                        discipline, entrepreneurship and management, pursuit of their higher studies,
                                        job
                                        training, team – working, prog...
                                    </p>
                                    <div className="flex items-center space-x-3">
                                        <span className="underline font-light">Learn more</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none"
                                             viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                                  d="M13 7l5 5m0 0l-5 5m5-5H6"/>
                                        </svg>
                                    </div>
                                </article>
                                <article className="space-y-5 flex-1">
                                    <span className="text-gray-400 text-5xl font-light">03</span>
                                    <h3 className="font-bold">OBJECTIVE</h3>
                                    <p className="text-sm">
                                        To provide quality as well as practical education at an affordable cost in the
                                        multidisciplinary programs that will help our students in becoming independent.
                                    </p>
                                    <div className="flex items-center space-x-3">
                                        <span className="underline font-light">Learn more</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none"
                                             viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                                  d="M13 7l5 5m0 0l-5 5m5-5H6"/>
                                        </svg>
                                    </div>
                                </article>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="statistics" className="mt-16">
                    <div className="max-w-1170 mx-auto">
                        <ul className="flex justify-between items-center">
                            <li>
                                <strong className="text-education font-bold text-4xl">100+</strong>
                                <span className="text-gray-400 text-sm block">Teachers</span>
                            </li>
                            <li>
                                <strong className="text-education font-bold text-4xl">35400+</strong>
                                <span className="text-gray-400 text-sm block">Students</span>
                            </li>
                            <li>
                                <strong className="text-education font-bold text-4xl">50+</strong>
                                <span className="text-gray-400 text-sm block">Course</span>
                            </li>
                            <li>
                                <strong className="text-education font-bold text-4xl">97400+</strong>
                                <span className="text-gray-400 text-sm block">Pass Out</span>
                            </li>
                        </ul>
                    </div>
                </section>
                <section id="contact">
                    <div className="bg-gray-100 mt-16">
                        <div className="max-w-1170 mx-auto py-12 flex space-x-4 h-full">
                            <div className="bg-white p-6 flex-grow">
                                <EducationSectionHeading text="Contact Us"/>
                                <span
                                    className="block text-5xl font-bold text-black mb-12 uppercase">Keep In Touch</span>
                                <form onSubmit={(event) => event.preventDefault()}
                                      className="grid grid-cols-2 gap-4">
                                    <input type="text" placeholder="Enter your name"
                                           className="block rounded-md p-3 w-full border-2 border-gray-300"/>
                                    <input type="email" placeholder="Enter your email address"
                                           className="block rounded-md p-3 w-full border-2 border-gray-300"/>
                                    <input type="text" placeholder="Enter your name"
                                           className="block rounded-md p-3 w-full border-2 border-gray-300"/>
                                    <input type="email" placeholder="Enter your email address"
                                           className="block rounded-md p-3 w-full border-2 border-gray-300"/>
                                    <textarea rows={5} placeholder="Write your comments/reviews"
                                              className="block rounded-md p-3 w-full border-2 border-gray-300 col-span-2"/>
                                    <div>
                                        <button type="submit"
                                                className="px-10 py-2.5 bg-education text-white rounded-md">Submit
                                        </button>
                                    </div>
                                </form>
                            </div>
                            <div className="flex flex-col h-full w-80 space-y-4">
                                <ul className="bg-white p-8 space-y-6">
                                    <li className="flex items-start space-x-4">
                                        <div className="mt-1">
                                            <img src="/assets/images/edu_phone.svg" alt="" className="w-5 h-5"/>
                                        </div>
                                        <div>
                                            <strong className="block font-bold text-xl leading-none">Contact
                                                Number</strong>
                                            <span
                                                className="text-sm">+01 5522484, +977 9867254986, +977 9823050306</span>
                                        </div>
                                    </li>
                                    <li className="flex items-start space-x-4">
                                        <div className="mt-1">
                                            <img src="/assets/images/edu_mail.svg" alt="" className="w-4 h-4"/>
                                        </div>
                                        <div>
                                            <strong
                                                className="block font-bold text-xl leading-none">Email</strong>
                                            <span className="text-sm">someone@somewhere.com</span>
                                        </div>
                                    </li>
                                    <li className="flex items-start space-x-4">
                                        <div className="mt-1">
                                            <img src="/assets/images/edu_location.svg" alt="" className="w-4 h-4"/>
                                        </div>
                                        <div>
                                            <strong
                                                className="block font-bold text-xl leading-none">Address</strong>
                                            <span className="text-sm">Chyasal, Lalitpur</span>
                                        </div>
                                    </li>
                                </ul>
                                <div
                                    className="map bg-white flex-grow h-full flex justify-center items-center bg-gray-500">
                                    <span>map</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <EducationFooter/>
        </>
    )
}
