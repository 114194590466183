import {Link} from "react-router-dom";
import routes from "../constants/routes";
import {externalLinks} from "../constants/externalLinks";
import {siteLinks} from "../constants/siteLinks";

export default function MainFooter() {

    if (window.location.host.split(".")[0] === "news") {
        return (
            <div className="border-t border-gray-200 px-4 py-4 md:px-8 mt-16">
                <footer className="flex justify-between text-sm flex-col md:flex-row items-center space-y-4 md:space-y-0">
                    <div className="flex space-x-4">
                        <a href={siteLinks.about}>About</a>
                        <a href={siteLinks.career}>Career</a>
                        <a href={siteLinks.gallery}>Gallery</a>
                        <a href={siteLinks.advertising}>Advertising</a>
                    </div>
                    <div>
                        <span className="mr-4">Get Our App</span>
                        <a href={externalLinks.appGoolge} title="Get our app from Playstore"><img
                            src="/assets/images/playstore.svg" className="inline" alt=""/> </a>
                        <span className="mx-2">|</span>
                        <a href={externalLinks.appApple} title="Get our app from Appstore"> <img
                            src="/assets/images/appstore.svg" className="inline" alt=""/></a>
                    </div>
                    <div className="flex space-x-4">
                        <a href={siteLinks.privacy}>Privacy Policy</a>
                        <a href={siteLinks.terms}>Terms</a>
                    </div>
                </footer>
            </div>
        )
    }

    return (
        <div className="border-t border-gray-200 px-4 py-4 md:px-8 mt-16">
            <footer className="flex justify-between text-sm flex-col md:flex-row items-center space-y-4 md:space-y-0">
                <div className="flex space-x-4">
                    <Link to={`/findall${routes.about}`}>About</Link>
                    <Link to={`${routes.career}`}>Career</Link>
                    <Link to={`/findall${routes.gallery}`}>Gallery</Link>
                    <Link to={`${routes.advertising}`}>Advertising</Link>
                </div>
                <div>
                    <span className="mr-4">Get Our App</span>
                    <a href={externalLinks.appGoolge} title="Get our app from Playstore"><img
                        src="/assets/images/playstore.svg" className="inline" alt=""/> </a>
                    <span className="mx-2">|</span>
                    <a href={externalLinks.appApple} title="Get our app from Appstore"> <img
                        src="/assets/images/appstore.svg" className="inline" alt=""/></a>
                </div>
                <div className="flex space-x-4">
                    <Link to={routes.privacy}>Privacy Policy</Link>
                    <Link to={routes.terms}>Terms</Link>
                </div>
            </footer>
        </div>
    )
}