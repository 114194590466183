import {useEffect, useState} from "react";
import {Transition} from "@headlessui/react";
import {useDispatch, useSelector} from "react-redux";
import {doShowSuccessNotification} from "../../store/voiceSlice";

export default function PostSuccessNotification() {

    const dispatch = useDispatch();
    const {create} = useSelector(state => state.voice);
    const [isShowing, setIsShowing] = useState(true)

    useEffect(() => {
        setTimeout(function () {
            dispatch(doShowSuccessNotification(false))
        }, 3000)
    }, [create.success]);

    return (
        <div className="fixed z-[999999999] left-1/2 -translate-x-1/2 top-0 mt-20">
            <Transition show={create.success}>
                <Transition.Child
                    enter="transition ease-in-out duration-300 transform"
                    enterFrom="-translate-y-full"
                    enterTo="translate-y-0"
                    leave="transition ease-in-out duration-300 transform"
                    leaveFrom="translate-y-0"
                    leaveTo="-translate-y-full"
                >
                    <div
                        className="shadow-custom bg-emerald-50 text-emerald-700 px-6 py-4 flex items-center justify-center space-x-4">
                        <img src="/assets/check-circle.png" alt="" className="w-6"/>
                        <span className="whitespace-nowrap">Post created successfully.</span>
                    </div>
                </Transition.Child>
            </Transition>
        </div>
    )
}