import "./App.css";
import {Routes, Route, Link, BrowserRouter, Navigate} from "react-router-dom"
import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import {doGetProfile} from "./store/userSlice";
import routes from "./constants/routes";
import Home from "./pages/Home";
import Jobs from "./pages/Jobs";
import JobsSingle from "./components/JobsSingle";
import Shop from "./pages/shop/Shop";
import SearchByType from "./pages/SearchByType";
import EducationRoot from "./pages/education/EducationRoot";
import UnClaimed from "./pages/UnClaimed";
import OrgConditionalRoute from "./components/OrgConditionalRoute";
import FourOFour from "./components/FourOFour";
import SearchByQuery from "./pages/SearchByQuery";
import CheckEnvironment from "./components/CheckEnvironment";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Tos from "./pages/Tos";
import ScrollToTop from "./others/ScrollToTop";
import {Helmet} from "react-helmet-async";
import NewsNewLayout from "./pages/NewsNewLayout";
import UnderConstruction from "./components/UnderConstruction";
import TodaysNews from "./pages/TodaysNews";
import OldNews from "./pages/OldNews";
import NewsHome from "./pages/NewsHome";
import NewsNewSingle from "./pages/NewsNewSingle";
import SearchNews from "./pages/SearchNews";
import VoiceLayout from "./pages/voice/VoiceLayout";
import VoiceHome from "./pages/voice/VoiceHome";
import VoiceHomeNew from "./pages/voice/VoiceHomeNew";
import TouristAreaLayout from "./pages/TouristAreaLayout";

function App() {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(doGetProfile());
    }, [dispatch])

    if (window.location.host.split(".")[0] === "news") {
        return (
            <BrowserRouter>
                <DefaultMetaTage/>
                <ScrollToTop/>
                <div className="App text-gray-700 m-auto min-w-1200 relative z-10 min-h-screen flex flex-col">
                    <Routes>
                        <Route path="/*" element={<NewsNewLayout/>}>
                            <Route path={`search`} element={<SearchNews/>}/>
                            <Route path={`todays`} element={<TodaysNews/>}/>
                            <Route path={`old`} element={<OldNews/>}/>
                            <Route path={`category`} element={<Navigate to={"/"}/>}/>
                            <Route path={`category/:category`} element={<NewsHome/>}/>
                            <Route path={`:slug`} element={<NewsNewSingle/>}/>
                            <Route path={""} element={<NewsHome/>}/>
                            <Route path="*" element={<div className="flex-grow"><FourOFour/></div>}/>
                        </Route>
                    </Routes>
                </div>
            </BrowserRouter>
        );
    }

    return (
        <BrowserRouter>
            <DefaultMetaTage/>
            {/*<ScrollToTop/>*/}
            <div className="app text-gray-700 m-auto min-w-1200 relative z-10 min-h-screen flex flex-col">
                <Routes>
                    <Route path={routes.unClaimed} element={<UnClaimed/>}/>
                    <Route path={`${routes.voice}/*`} element={<VoiceLayout/>}>
                        <Route path={`:type`} element={<VoiceHomeNew/>}/>
                        <Route path={""} element={<VoiceHomeNew/>}/>
                    </Route>

                    <Route path={`${routes.shop}/*`} element={
                        <CheckEnvironment redirectTo={routes.underConstruction}> <Shop/> </CheckEnvironment>
                    }/>
                    <Route path={`${routes.jobs}/:title`} element={
                        <CheckEnvironment redirectTo={routes.underConstruction}> <JobsSingle/> </CheckEnvironment>
                    }/>
                    <Route path={routes.jobs} element={
                        <CheckEnvironment redirectTo={routes.underConstruction}> <Jobs/> </CheckEnvironment>
                    }/>
                    <Route path={`${routes.underConstruction}`} element={<UnderConstruction/>}/>
                    <Route path={`${routes.organization}/:orgName/*`} element={<EducationRoot/>}/>
                    {/*<Route path={routes.educationAll} element={<AfterSeeMore/>}/>*/}
                    <Route path={`${routes.searchByCat}/:category`} element={<SearchByType/>}/>
                    <Route path={routes.searchByQuery} element={<SearchByQuery/>}/>
                    <Route path={routes.privacy} element={<PrivacyPolicy/>}/>
                    <Route path={routes.advertising} element={<UnderConstruction/>}/>
                    <Route path={routes.career} element={<UnderConstruction/>}/>
                    <Route path={routes.terms} element={<Tos/>}/>
                    <Route path={`${routes.home}:slug/*`} element={<OrgConditionalRoute/>}/>
                    <Route path={routes.home} exact element={<Home/>}/>
                    <Route path="/aaaa" exact element={<TouristAreaLayout/>}/>
                    <Route element={<FourOFour/>}/>
                </Routes>
            </div>
        </BrowserRouter>
    );
}

export function NotFound() {
    return (
        <>
            <p>404</p>
            <p>routes not defined yet.</p>
            <Link to="/">Back to home</Link>
        </>
    )
}

function DefaultMetaTage() {
    return (
        <Helmet>
            <meta property="og:type" content="website"/>
            <meta name="twitter:card" content="summary_large_image"/>
        </Helmet>
    )
}

export default App;
