export default function ShopHomeSearch() {

    return (
        <section id="search">
            <div className="max-w-screen-xl mx-auto">
                <div className="mt-16 flex justify-center items-center flex-col">
                    <div>
                        <img src="/logo.svg" alt="find all logo" className="w-20"/>
                    </div>
                    <p className="mt-4 text-secondaryColor font-black text-4xl">FIND ALL <span
                        className="text-bColor">NEPAL</span></p>
                    <p className="font-playfair mt-12 text-2xl text-bColor font-bold">Search <span
                        className="text-secondaryColor">for product & find </span>
                        seller <span className="text-secondaryColor">near you</span>
                    </p>
                </div>
                <div className="mt-8 flex">
                    {/*<div className="max-w-xs w-full">*/}
                    {/*    <Select options={options} styles={customStyles} isClearable placeholder="Select your city..."/>*/}
                    {/*</div>*/}
                    <div className="bg-secondaryColor pr-2 pl-4 rounded-tl-2xl rounded-bl-2xl outline-none py-1">
                        <select
                            name="city" id="city" placeholder="Select your city"
                            className="bg-secondaryColor text-white pl-10 py-4 "
                        >
                            <option value="hetauda">Hetauda</option>
                            <option value="butwal">Butwal</option>
                            <option value="birgunj">Birgunj</option>
                            <option value="pokhara">Pokhara</option>
                            <option value="kathmanudu">kathmanudu</option>
                        </select>
                    </div>
                    <input type="text" className="flex-grow px-6 shadow-custom outline-none "
                           placeholder="Search for products"/>
                    <button className="bg-bColor px-6 rounded-tr-2xl rounded-br-2xl outline-none text-white">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24"
                             stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"/>
                        </svg>
                    </button>
                </div>
            </div>
        </section>
    )
}