import {useCallback, useState} from "react";
import {useGetSearchBarResultsMutation} from "../services/directoryApi";
import {debounce} from "lodash/function";
import {useCombobox} from "downshift";
import {useNavigate, useParams} from "react-router-dom";
import {useQuery} from "../others/useQuery";

export default function SearchbarOnly2({full, short, placeholder}) {

    const queryString = useQuery();
    const navigate = useNavigate();
    const param = useParams();

    const [query, setQuery] = useState(queryString.get("q") || "");
    const [fetchSuggestions, {data, isLoading}] = useGetSearchBarResultsMutation();

    const {isOpen, getMenuProps, getInputProps, getComboboxProps, highlightedIndex, getItemProps,} = useCombobox(
        {
            items: data?.data || [],
            initialInputValue: query,
            onInputValueChange: ({inputValue}) => {
                setQuery(inputValue)
                debouncedFetch(inputValue)
            },
        });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedFetch = useCallback(
        debounce((value) => fetchSuggestions(value), 700),
        []
    );

    const handleSubmit = (event) => {
        event.preventDefault();
        navigate(`/search?q=${query}`)
        if (short) navigate(`/search/${param.category}?q=${query}`)
        // window.open(`/search?q=${query}`, "_self");
    }

    return (
        <form className="flex flex-grow bg-white border border-gray-300 rounded-full" onSubmit={handleSubmit}>
            <div {...getComboboxProps()} className="inline-block ml-1 relative flex-grow">
                {full &&
                <>
                    <input {...getInputProps()} placeholder={placeholder}
                           className={`w-full rounded-tl-[2rem] px-8 py-4 outline-none ${isOpen ? "" : "rounded-bl-[2rem]"}`}/>
                    <ul className={`bg-white p-0 absolute left-0 right-0 border border-gray-400 border-t-0 z-10 ${!isOpen && "hidden"}`} {...getMenuProps()}>
                        {isOpen && isLoading
                            ? <li className="px-4 py-2">...loading</li>
                            : isOpen && data?.data?.length > 0
                                ? data?.data?.map((item, index) => (
                                        <li className={`px-4 py-2 ${(highlightedIndex === index) ? "bg-gray-200" : ""}`}
                                            key={`${item}${index}`}
                                            {...getItemProps({item, index})}
                                        >
                                            {item}
                                        </li>
                                    )
                                )
                                : (isOpen) && <li className="px-4 py-2">No results</li>
                        }
                    </ul>
                </>
                }
                {short &&
                <>
                    <input {...getInputProps()} placeholder={placeholder}
                           className={`w-full rounded-3xl px-4 py-2 outline-none`}/>
                    <ul className={`bg-white p-0 absolute inset-x-4 border border-gray-400 border-t-0 z-10 ${!isOpen && "hidden"}`} {...getMenuProps()}>
                        {isOpen && isLoading
                            ? <li className="px-4 py-2">...loading</li>
                            : isOpen && data?.data?.length > 0
                                ? data?.data?.map((item, index) => (
                                        <li className={`px-4 py-2 ${(highlightedIndex === index) ? "bg-gray-200" : ""}`}
                                            key={`${item}${index}`}
                                            {...getItemProps({item, index})}
                                        >
                                            {item}
                                        </li>
                                    )
                                )
                                : (isOpen) && <li className="px-4 py-2">No results</li>
                        }
                    </ul>
                </>
                }
            </div>
            {full &&
            <button className="px-6 py-3 rounded-full bg-gray-100 hover:bg-gray-200 m-1" type="submit">
                <img src="/assets/images/search-icon.svg" alt="" width={20} height={20} className="pointer-events-none"/>
            </button>
            }
        </form>
    )
}