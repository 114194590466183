import ShopSecondaryHeader from "../../components/shop/ShopSecondaryHeader";
import {useState} from "react";
import {Link} from "react-router-dom";
import routes from "../../constants/routes";
import ProductCard from "../../components/shop/ProductCard";

export default function Cart() {

    const [quantity, setQuantity] = useState(1);

    const handleQuantityDecrease = () => {
        setQuantity(prevState => prevState - 1)
    }
    const handleQuantityIncrease = () => {
        setQuantity(prevState => prevState + 1)
    }

    return (
        <div className="max-w-screen-xl mx-auto">
            <ShopSecondaryHeader/>
            <section>
                <div className="space-y-4">
                    <article className="flex space-x-4">
                        <div className="flex rounded-xl border border-gray-100 py-4 px-8 flex-grow">
                            <img className="w-32" src="/assets/images/product_image.png" alt=""/>
                            <div id="name-stock-price" className="ml-6 flex-grow">
                                <div className="flex justify-between">
                                    <div id="header" className="border-b border-gray-200 mb-1 pb-1">
                                        <Link to={`${routes.product}/some-hoodie`}>
                                            <h1 className="text-xl">Black Color Hoodie for Men</h1>
                                        </Link>
                                        <div>
                                            <div className="Stars mt-1" style={{"--rating": "4", "--star-size": "1rem"}}
                                                 aria-label="Rating of this product.">
                                                <span className="text-xs">4 Star Rating</span>
                                            </div>
                                            <span className="mx-2">|</span>
                                            <span className="text-xs">1 Reviews</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-2 font-bold space-x-2">
                                    <span className="inline-block">Rs. 2500</span>
                                    <span className="inline-block text-gray-400 line-through text-sm">Rs. 2800</span>
                                    <strong className="text-bColor">10% off</strong>
                                </div>
                            </div>
                            <div className="flex flex-col justify-between items-end">
                                <button><img className="w-6" src="/assets/images/ICON_SHARE.svg" alt=""/></button>
                                <button className="bg-bColor text-white py-1 px-4 rounded-lg text-sm">Add to WishList
                                </button>
                            </div>
                        </div>
                        <div className="flex flex-col justify-evenly rounded-xl border border-gray-100 py-4 px-8">
                            <div id="quantity-selector" className="flex items-center mt-2 space-x-2">
                                <span className="text-gray-500 text-sm">Quantity</span>
                                <div className="space-x-3">
                                    <button
                                        className="bg-gray-200 px-3 py-2 inline-block leading-none disabled:opacity-50"
                                        disabled={quantity <= 1} onClick={handleQuantityDecrease}>-
                                    </button>
                                    <span>{quantity}</span>
                                    <button className="bg-gray-200 px-3 py-2 inline-block leading-none"
                                            onClick={handleQuantityIncrease}>+
                                    </button>
                                </div>
                            </div>
                            <p>Subtotal: <span className="bg-bColor rounded-lg text-white px-4 py-1">2200</span></p>
                        </div>
                        <button className="self-center text-red-600 border-2 border-red-600 p-2">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24"
                                 stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                      d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"/>
                            </svg>
                        </button>
                    </article>
                    <article className="flex space-x-4">
                        <div className="flex rounded-xl border border-gray-100 py-4 px-8 flex-grow">
                            <img className="w-32" src="/assets/images/product_image.png" alt=""/>
                            <div id="name-stock-price" className="ml-6 flex-grow">
                                <div className="flex justify-between">
                                    <div id="header" className="border-b border-gray-200 mb-1 pb-1">
                                        <Link to={`${routes.product}/some-hoodie`}>
                                            <h1 className="text-xl">Black Color Hoodie for Men</h1>
                                        </Link>
                                        <div>
                                            <div className="Stars mt-1" style={{"--rating": "4", "--star-size": "1rem"}}
                                                 aria-label="Rating of this product.">
                                                <span className="text-xs">4 Star Rating</span>
                                            </div>
                                            <span className="mx-2">|</span>
                                            <span className="text-xs">1 Reviews</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-2 font-bold space-x-2">
                                    <span className="inline-block">Rs. 2500</span>
                                    <span className="inline-block text-gray-400 line-through text-sm">Rs. 2800</span>
                                    <strong className="text-bColor">10% off</strong>
                                </div>
                            </div>
                            <div className="flex flex-col justify-between items-end">
                                <button><img className="w-6" src="/assets/images/ICON_SHARE.svg" alt=""/></button>
                                <button className="bg-bColor text-white py-1 px-4 rounded-lg text-sm">Add to WishList
                                </button>
                            </div>
                        </div>
                        <div className="flex flex-col justify-evenly rounded-xl border border-gray-100 py-4 px-8">
                            <div id="quantity-selector" className="flex items-center mt-2 space-x-2">
                                <span className="text-gray-500 text-sm">Quantity</span>
                                <div className="space-x-3">
                                    <button
                                        className="bg-gray-200 px-3 py-2 inline-block leading-none disabled:opacity-50"
                                        disabled={quantity <= 1} onClick={handleQuantityDecrease}>-
                                    </button>
                                    <span>{quantity}</span>
                                    <button className="bg-gray-200 px-3 py-2 inline-block leading-none"
                                            onClick={handleQuantityIncrease}>+
                                    </button>
                                </div>
                            </div>
                            <p>Subtotal: <span className="bg-bColor rounded-lg text-white px-4 py-1">2200</span></p>
                        </div>
                        <button className="self-center text-red-600 border-2 border-red-600 p-2">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24"
                                 stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                      d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"/>
                            </svg>
                        </button>
                    </article>
                </div>
                <div className="flex justify-between mt-6">
                    <div>
                        <button className="bg-bColor text-white py-1 px-4 rounded-lg text-sm mr-4 shadow-custom">
                            Continue Shopping
                        </button>
                        <button className="py-1 px-4 rounded-lg text-sm border-2 border-gray-300 shadow-custom">
                            Clear List
                        </button>
                    </div>
                    <div className="self-end">
                        <p className="font-bold mb-2">Total Amount: <span className="text-bColor">22000</span></p>
                        <Link to={`${routes.checkoutInformation}`}
                              className="bg-bColor text-white py-3 px-6 rounded-lg shadow-custom block">
                            Proceed to Checkout
                        </Link>
                    </div>
                </div>
            </section>
            <section className="mt-16">
                <div className="border-t border-gray-200 mt-8">
                    <div className="flex justify-between my-4 text-xl">
                        <p>Inspired By Your List</p>
                    </div>
                    <div className="products-grid">
                        <ProductCard/>
                        <ProductCard/>
                        <ProductCard/>
                        <ProductCard/>
                        <ProductCard/>
                    </div>
                </div>
                <div className="border-t border-gray-200 mt-8">
                    <div className="flex justify-between my-4 text-xl">
                        <p>Your Searching History</p>
                    </div>
                    <div className="products-grid">
                        <ProductCard/>
                        <ProductCard/>
                        <ProductCard/>
                        <ProductCard/>
                        <ProductCard/>
                    </div>
                </div>
            </section>
        </div>
    )
}