import {Route, Routes} from "react-router-dom";
import GenericOrganizationEvents from "./GenericOrganizationEvents";
import React from "react";
import GenericOrganizationEventsSingle from "./GenericOrganizationEventsSingle";

export default function GenericOrganizationEventsRoot({data}) {
    return (
        <Routes>
            <Route path={`:eventSlug`} element={<GenericOrganizationEventsSingle data={data}/>}/>
            <Route path={`/`} exact element={<GenericOrganizationEvents data={data}/>}/>
        </Routes>
    )
}