export default function Filters() {
    return (
        <div className="mr-8 w-64">
            <div className="border-b border-gray-200 pr-8 pb-4 mb-4">
                <p className="text-lg mb-1">Filters</p>
                <span className="text-xs text-bColor">Hoodies</span>
            </div>
            <div className="border-b border-gray-200 pr-8 pb-4 mb-4">
                <p className="text-lg mb-2">Brand</p>
                <label className="block">
                    <input type="radio" className="inline mr-2" name="brand"/>
                    <span>Raymond</span>
                </label>
                <label className="block">
                    <input type="radio" className="inline mr-2" name="brand"/>
                    <span>Woodland</span>
                </label>
                <label className="block">
                    <input type="radio" className="inline mr-2" name="brand"/>
                    <span>Under Armour</span>
                </label>
                <ViewMoreButton/>
            </div>
            <div className="border-b border-gray-200 pr-8 pb-4 mb-4">
                <p className="text-lg mb-2">Size</p>
                <label className="block">
                    <input type="radio" className="inline mr-2" name="size"/>
                    <span>S</span>
                </label>
                <label className="block">
                    <input type="radio" className="inline mr-2" name="size"/>
                    <span>M</span>
                </label>
                <label className="block">
                    <input type="radio" className="inline mr-2" name="size"/>
                    <span>L</span>
                </label>
                <label className="block">
                    <input type="radio" className="inline mr-2" name="size"/>
                    <span>XXL</span>
                </label>
                <ViewMoreButton/>
            </div>
            <div className="border-b border-gray-200 pr-8 pb-4 mb-4">
                <p className="text-lg mb-2">Color</p>
                <label className="block">
                    <input type="radio" className="inline mr-2" name="color"/>
                    <span>Navy Blue</span>
                </label>
                <label className="block">
                    <input type="radio" className="inline mr-2" name="color"/>
                    <span>Maroon</span>
                </label>
                <label className="block">
                    <input type="radio" className="inline mr-2" name="color"/>
                    <span>Cyan</span>
                </label>
                <label className="block">
                    <input type="radio" className="inline mr-2" name="color"/>
                    <span>XXL</span>
                </label>
                <ViewMoreButton/>
            </div>
            <div className="border-b border-gray-200 pr-8 pb-4 mb-4">
                <p className="text-lg mb-2">Price Range</p>
                <input type="range" min="1" max="100" value="50" className="slider" id="myRange"/>
            </div>
            <div className="border-b border-gray-200 pr-8 pb-4 mb-4">
                <p className="text-lg mb-2">Discount</p>
                <label className="block">
                    <input type="radio" className="inline mr-2" name="discount"/>
                    <span>5% to 10%</span>
                </label>
                <label className="block">
                    <input type="radio" className="inline mr-2" name="discount"/>
                    <span>10% to 20%</span>
                </label>
                <label className="block">
                    <input type="radio" className="inline mr-2" name="discount"/>
                    <span>20% & above</span>
                </label>
            </div>
        </div>
    )
}

function ViewMoreButton() {
    return (
        <button className="mt-4 px-8 rounded-full border border-gray-600">View More</button>
    )
}