import ShopSecondaryHeader from "../../components/shop/ShopSecondaryHeader";

export default function OrderConfirmed() {
    return (
        <div className="max-w-screen-xl mx-auto">
            <ShopSecondaryHeader/>
            <section className="mt-24 h-96">
                <div className="flex flex-col items-center text-bColor text-lg">
                        <span className="p-2 border-2 border-bColor inline-block rounded-full">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10" fill="none" viewBox="0 0 24 24"
                             stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={3} d="M5 13l4 4L19 7"/>
                        </svg>
                        </span>
                    <p className="font-bold mt-1">Thank you!</p>
                </div>
                <div className="flex flex-col items-center mt-8">
                    <p className="text-bColor font-bold">Your order is in process</p>
                    <span>In certain time we will contact for your order</span>
                </div>
            </section>
        </div>
    )
}