import {useSelector} from "react-redux";
import React from "react";

function VoiceDisplayPic() {

    const {data, isLoading} = useSelector(state => state.user);
    const regex = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');


    if (!isLoading && data?.user) {
        let initials = [...data?.user?.displayName.matchAll(regex)] || [];
        initials = ((initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')).toUpperCase();
        return (
            <div className="w-7 h-7 rounded-full flex justify-center items-center">
                {data?.user?.image
                    ? <img src={data?.user?.image?.path} alt="" className="w-7 h-7 rounded-full pointer-events-none"
                           width={28} height={28}/>
                    : <span
                        className="w-7 h-7 rounded-full border-2 border-gray-200 bg-red-500 text-white block text-sm flex items-center justify-center">{initials}</span>
                }
            </div>
        )
    }

    return (
        <div className="w-7 h-7 rounded-full flex justify-center items-center bg-gray-200"/>
    )

}

export default VoiceDisplayPic;