import {useQuery} from "../others/useQuery";
import {useGetNewsListQuery} from "../services/newsApi";
import {getStartEndDates} from "../others/newsUtils";
import NewsArticle from "../components/NewsArticleCard";
import React from "react";

export default function TodaysNews() {

    const query = useQuery();

    const {data, isFetching} = useGetNewsListQuery({
        category: query.get("category") || null, start_date: getStartEndDates("start"),
        end_date: getStartEndDates("end"), q: "", today: true
    })

    return (
        <main className="flex-grow">
            <section>
                <div className="flex items-center">
                    <h2 className="uppercase text-2xl font-light mr-3 my-6">{query.get("category") || ""} News</h2>
                    <div className="h-1.5 border-t-3 border-b border-gray-200 flex-grow"/>
                </div>
                <div className="grid grid-cols-2 gap-2">
                    {data?.news?.length > 0
                        ? data?.news?.map(current => (
                            <NewsArticle current={current} key={current.id}/>
                        ))
                        : <div className="max-w-1170 my-12"><p>No news to show.</p></div>
                    }
                </div>
            </section>
        </main>
    )
}