import {useParams} from "react-router-dom";
import ShopSecondaryHeader from "../../components/shop/ShopSecondaryHeader";
import BreadCrumbs from "../../components/shop/BreadCrumbs";
import Filters from "../../components/shop/Filters";
import ProductCard from "../../components/shop/ProductCard";

export default function Category() {

    const {catName} = useParams();

    return (
        <div className="max-w-screen-xl mx-auto">
            <ShopSecondaryHeader/>
            <BreadCrumbs/>
            <div className="flex mt-8">
                <Filters/>
                <div className="flex-grow">
                    <div className="flex justify-between  border-b border-gray-200 pb-4 mb-4">
                        <div>
                            <p className="text-lg mb-1">Hoodies for Men</p>
                            <span className="text-xs">112 items found for "{catName}"</span>
                        </div>
                        <div>
                            <label htmlFor="cars">Sort By:</label>
                            <select name="sort" id="sort"
                                    className="ml-1 p-2 border border-gray-700 rounded-lg outline-none">
                                <option value="recommended">Recommended</option>
                                <option value="saab">Price Low to High</option>
                                <option value="mercedes">Price High to Low</option>
                                <option value="audi">Most Popular</option>
                                <option value="pop">Most Popular</option>
                                <option value="new">New Arrivals</option>
                                <option value="dis">Discount</option>
                            </select>
                        </div>
                    </div>
                    <div className="products-grid">
                        <ProductCard/>
                        <ProductCard/>
                        <ProductCard/>
                        <ProductCard/>
                        <ProductCard/>
                    </div>
                </div>
            </div>
        </div>
    )
}