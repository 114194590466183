import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {baseUrlVoice, baseUrlVoiceImage} from "../constants/endpoints";
import axios from "axios";
import handleError from "../network/handleError";
import _ from "lodash";

const initialState = {
    data: {
        posts: [],
        next: null,
        hasMore: false,
    },
    isLoading: true,
    isFetching: false,
    isError: false,
    create: {
        success: false,
        isLoading: false,
        isError: {status: false, message: ""},
    },
    image: {
        list: [],
        isUploading: false,
        isError: {status: false, message: ""},
    },
    showLoginPopup: false,
    comments: {
        fetch: {
            isLoading: false,
            isFetchingMore: false,
            list: {comments: []},
            isError: {status: false, message: ""},
        },
        create: {
            isSuccess: false,
            isLoading: false,
            isError: {status: false},
            text: "",
        }
    }
}

const doGetPosts = createAsyncThunk(
    "voice/doGetPosts",
    async (data = null, thunkAPI) => {
        const url = data.auth ? `/entertainment?superman=${data.type}` : `/unauth/entertainment?superman=${data.type}`
        try {
            const response = await fetch(`${baseUrlVoice}${url}`, {
                credentials: "include",
                method: "get",
            });
            return response.json();
        } catch (error) {
            // const {message} = error.payload
            // console.log(message)
            return thunkAPI.rejectWithValue(error)
        }
    }
)

const doReGetPosts = createAsyncThunk(
    "voice/doReGetPosts",
    async (data = null, thunkAPI) => {
        const url = data.auth ? `/entertainment?superman=${data.type}&next=${data.next || ""}` : `/unauth/entertainment?superman=${data.type}&next=${data.next || ""}`
        try {
            const response = await fetch(`${baseUrlVoice}${url}`, {
                credentials: "include",
                method: "get",
            });
            return response.json();
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

const doCreatePosts = createAsyncThunk(
    "voice/doCreatePosts",
    async (data, thunkAPI) => {
        const url = `/entertainment?superman=${data.type}`;
        try {
            const response = await axios.post(`${baseUrlVoice}${url}`, data.formData, {
                withCredentials: true,
            });
            return {data: response.data, slug: data.slug, selectedType: data.selectedType};
        } catch (error) {
            const errorMessage = handleError(error)
            return thunkAPI.rejectWithValue(errorMessage);
        }
    }
)

const doUploadImage = createAsyncThunk(
    "voice/doUploadImage",
    async (data, thunkAPI) => {
        try {
            const response = await axios.post(`${baseUrlVoiceImage}/image/upload`, data.formData, {
                withCredentials: true,
            });
            return response.data;
        } catch (error) {
            const errorMessage = handleError(error)
            return thunkAPI.rejectWithValue(errorMessage);
        }
    }
)


const doLikeUnLikePost = createAsyncThunk(
    "voice/doLikeUnLikePost",
    async (data, thunkAPI) => {
        try {
            if (data.action === "like") {
                const response = await axios.post(`${baseUrlVoice}/like?_sp=${data.slug}&superman=ni`, "", {
                    withCredentials: true,
                });
                return response.data;
            } else if (data.action === "unlike") {
                const response = await axios.delete(`${baseUrlVoice}/like?_sp=${data.slug}&superman=ni`, {
                    withCredentials: true,
                });
                return response.data;
            }
        } catch (error) {
            const errorMessage = handleError(error)
            return thunkAPI.rejectWithValue(errorMessage);
        }
    }
)

const doLikeUnLikeComment = createAsyncThunk(
    "voice/doLikeUnLikeComment",
    async (data, thunkAPI) => {
        try {
            if (data.action === "like") {
                const response = await axios.post(`${baseUrlVoice}/like?_sp=${data.id}&superman=nc`, "", {
                    withCredentials: true,
                });
                return response.data;
            } else if (data.action === "unlike") {
                const response = await axios.delete(`${baseUrlVoice}/like?_sp=${data.id}&superman=nc`, {
                    withCredentials: true,
                });
                return response.data;
            }
        } catch (error) {
            const errorMessage = handleError(error)
            return thunkAPI.rejectWithValue(errorMessage);
        }
    }
)

const doLikeUnLikeCommentPopup = createAsyncThunk(
    "voice/doLikeUnLikeCommentPopup",
    async (data, thunkAPI) => {
        try {
            if (data.action === "like") {
                const response = await axios.post(`${baseUrlVoice}/like?_sp=${data.id}&superman=nc`, "", {
                    withCredentials: true,
                });
                return response.data;
            } else if (data.action === "unlike") {
                const response = await axios.delete(`${baseUrlVoice}/like?_sp=${data.id}&superman=nc`, {
                    withCredentials: true,
                });
                return response.data;
            }
        } catch (error) {
            const errorMessage = handleError(error)
            return thunkAPI.rejectWithValue(errorMessage);
        }
    }
)

const doGetComments = createAsyncThunk(
    "voice/doGetComments",
    async (data = null, thunkAPI) => {
        try {
            const response = await axios.get(`${baseUrlVoice}/comment?superman=no&_sp=${data.slug}`, {
                withCredentials: true,
            });
            return response.data;
        } catch (error) {
            // const {message} = error.payload
            // console.log(message)
            return thunkAPI.rejectWithValue(error)
        }
    }
)

const doGetMoreComments = createAsyncThunk(
    "voice/doGetMoreComments",
    async (data = null, thunkAPI) => {
        try {
            const response = await axios.get(`${baseUrlVoice}/comment?superman=no&_sp=${data.slug}&next=${data.token}`, {
                withCredentials: true,
            });
            return response.data;
        } catch (error) {
            // const {message} = error.payload
            // console.log(message)
            return thunkAPI.rejectWithValue(error)
        }
    }
)

const doPostComment = createAsyncThunk(
    "voice/doPostComment",
    async (data = null, thunkAPI) => {
        try {
            const payload = data.id
                ? {_sp: data.slug, _olo: 2, _io: data.comment, _ol: data.id}
                : {_sp: data.slug, _olo: 2, _io: data.comment}
            const response = await axios.post(`${baseUrlVoice}/comment?superman=no`,
                payload, {withCredentials: true,}
            );

            return response.data;
        } catch (error) {
            // const {message} = error.payload
            // console.log(message)
            return thunkAPI.rejectWithValue(error)
        }
    }
)

const doPostCommentPopUp = createAsyncThunk(
    "voice/doPostCommentPopUp",
    async (data = null, thunkAPI) => {
        try {
            const payload = data.id
                ? {_sp: data.slug, _olo: 2, _io: data.comment, _ol: data.id}
                : {_sp: data.slug, _olo: 2, _io: data.comment}
            const response = await axios.post(`${baseUrlVoice}/comment?superman=no`,
                payload, {withCredentials: true,}
            );

            return response.data;
        } catch (error) {
            // const {message} = error.payload
            // console.log(message)
            return thunkAPI.rejectWithValue(error)
        }
    }
)


const voiceSlice = createSlice({
    name: "voice",
    initialState,
    reducers: {
        doResetCreateErrors: (state) => {
            state.create.isError = {status: false, message: ""}
        },
        doSetCommentText: (state, {payload}) => {
            state.comments.create.text = payload;
        },
        doToggleLoginPopup: (state, action) => {
            state.showLoginPopup = action.payload
        },
        doResetImageList: (state) => {
            state.image.list = [];
        },
        doShowSuccessNotification: (state, action) => {
            state.create.success = action.payload;
        },
        doSetImageError: (state, action) => {
            state.image.isError = {status: action.payload.status, message: action.payload.message};
        },
    },
    extraReducers: {

        //like a post
        [doLikeUnLikePost.pending]: (state, action) => {
            state.data.posts = state.data.posts.map(item => {
                if (item.slug === action.meta.arg.slug) {
                    item.is_heart = (action.meta.arg.action === "like")
                    item.heart_count = (action.meta.arg.action === "like") ? item.heart_count + 1 : item.heart_count - 1
                    return item
                }
                return item
            });
        },
        [doLikeUnLikePost.fulfilled]: (state, {payload}) => {
            // console.log("sds")
        },
        [doLikeUnLikePost.rejected]: (state, action) => {
            state.data.posts = state.data.posts.map(item => {
                if (item.slug === action.meta.arg.slug) {
                    item.is_heart = !(action.meta.arg.action === "like")
                    item.heart_count = (action.meta.arg.action === "like") ? item.heart_count - 1 : item.heart_count + 1
                    return item
                }
                return item
            });
        },


        //like a comment (on not popup)
        [doLikeUnLikeComment.pending]: (state, action) => {
            const {id, index, path, action: actionType} = action.meta.arg
            const fullPath = `${path}[${index}]`;
            const comment = _.get(state.data.posts, fullPath)
            console.log(comment)
            comment.isLiked = (actionType === "like")
            comment.likes = (actionType === "like") ? comment.likes + 1 : comment.likes - 1
            _.set(state.data.posts, fullPath, comment)
        },
        [doLikeUnLikeComment.fulfilled]: (state, {payload}) => {
        },
        [doLikeUnLikeComment.rejected]: (state, action) => {
            const {id, index, path, action: actionType} = action.meta.arg
            const fullPath = `${path}[${index}]`;
            const comment = _.get(state.data.posts, fullPath)
            console.log(comment)
            comment.isLiked = !(actionType === "like")
            comment.likes = (actionType === "like") ? comment.likes - 1 : comment.likes + 1
            _.set(state.data.posts, fullPath, comment)
        },


        //do post create new comment from home (not popup)
        [doPostComment.pending]: (state, action) => {
            // state.comments.create.isLoading = true;
            // state.comments.create.isSuccess = false;
            // state.comments.create.isError.status = false;
        },
        [doPostComment.fulfilled]: (state, action) => {
            if (action.meta.arg.id) {
                const comment = _.get(state.data.posts, action.meta.arg.path)
                if (comment.reply) {
                    comment.reply.push(action.payload.payload)
                } else {
                    comment.reply = []
                    comment.reply.push(action.payload.payload)
                }
                _.set(state.data.posts, action.meta.arg.path, comment)
            } else {
                console.log(action.meta.arg.path)
                const comment = _.get(state.data.posts, action.meta.arg.path)
                console.log(comment)
                comment.unshift(action.payload.payload)
                _.set(state.data.posts, action.meta.arg.path, comment)
            }

        },
        [doPostComment.rejected]: (state, action) => {
            // state.comments.create.isLoading = false;
            // state.comments.create.isError.status = true;
            // state.comments.create.isSuccess = false;
        },


        //fetch posts
        [doGetPosts.pending]: state => {
            state.isLoading = true;
            // state.isFetching = true;
        },
        [doGetPosts.fulfilled]: (state, {payload}) => {
            state.data = {
                posts: [...payload.payload.posts],
                hasMore: payload.payload.hasMore,
                next: payload.payload.next,
            }
            state.isLoading = false;
        },
        [doGetPosts.rejected]: (state, action) => {
            state.isError = true;
        },


        //re-fetch posts
        [doReGetPosts.pending]: state => {
            state.isFetching = true;
            state.data = {
                ...state.data,
                posts: [...state.data.posts],
                // hasMore: false,
                // next: null,
            }
        },
        [doReGetPosts.fulfilled]: (state, {payload}) => {
            state.isFetching = false;
            state.data = {
                posts: [...state.data.posts, ...payload.payload.posts],
                hasMore: payload.payload.hasMore,
                next: payload.payload.next,
            }
            // state.data.posts = [...state.data.posts, ...payload.payload.posts];
            // state.data.hasMore = payload.payload.hasMore;
            // state.data.next = payload.payload.next;
        },
        [doReGetPosts.rejected]: (state, action) => {
            state.isError = true;
        },


        //create new posts, new posts
        [doCreatePosts.pending]: state => {
            state.create.success = false;
            state.create.isLoading = true;
            state.create.isError = {status: false, message: ""};
        },
        [doCreatePosts.fulfilled]: (state, {payload}) => {
            if (payload.slug === "all" || (payload.slug === payload.selectedType)) {
                state.data.posts.unshift(payload.data.payload)
            }
            state.create.success = true;
            state.create.isLoading = false;
        },
        [doCreatePosts.rejected]: (state, action) => {
            state.create.success = false;
            state.create.isLoading = false;
            state.create.isError = {status: true, message: action.payload.message};
            // state.create.isError.status = true;
            // state.create.isError.message = action.payload.message;
        },


        //upload image upload image upload image upload image
        [doUploadImage.pending]: state => {
            state.image.isUploading = true
            state.image.isError = {status: false, message: ""};
        },
        [doUploadImage.fulfilled]: (state, {payload}) => {
            // state.data.posts.unshift(payload.payload)
            payload.message.payload.map(current => state.image.list.push(current))
            state.image.isUploading = false;
        },
        [doUploadImage.rejected]: (state, action) => {
            state.image.isUploading = false
            state.image.isError = {status: true, message: action.payload.message};
        },


        //fetch comments on popup
        [doGetComments.pending]: (state) => {
            state.comments = {
                ...state.comments,
                fetch: {
                    isLoading: true,
                    list: {comments: []},
                    isError: {status: false, message: ""},
                },
            }
        },
        [doGetComments.fulfilled]: (state, {payload}) => {
            state.comments = {
                ...state.comments,
                fetch: {
                    isLoading: false,
                    list: {...payload.payload},
                    isError: {status: false, message: ""},
                },
            }
        },
        [doGetComments.rejected]: (state) => {
            state.comments = {
                ...state.comments,
                fetch: {
                    isLoading: false,
                    list: {},
                    isError: {status: true, message: "Sorry some error occurred. Reload the page to try again."},
                },
            }
        },


        //fetch more previous comments on popup
        [doGetMoreComments.pending]: (state) => {
            state.comments = {
                ...state.comments,
                fetch: {
                    ...state.comments.fetch,
                    isFetchingMore: true,
                    isError: {status: false, message: ""},
                },
            }
        },
        [doGetMoreComments.fulfilled]: (state, {payload}) => {
            state.comments = {
                ...state.comments,
                fetch: {
                    isFetchingMore: false,
                    isLoading: false,
                    list: {
                        comments: [...state.comments.fetch.list.comments, ...payload.payload.comments],
                        hasMore: payload.payload.hasMore,
                        next: payload.payload.next,
                    },
                    isError: {status: false, message: ""},
                },
            }
        },
        [doGetMoreComments.rejected]: (state) => {
            state.comments = {
                ...state.comments,
                fetch: {
                    isFetchingMore: false,
                    isLoading: false,
                    list: {comments: []},
                    isError: {status: true, message: "Sorry some error occurred. Reload the page to try again."},
                },
            }
        },


        //like a comment (on popup)
        [doLikeUnLikeCommentPopup.pending]: (state, action) => {
            const {id, index, path, action: actionType} = action.meta.arg
            const fullPath = `${path}[${index}]`;
            const comment = _.get(state.comments.fetch.list, fullPath)
            comment.isLiked = (actionType === "like")
            comment.likes = (actionType === "like") ? comment.likes + 1 : comment.likes - 1
            _.set(state.comments.fetch.list, fullPath, comment)
        },
        [doLikeUnLikeCommentPopup.fulfilled]: (state, {payload}) => {
        },
        [doLikeUnLikeCommentPopup.rejected]: (state, action) => {
            const {id, index, path, action: actionType} = action.meta.arg
            const fullPath = `${path}[${index}]`;
            const comment = _.get(state.comments.fetch.list, fullPath)
            comment.isLiked = !(actionType === "like")
            comment.likes = (actionType === "like") ? comment.likes - 1 : comment.likes + 1
            _.set(state.comments.fetch.list, fullPath, comment)
        },


        //do post create new comment
        [doPostCommentPopUp.pending]: (state, action) => {
            // state.comments.create.isLoading = true;
            // state.comments.create.isSuccess = false;
            // state.comments.create.isError.status = false;
        },
        [doPostCommentPopUp.fulfilled]: (state, action) => {
            // const currentSlug = action.meta.arg.slug;
            // state.comments.create.text = "";
            // state.data.posts = state.data.posts.map(item => {
            //     if (item.slug === currentSlug) {
            //         item.comments.push(action.payload.payload)
            //         return item
            //     }
            //     return item
            // })
            // state.comments.create.isLoading = false;
            // state.comments.create.isSuccess = true;
            const {path, id} = action.meta.arg;
            if (id) {
                const comment = _.get(state.comments.fetch.list, path)
                if (comment.reply) {
                    comment.reply.push(action.payload.payload)
                }else {
                    comment.reply = []
                    comment.reply.push(action.payload.payload)
                }
                _.set(state.comments.fetch.list, path, comment)
            } else {
                const comment = _.get(state.comments.fetch.list, path)
                comment.unshift(action.payload.payload)
                _.set(state.comments.fetch.list, path, comment)
            }
        },
        [doPostCommentPopUp.rejected]: (state, action) => {
            // state.comments.create.isLoading = false;
            // state.comments.create.isError.status = true;
            // state.comments.create.isSuccess = false;
        },
    }
})

export default voiceSlice.reducer;

export const {
    doResetCreateErrors,
    doResetImageList,
    doSetImageError,
    doShowSuccessNotification,
    doToggleLoginPopup,
    doSetCommentText
} = voiceSlice.actions;

export {
    doGetPosts,
    doReGetPosts,
    doCreatePosts,
    doUploadImage,
    doLikeUnLikePost,
    doGetComments,
    doPostComment,
    doLikeUnLikeComment,
    doLikeUnLikeCommentPopup,
    doPostCommentPopUp,
    doGetMoreComments,
};