import routes from "../constants/routes";
import {useSelector} from "react-redux";
import React, {Fragment} from "react";
import {Menu, Transition} from "@headlessui/react";

export default function UserMenu({news}) {

    const {data, isLoading} = useSelector(state => state.user);

    const regex = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');

    //isMounted - to hide "signup/sign" text from being briefly shown before spinner
    // useEffect(() => {
    //     setIsMounted(true)
    // }, []);

    const userMenuItems = [
        {name: "Dashboard", link: `//dash.${routes.siteUrl}`},
        {name: "My listings", link: `//dash.${routes.siteUrl}/organization`},
        {name: "Profile", link: `//dash.${routes.siteUrl}/profile/${data?.user?.uuid}`},
        {name: "Logout", link: `//account.${routes.siteUrl}/signout`},
    ]

    if (isLoading) return <div><img src="/assets/round-loader.svg" alt="" className="w-5"/></div>;

    if (!isLoading && data?.user) {
        let initials = [...data?.user?.displayName.matchAll(regex)] || [];
        initials = ((initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')).toUpperCase();
        return (
            <Menu as="div" className="relative text-left">
                <Menu.Button className="cursor-pointer flex justify-center items-center">
                    {news
                        ? <span className="rounded-full bg-white text-gray-400 p-2.5 block hover:bg-gray-300"
                                title="User menu">
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" viewBox="0 0 19.333 19.333">
                                <g id="Group_465" data-name="Group 465">
                                    <path id="Path_545" data-name="Path 545" fill="currentColor"
                                          d="M16.5,12.5a9.629,9.629,0,0,0-3.673-2.3,5.588,5.588,0,1,0-6.324,0A9.682,9.682,0,0,0,0,19.333H1.51a8.156,8.156,0,0,1,16.312,0h1.51A9.6,9.6,0,0,0,16.5,12.5ZM9.667,9.667a4.078,4.078,0,1,1,4.078-4.078A4.083,4.083,0,0,1,9.667,9.667Z"/>
                                </g>
                            </svg>
                        </span>
                        : <div className="p-0.5 rounded-full flex space-x-2 justify-center items-center bg-gray-100 hover:bg-gray-200 transition-colors">
                            <div className="w-9 h-9 rounded-full flex justify-center items-center">
                                {data?.user?.image
                                    ? <img src={data?.user?.image?.path} alt=""
                                           className="w-9 h-9 rounded-full border-2 border-gray-200" width={36}
                                           height={36}/>
                                    : <span
                                        className="w-9 h-9 rounded-full border-2 border-gray-200 bg-red-500 text-white block text-sm flex items-center justify-center">{initials}</span>
                                }
                            </div>
                            <span className="whitespace-nowrap pr-2">{data?.user?.displayName}</span>
                        </div>
                    }
                </Menu.Button>
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items
                        className="absolute right-0 w-44 z-20 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-light ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-2">
                            {userMenuItems.map((current, index) =>
                                <span key={current.name}>
                                        {index === 3 && <span className="border-gray-200 border-t w-full block my-1"/>}
                                    <Menu.Item key={current.name}>
                                        {({active}) => (
                                            <a href={current.link}
                                               className={`${active && 'bg-gray-100'} ${index === 3 && "justify-center font-medium"}  w-full text-gray-900 block group flex items-center w-full px-4 py-2 text-base`}>
                                                {current.name}
                                            </a>
                                        )}
                                    </Menu.Item>
                                    </span>
                            )
                            }
                        </div>
                    </Menu.Items>
                </Transition>
            </Menu>
        )
    }

    return (
        <>
            {news
                ? <a href={`//account.${process.env.REACT_APP_BASE_DOMAIN}/signin?goto=${window.location.href}`}
                     className="rounded-full bg-bColor text-white">
                    <span className="rounded-full bg-white text-gray-400 p-2.5 block hover:bg-gray-300"
                          title="Login">
                        <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" viewBox="0 0 19.333 19.333">
                            <g id="Group_465" data-name="Group 465">
                                <path id="Path_545" data-name="Path 545" fill="currentColor"
                                      d="M16.5,12.5a9.629,9.629,0,0,0-3.673-2.3,5.588,5.588,0,1,0-6.324,0A9.682,9.682,0,0,0,0,19.333H1.51a8.156,8.156,0,0,1,16.312,0h1.51A9.6,9.6,0,0,0,16.5,12.5ZM9.667,9.667a4.078,4.078,0,1,1,4.078-4.078A4.083,4.083,0,0,1,9.667,9.667Z"/>
                            </g>
                        </svg>
                </span>
                </a>
                : <a href={`//account.${process.env.REACT_APP_BASE_DOMAIN}/signin?goto=${window.location.href}`}
                     className="rounded-full bg-bColor text-white py-1.5 px-6">
                    Login
                </a>
            }
        </>
    )
}