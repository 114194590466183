import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {createRequest} from "../network/api";
import {baseUrl} from "../constants/endpoints";


export const newsApi = createApi({
    reducerPath: "newsApi",
    baseQuery: fetchBaseQuery({baseUrl}),
    endpoints: (builder) => ({
        getNewsList: builder.query({
            query: (filter) => {
                const {start_date, end_date, q, category, today} = filter;
                if (today) return createRequest(`/news?category=${category || ""}&${start_date ? "start_date=" + start_date : ""}&${end_date ? "end_date=" + end_date : ""}&${q ? "q=" + q : ""}`);
                return createRequest(`/news?category=${category || ""}&${start_date ? "end_date=" + start_date : ""}&${q ? "q=" + q : ""}`)
            }
        }),
        getRecentNewsList: builder.query({
            query: () => createRequest(`/news`)
        }),
        getSearchNews: builder.query({
            query: (filter) => createRequest(`/news?category=${filter.category || ""}&q=${filter.keyword}`)
        }),
        getTodaysOldNewsList: builder.query({
            async queryFn(filter, _queryApi, _extraOptions, fetchWithBQ) {
                const {start_date, end_date, q, category} = filter;
                const t = fetchWithBQ(createRequest(`/news?category=${category || ""}&${start_date ? "start_date=" + start_date : ""}&${end_date ? "end_date=" + end_date : ""}&${q ? "q=" + q : ""}`))
                const o = fetchWithBQ(createRequest(`/news?category=${category || ""}&${start_date ? "end_date=" + start_date : ""}&${q ? "q=" + q : ""}`))
                const old = await o;
                const todays = await t;
                return {
                    data: {
                        todays: todays.data ? {data: todays.data} : {error: todays.error},
                        old: old.data ? {data: old.data} : {error: old.error}
                    }
                }
            }
        }),
        getNewsCategories: builder.query({
            query: () => createRequest(`/news-category`),
            // query: (featured) => createRequest(featured ? `/news-category?featured` : "/news-category")
        }),
        getNewsBySlug: builder.query({
            query: (slug) => createRequest(`/news/${slug}`)
        }),
    }),
})

export const {
    useGetRecentNewsListQuery,
    useGetNewsListQuery,
    useGetNewsBySlugQuery,
    useGetNewsCategoriesQuery,
    useGetTodaysOldNewsListQuery,
    useGetSearchNewsQuery
} = newsApi;
