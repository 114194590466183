import HomeHeader from "../components/HomeHeader";
import MainFooter from "../components/MainFooter";
import {Helmet} from "react-helmet-async";
import routes from "../constants/routes";
import React from "react";

export default function PrivacyPolicy() {
    return (
        <>
            <Helmet>
                <meta property="og:title" content="Privacy Policy | Find All Nepal"/>
                <link rel="canonical" href={`https://findallnepal.com${routes.privacy}`}/>
                <title>Privacy Policy | Find All Nepal</title>
            </Helmet>
            <div className="flex-grow">
                <HomeHeader/>
                <div className="container max-w-1000 mx-auto my-16 space-y-10">
                    <div className="relative inline-block">
                        <h1 className="font-medium text-3xl relative">
                            Find All Nepal Privacy Policy
                        </h1>
                        <span className="border-b-3 border-bColor absolute -bottom-4 w-32"/>
                    </div>
                    <main>
                        <h2 className="text-xl mt-16">
                            This is the privacy policy of Find All Nepal and is suitable to all the clients who intend
                            to
                            use any of our services.
                        </h2>
                        <div className="mt-8 space-y-4 text-sm">
                            <p>
                                In the course of registering from our company “Find All Nepal” and meet its services
                                mentioned on the website findallnepal.com, you (i.e. the User) may be asked to release
                                personal information like name, job title, workplace address, e-mail address, date of
                                birth, residence address etc. This information is used to personalize your experience
                                with us by fulfilling your requests for certain services, informing you about our
                                services and customizing the content that you see online on our website.
                            </p>
                            <p>In order to prevent unauthorized sharing of your personal facts, we have suitable
                                technical team, data analyzer, data approver and managerial processes in our company. We
                                are committed to protecting the confidentiality of all your personal information that
                                you may share with us.Your public or private data would be used for market research,
                                business development and strategic client analysis. It shall be expected agreeable that
                                you have given them the permit for this. It may be shared with our advertisers who can
                                call you for a query about a product or service that you are taking from us or other
                                such organizations related reasons, if required.
                            </p>
                            <p>
                                Infrequently, at our responsibility, your personal data may be liberated to the third
                                parties who are working on behalf of Find All Nepal for propagating our business
                                interests and concerns. Clients/Advertiser are allowed to provide details about their
                                products, services and personnel information on our website. This information can be
                                perceived on our website by the targeted public and may not be removed by us, so
                                whatever information the Clients/Advertisers post on the website will be at their own
                                risk.
                            </p>
                            <p>
                                Find All Nepal may transfer the content including your personal data to its database or
                                share it with its trusted organizations or governmental agencies whenever we believe it
                                is convenient, to acquiesce with the law, protect your vital interest or fulfill
                                requirements of administration process. This is done as a carefully measure to maintain
                                security and integrity of the website against legal liabilities, or in the event of
                                tie-up and acquisition. Our customers or clients can post opinion, ideas, views and any
                                relevant links on our site. These posts may carry link of third-party sites too, but the
                                third-party sites can have independent privacy policies. We are not obligated how they
                                use or record personal data and therefore Find All Nepal will not be accountable.
                            </p>
                            <p>
                                Information collected through our website will be retained with us as long as we feel is
                                necessary for our business or to forbid any legal or ethical issues. By visiting Find
                                All Nepal and exposing your personal facts on the site, you acknowledge and give us your
                                permission that your information, collected for reasons identified in our policy, can be
                                transmitted to servers outside your resident jurisdiction. This is due to the global
                                nature of business of our website. Users permit us to store information on servers
                                located in different places.
                            </p>
                            <p>
                                Users must be agreed that they are showing personal data on our website voluntarily to
                                suffice of our services. They are not permitted to disclose or withhold personal
                                information too. However, if this practice hampers the completion of registration
                                process, it is possible that the entire process of registration would be incomplete and
                                assumed impractical. In cases where we have entered intoa contract of confidentiality
                                with our corporate clients, this policy shall not affect that contract in any manner.
                            </p>
                            <p>This Privacy Policy may be changed by Find All Nepal periodically without noticing
                                client/ advertiser so you will be responsible for checking these improvements. All
                                modified terms will become effective upon our posting to the Website, and any use of the
                                Website after such improvements have been posted will signify your consent to these
                                changes.
                            </p>
                            <p>If you have any queries or concerns about this privacy policy, please send us an e-mail
                                at <a href="mailto:info@findallnepal.com"><strong>info@findallnepal.com</strong></a>.
                            </p>
                        </div>
                    </main>
                </div>
            </div>
            <MainFooter/>
        </>
    )
}