import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {baseUrl} from "../constants/endpoints";
import axios from "axios";

const initialState = {
    data: {},
    isLoading: true,
    isError: false,
}

const doGetProfile = createAsyncThunk(
    "user/doGetProfile",
    async (data = null, thunkAPI) => {
        try {
            const response = await axios.get(`${baseUrl}/profile`, {withCredentials: true});
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(JSON.stringify(error))
        }
    }
)

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {},
    extraReducers: {
        [doGetProfile.pending]: state => {
            state.isLoading = true;
        },
        [doGetProfile.fulfilled]: (state, {payload}) => {
            state.isLoading = false;
            state.data = payload;
        },
        [doGetProfile.rejected]: (state, action) => {
            state.isLoading = false;
            state.isError = true;
        },
    }
})

export default userSlice.reducer;

export {doGetProfile};