import React, {Fragment, useEffect, useRef, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {Menu, Transition, Dialog} from "@headlessui/react";
import {useGetNewsCategoriesQuery} from "../services/newsApi";
import {useQuery} from "../others/useQuery";
import UserMenu from "./UserMenu";


export default function NewsNewHeader() {

    const param = useParams();
    const query = useQuery();
    const navigate = useNavigate();
    const searchInputRef = useRef(null);

    const {data: catList, isFetching: isFetchingCatList} = useGetNewsCategoriesQuery(null);

    const [showSearch, setShowSearch] = useState(false);
    const [selectedCat, setSelectedCat] = useState(param.category || query.get("category") || "");
    const handleShowSearch = e => {
        setShowSearch(prevState => !prevState)
    }

    useEffect(() => {
        setSelectedCat(param.category || query.get("category") || "")
    }, [param.category, query])

    const handleSearch = (event) => {
        event.preventDefault();
        navigate(`/search?category=${selectedCat}&q=${searchInputRef.current.value}`)
        handleShowSearch();
    }

    return (
        <header className="max-w-1170 mx-auto text-white py-3 flex justify-between">
            <Link className="flex block items-center" to="/">
                <img src="/logo.svg" alt="" className="w-12 mr-4"/>
                <h1 className="text-2xl">Findall News</h1>
            </Link>
            {(!isFetchingCatList && catList) &&
                <div className="flex justify-center items-center">
                    <nav className="space-x-4">
                        <Link to="/">Home</Link>
                        {catList.category.filter(item => item.isFeatured).map(current =>
                            <Link key={current.id} to={`/category/${current.slug}`}>{current.title}</Link>)
                        }
                    </nav>
                    <div className="flex ml-4 space-x-2 items-center">
                        <Menu as="div" className="relative text-left" title="Show more categories">
                            <Menu.Button className="rounded-full bg-white text-gray-400 p-2.5 block hover:bg-gray-300">
                                <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" viewBox="0 0 16.124 10.121">
                                    <g id="read_x5F_more">
                                        <g id="Group_459" data-name="Group 459">
                                            <path id="Path_529" data-name="Path 529"
                                                  d="M16.124,2.016H0V0H16.124Z"
                                                  fill="currentColor"/>
                                            <path id="Path_530" data-name="Path 530"
                                                  d="M10.078,10.016H0V8H10.078Z"
                                                  transform="translate(0 -3.969)" fill="currentColor"/>
                                            <path id="Path_531" data-name="Path 531"
                                                  d="M16.124,18.1H0V16.086H16.124Z"
                                                  transform="translate(0 -7.981)" fill="currentColor"/>
                                        </g>
                                    </g>
                                </svg>
                            </Menu.Button>
                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                            >
                                <Menu.Items
                                    className="absolute right-0 w-52 z-10 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    <div className="mx-1 py-1">
                                        {catList.category.filter(item => !item.isFeatured).map(current =>
                                            <Menu.Item key={current.id}>
                                                {({active}) => (
                                                    <Link to={`/category/${current.slug}`}
                                                          className={`${active && 'bg-gray-100'} text-gray-900 block group flex rounded-md items-center w-full px-2 py-2 text-sm`}>
                                                        {current.title}
                                                    </Link>

                                                )}
                                            </Menu.Item>
                                        )
                                        }
                                    </div>
                                </Menu.Items>
                            </Transition>
                        </Menu>
                        {/*search dialog*/}
                        <button className="rounded-full bg-white text-gray-400 p-2.5 block hover:bg-gray-300"
                                onClick={handleShowSearch} type="button" title="Search news">
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5"
                                 viewBox="0 0 16.091 16.054">
                                <path id="search"
                                      d="M1.957,2.3a6.693,6.693,0,0,1,10.585,7.979L15.561,13.3A1.813,1.813,0,1,1,13,15.86L9.991,12.855A6.694,6.694,0,0,1,1.957,2.3Zm1.185,8.28a5.017,5.017,0,1,0,0-7.095A4.984,4.984,0,0,0,3.142,10.578Z"
                                      transform="translate(0 -0.338)" fill="currentColor"/>
                            </svg>
                        </button>
                        <Transition appear show={showSearch} as={Fragment}>
                            <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={setShowSearch}>
                                <div className="min-h-screen px-4">
                                    <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0"
                                                      enterTo="opacity-100" leave="ease-in duration-200"
                                                      leaveFrom="opacity-100" leaveTo="opacity-0"
                                    >
                                        <Dialog.Overlay className="fixed inset-0 bg-black opacity-90"/>
                                    </Transition.Child>
                                    {/* This element is to trick the browser into centering the modal contents. */}
                                    {/*<span className="inline-block h-screen align-middle" aria-hidden="true">*/}
                                    {/*    &#8203;*/}
                                    {/*</span>*/}
                                    <Transition.Child as={Fragment} enter="ease-out duration-300"
                                                      enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100"
                                                      leave="ease-in duration-200" leaveFrom="opacity-100 scale-100"
                                                      leaveTo="opacity-0 scale-95"
                                    >
                                        <div className="w-full mx-auto max-w-1170 my-8 overflow-hidden transform">
                                            <div className="mt-6">
                                                <form onSubmit={handleSearch}
                                                      className="flex justify-center items-center bg-white pl-6 py-1 rounded-full"
                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                         className="w-7 h-7 mr-4 text-gray-300"
                                                         viewBox="0 0 16.091 16.054">
                                                        <path id="search"
                                                              d="M1.957,2.3a6.693,6.693,0,0,1,10.585,7.979L15.561,13.3A1.813,1.813,0,1,1,13,15.86L9.991,12.855A6.694,6.694,0,0,1,1.957,2.3Zm1.185,8.28a5.017,5.017,0,1,0,0-7.095A4.984,4.984,0,0,0,3.142,10.578Z"
                                                              transform="translate(0 -0.338)" fill="currentColor"/>
                                                    </svg>
                                                    <input type="text" ref={searchInputRef}
                                                           className="text-black flex-1 outline-none placeholder-gray-200"
                                                           placeholder="Search news"/>
                                                    <button type="submit"
                                                            className="bg-gray-200 block px-6 py-3 mx-1 text-xl h-full rounded-3xl">
                                                        Search
                                                    </button>
                                                </form>
                                            </div>
                                            <ul className="mt-12 space-y-4 text-xl columns-4 gap-4 text-white">
                                                <li>
                                                    <button onClick={(() => setSelectedCat(""))}
                                                            className={`rounded-full px-2 py-0.5 ${selectedCat === "" ? "bg-blue-500" : ""}`}>
                                                        <span className="align-middle">All Categories</span>
                                                    </button>
                                                </li>
                                                {catList.category?.map(item => {
                                                    return <li key={item.id}>
                                                        <button onClick={(() => setSelectedCat(item.slug))}
                                                                title={`Search this category`}
                                                                className={`rounded-full px-3 align-middle ${selectedCat === item.slug ? "bg-blue-500" : ""}`}>
                                                            <span className="align-middle">{item.title}</span>
                                                        </button>
                                                    </li>
                                                })}
                                            </ul>
                                        </div>
                                    </Transition.Child>
                                </div>
                            </Dialog>
                        </Transition>
                        <UserMenu news/>
                    </div>
                </div>
            }
        </header>
    )
}