import ShopSecondaryHeader from "../../components/shop/ShopSecondaryHeader";
import StyledInput from "../../components/shop/StyledInput";
import StyledSelect from "../../components/shop/StyledSelect";
import StyledButton from "../../components/shop/StyledButton";
import {Link} from "react-router-dom";
import routes from "../../constants/routes";

export default function CheckoutInformation() {

    const handleSubmit = event => {
        event.preventDefault();
    }

    return (
        <div className="max-w-screen-xl mx-auto">
            <ShopSecondaryHeader/>
            <h2 className="mb-4 text-2xl">Checkout Information</h2>
            <div className="flex">
                <form onSubmit={handleSubmit} className="flex-grow mr-4">
                    <div className="rounded-xl border-2 border-gray-100 p-8">
                        <div className="grid grid-cols-2 gap-8">
                            <div className="space-y-5">
                                <StyledInput label="Full Name" name="_full-name" placeholder="Enter your full name"/>
                                <StyledInput label="Phone Number" name="_full-name" placeholder="Enter your phone number"/>
                            </div>
                            <div className="space-y-5">
                                <StyledSelect label="Region" name="_region" placeholder="Select your region"/>
                                <StyledSelect label="City" name="_city" placeholder="Select your city"/>
                                <StyledSelect label="Area" name="_area" placeholder="Select your nearby area"/>
                                <StyledInput label="Address" name="_address"
                                             placeholder="Local Address: Ex, House 123, Street"/>
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-end mt-4"><StyledButton text="SAVE" type="submit"/></div>
                </form>
                <div className="rounded-xl border-2 border-gray-100 p-5 w-96">
                    <p className="text-xl font-bold mb-3">Order Total Items</p>
                    <div className="divide-y border-b border-black pb-6">
                        <article className="flex py-2 pr-8 items-start">
                            <img src="/assets/images/product_image.png" className="w-20" alt=""/>
                            <div className="ml-6 text-sm">
                                <p>Black Color Hoodies For Men</p>
                                <span className="font-bold text-bColor">Rs. 2200</span>
                                <p className="mt-2">Qty: <span>1</span></p>
                            </div>
                        </article>
                        <article className="flex py-2 pr-8 items-start">
                            <img src="/assets/images/product_image.png" className="w-20" alt=""/>
                            <div className="ml-6 text-sm">
                                <p>Black Color Hoodies For Men</p>
                                <span className="font-bold text-bColor">Rs. 2200</span>
                                <p className="mt-2">Qty: <span>1</span></p>
                            </div>
                        </article>
                        <article className="flex py-2 pr-8 items-start">
                            <img src="/assets/images/product_image.png" className="w-20" alt=""/>
                            <div className="ml-6 text-sm">
                                <p>Black Color Hoodies For Men</p>
                                <span className="font-bold text-bColor">Rs. 2200</span>
                                <p className="mt-2">Qty: <span>1</span></p>
                            </div>
                        </article>
                        <article className="flex py-2 pr-8 items-start">
                            <img src="/assets/images/product_image.png" className="w-20" alt=""/>
                            <div className="ml-6 text-sm">
                                <p>Black Color Hoodies For Men</p>
                                <span className="font-bold text-bColor">Rs. 2200</span>
                                <p className="mt-2">Qty: <span>1</span></p>
                            </div>
                        </article>
                    </div>
                    <span className="block text-xl font-bold my-4">Proceed to pay</span>
                    <span className="block text-lg mb-3">Order Summary</span>
                    <div className="flex justify-between text-sm">
                        <div>
                            <span className="block">Subtotal (5 Items)</span>
                            <span className="block">Delivery Charge</span>
                            <span className="block mt-1 font-bold">Total</span>
                        </div>
                        <div>
                            <span className="block">11,200</span>
                            <span className="block">100</span>
                            <span className="block mt-1 font-bold">11,300</span>
                        </div>
                    </div>
                    <Link to={routes.checkoutPreview}
                          className="rounded-lg py-2 px-8 bg-bColor text-white uppercase mt-6 w-full block text-center">
                        proceed to pay
                    </Link>
                </div>
            </div>
        </div>
    )
}