import React, {useState, useRef} from "react";

export default function Accordion(props) {
    const [setActive, setActiveState] = useState("");
    const [setHeight, setHeightState] = useState("0px");
    const [setRotate, setRotateState] = useState("accordion__icon");

    const content = useRef(null);

    function toggleAccordion() {
        setActiveState(setActive === "" ? "active" : "");
        setHeightState(
            setActive === "active" ? "0px" : `${content.current.scrollHeight}px`
        );
        setRotateState(
            setActive === "active" ? "accordion__icon" : "accordion__icon rotate"
        );
    }

    return (
        <article className="flex flex-col">
            <button className={`bg-gray-100 flex items-center border-0 outline-none px-8 py-6 text-2xl flex justify-between ${setActive}`} onClick={toggleAccordion}>
                <p>{props.title}</p>
                <img src="/assets/images/plus.svg" alt="" className={`${setRotate}`}/>
            </button>
            <div
                ref={content}
                style={{maxHeight: `${setHeight}`}}
                className="accordion__content mt-1 overflow-hidden"
            >
                <div
                    className="bg-gray-100 px-8 py-6 text-lg"
                    dangerouslySetInnerHTML={{__html: props.content}}
                />
            </div>
        </article>
    );
}
