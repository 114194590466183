import ShopSecondaryHeader from "../../components/shop/ShopSecondaryHeader";
import {Link} from "react-router-dom";
import routes from "../../constants/routes";

export default function CheckoutPreview() {

    return (
        <div className="max-w-screen-xl mx-auto">
            <ShopSecondaryHeader/>
            <h2 className="mb-4 text-2xl">Checkout Information Preview</h2>
            <div className="flex">
                <div className="flex-grow mr-4">
                    <div className="rounded-xl border-2 border-gray-100 p-8">
                        <div className="grid grid-cols-2 gap-8">
                            <div className="space-y-5">
                                <div>
                                    <span className="block mb-2">Full Name</span>
                                    <span className="block mb-2 font-bold">Nirajan Rawal</span>
                                </div>
                                <div>
                                    <span className="block mb-2">Contact Number</span>
                                    <span className="block mb-2 font-bold">+977 9802123354</span>
                                </div>
                            </div>
                            <div className="space-y-5">
                                <div>
                                    <span className="block mb-2">Region</span>
                                    <span className="block mb-2 font-bold">Bagmati</span>
                                </div>
                                <div>
                                    <span className="block mb-2">City</span>
                                    <span className="block mb-2 font-bold">Kathmandu</span>
                                </div>
                                <div>
                                    <span className="block mb-2">Area</span>
                                    <span className="block mb-2 font-bold">Koteshwor</span>
                                </div>
                                <div>
                                    <span className="block mb-2">Address</span>
                                    <span className="block mb-2 font-bold">Local Address: Ex, House 123, Street</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rounded-xl border-2 border-gray-100 p-5 w-96">
                    <p className="text-xl font-bold mb-3">Order Total Items</p>
                    <div className="divide-y border-b border-black pb-6">
                        <article className="flex py-2 pr-8 items-start">
                            <img src="/assets/images/product_image.png" className="w-20" alt=""/>
                            <div className="ml-6 text-sm">
                                <p>Black Color Hoodies For Men</p>
                                <span className="font-bold text-bColor">Rs. 2200</span>
                                <p className="mt-2">Qty: <span>1</span></p>
                            </div>
                        </article>
                        <article className="flex py-2 pr-8 items-start">
                            <img src="/assets/images/product_image.png" className="w-20" alt=""/>
                            <div className="ml-6 text-sm">
                                <p>Black Color Hoodies For Men</p>
                                <span className="font-bold text-bColor">Rs. 2200</span>
                                <p className="mt-2">Qty: <span>1</span></p>
                            </div>
                        </article>
                        <article className="flex py-2 pr-8 items-start">
                            <img src="/assets/images/product_image.png" className="w-20" alt=""/>
                            <div className="ml-6 text-sm">
                                <p>Black Color Hoodies For Men</p>
                                <span className="font-bold text-bColor">Rs. 2200</span>
                                <p className="mt-2">Qty: <span>1</span></p>
                            </div>
                        </article>
                        <article className="flex py-2 pr-8 items-start">
                            <img src="/assets/images/product_image.png" className="w-20" alt=""/>
                            <div className="ml-6 text-sm">
                                <p>Black Color Hoodies For Men</p>
                                <span className="font-bold text-bColor">Rs. 2200</span>
                                <p className="mt-2">Qty: <span>1</span></p>
                            </div>
                        </article>
                    </div>
                    <span className="block text-xl font-bold my-4">Proceed to pay</span>
                    <span className="block text-lg mb-3">Order Summary</span>
                    <div className="flex justify-between text-sm">
                        <div>
                            <span className="block">Subtotal (5 Items)</span>
                            <span className="block">Delivery Charge</span>
                            <span className="block mt-1 font-bold">Total</span>
                        </div>
                        <div>
                            <span className="block">11,200</span>
                            <span className="block">100</span>
                            <span className="block mt-1 font-bold">11,300</span>
                        </div>
                    </div>
                    <Link to={routes.payment}
                          className="rounded-lg py-2 px-8 bg-bColor text-white uppercase mt-6 w-full block text-center">
                        proceed to pay
                    </Link>
                </div>
            </div>
        </div>
    )
}