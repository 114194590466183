import "react-responsive-carousel/lib/styles/carousel.min.css"
import {Carousel} from "react-responsive-carousel"

export default function SliderWithAds() {

    const arrowStyles = {
        position: 'absolute',
        zIndex: 20,
        top: 'calc(50% - 15px)',
        width: 30,
        height: 30,
        cursor: 'pointer',
    }

    return (
        <section className="bg-gray-200 mb-4">
            <div className="three-column-slider-grid">
                <div id="for-ads" className="hidden xl:flex justify-center items-center p-4">
                    <p>Your Advertisement</p>
                </div>
                <div className="max-w-3xl m-auto">
                    <Carousel showThumbs={false} showStatus={false} showIndicators={false}
                              swipeable emulateTouch
                              renderArrowPrev={(onClickHandler, hasPrev, label) =>
                                  hasPrev && (
                                      <button type="button" onClick={onClickHandler} title={label}
                                              style={{...arrowStyles, left: 0}}>
                                          <img src="/assets/images/arrow_r.png" alt="featured"/>
                                      </button>
                                  )
                              }
                              renderArrowNext={(onClickHandler, hasNext, label) =>
                                  hasNext && (
                                      <button type="button" onClick={onClickHandler} title={label}
                                              style={{...arrowStyles, right: 0}}>
                                          <img src="/assets/images/arrow_l.png" alt="featured"/>
                                      </button>
                                  )
                              }
                              autoPlay infiniteLoop
                    >
                        <div>
                            <img src="/assets/images/1.png" alt="featured"/>
                        </div>
                        <div>
                            <img src="/assets/images/2.png" alt="featured"/>
                        </div>
                    </Carousel>
                </div>
                <div id="for-ads" className="hidden xl:flex justify-center items-center p-4">
                    <p>Your Advertisement</p>
                </div>
            </div>
        </section>
    )
}