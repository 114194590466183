import {Link} from "react-router-dom";
import {siteLinks} from "../constants/siteLinks";
import {postTypes} from "../pages/voice/VoiceLayout";
import {useSelector} from "react-redux";


export default function VoiceSidebar({setSelectedType, handleShowCreatePost}) {

    const {data, isLoading} = useSelector(state => state.user);

    const handleCreatePost = (type) => {
        setSelectedType(type);
        handleShowCreatePost();
    }

    return (
        <aside className="space-y-3 w-300 sticky top-[4.5rem] pt-4 -mt-4">
            <div className="bg-white space-x-4 p-4 flex justify-between rounded-md">
                <article className="flex items-center flex-col w-1/3">
                    <img src="https://placekitten.com/50/50" alt=""
                         className="pointer-events-none border border-bColor rounded-full w-14 h-14" width={56}
                         height={56}/>
                    <span className="text-sm">Post of the..</span>
                </article>
                <article className="flex items-center flex-col w-1/3">
                    <img src="https://placekitten.com/50/50" alt=""
                         className="pointer-events-none border border-bColor rounded-full w-14 h-14" width={56}
                         height={56}/>
                    <span className="text-sm">Recent Post</span>
                </article>
                <article className="flex items-center flex-col w-1/3">
                    <img src="https://placekitten.com/50/50" alt=""
                         className="pointer-events-noneborder border-bColor rounded-full w-14 h-14" width={56}
                         height={56}/>
                    <span className="text-sm">Top Post</span>
                </article>
            </div>
            {(!isLoading && data?.user) &&
                <div className="bg-white py-6 px-4 rounded-md">
                    <div className="flex items-baseline space-x-4">
                        <img src="/assets/arrow-right.svg" alt="" className="pointer-events-none"/>
                        <div>
                            <span className="text-lg mb-2 block">Create Post</span>
                            <ul className="space-y-2">
                                {postTypes.map(item => {
                                    return <li key={item}>
                                        <button className="flex space-x-2 items-center"
                                                onClick={() => handleCreatePost(item)}>
                                            <img src="/assets/plus.svg" alt=""/>
                                            <span className="capitalize">{item}</span>
                                        </button>
                                    </li>
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            }

            <footer className="text-xs text-gray-500">
                <nav className="space-x-4 leading-7 whitespace-nowrap flex flex-wrap justify-center">
                    <Link to={siteLinks.about}>About</Link>
                    <Link to={siteLinks.career}>Career</Link>
                    <Link to={siteLinks.gallery}>Gallery</Link>
                    <Link to={siteLinks.advertising}>Advertising</Link>
                    <Link to={siteLinks.privacy}>Privacy Policy</Link>
                    <Link to={siteLinks.terms}>Terms</Link>
                </nav>
            </footer>
        </aside>
    );
}