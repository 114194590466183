import {DateTime} from "luxon";

export const units = ['year', 'month', 'week', 'day', 'hour', 'minute', 'second',];

export const timeAgo = (date) => {
    let dateTime = DateTime.fromISO(date)
    const diff = dateTime.diffNow().shiftTo(...units);
    const unit = units.find((unit) => diff.get(unit) !== 0) || 'second';

    const relativeFormatter = new Intl.RelativeTimeFormat('en', {
        numeric: 'auto',
    });
    return relativeFormatter.format(Math.trunc(diff.as(unit)), unit);
};

let startDate, endDate = null;

export function getStartEndDates(type, refresh = false) {
    const currentTime = DateTime.now();
    if (!startDate || refresh) {
        startDate = currentTime.minus({hour: 24}).toFormat("yyyy-LL-dd HH:mm:ss");
        endDate = currentTime.toFormat("yyyy-LL-dd HH:mm:ss");
        return type === "start" ? startDate : endDate;
    }
    return type === "start" ? startDate : endDate;
}
