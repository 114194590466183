import {useParams} from "react-router-dom";
import {useGetOrgTeamsQuery} from "../services/directoryApi";
import PageTransitionLoader from "../components/PageTransitionLoader";
import {Helmet} from "react-helmet-async";

export default function GenericOrganizationTeams({data}) {

    const param = useParams();

    const {isFetching, data: teamData} = useGetOrgTeamsQuery(param.slug);

    if (isFetching) return (
        <TeamLayout data={data}>
            <div className="flex justify-center items-center flex-col h-72 bg-bColor-50">
                <PageTransitionLoader size={28}/>
                <span className="text-sm">LOADING</span>
            </div>
        </TeamLayout>
    )

    if (!isFetching && teamData.organizationWithTeam) {
        return (
            <TeamLayout data={data}>
                <section id="teams">
                    <div className="mx-auto max-w-1000 mb-16">
                        {teamData?.organizationWithTeam.teams.map(current => {
                            console.log("sdsa")
                            return (
                                <div id={current.title} key={current.title}>
                                    <h1 className="text-center text-4xl font-bold my-16">
                                        {current.title}
                                    </h1>
                                    <div className="grid grid-cols-3 gap-10">
                                        {current.team_member?.map((item, index) => {
                                            return (
                                                <article className="rounded-2xl shadow space-y-6 pb-6" key={index}>
                                                    <img src={item.photo?.path || "/assets/images/user_ph.png"} alt="" width={310} height={310}
                                                         className="rounded-2xl shadow pointer-events-none"/>
                                                    <div className="text-center space-y-1.5">
                                                        <strong className="text-2xl font-bold">{item.name}</strong>
                                                        <span className="block text-gray-400">{item.post}</span>
                                                    </div>
                                                </article>
                                            )
                                        })}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </section>
            </TeamLayout>
        )
    }

    return (
        <TeamLayout data={data}>
            <div className="mx-auto max-w-1000 justify-center items-center flex mt-4"> Nothing to show.</div>
        </TeamLayout>
    )
}

function TeamLayout({data, children}) {
    return(
        <>
            <Helmet>
                <meta property="og:title" content={`Teams | ${data.name}`}/>
                <meta property="og:url" content={`https://findallnepal.com/${data.slug}/teams`}/>
                <link rel="canonical" href={`https://findallnepal.com/${data.slug}/teams`}/>
                <title>{`Teams | ${data.name}`}</title>
            </Helmet>
        <main>
            <section id="hero">
                <div className="bg-gray-400 w-full h-64 relative">
                    <div className="bg-transparent w-full h-64 bg-center bg-no-repeat filter brightness-50"
                         style={{backgroundImage: `url(${data.featuredImage?.path})` || ""}}/>
                    <h1 className="text-center text-4xl font-bold text-white absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2">
                        Our Team
                    </h1>
                </div>
            </section>
            {children}
        </main>
        </>
    )
}
