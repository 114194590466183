import {Dialog, Transition} from "@headlessui/react";
import {Fragment} from "react";
import {externalLinks} from "../constants/externalLinks";

export default function DirectionDialog({handleDirectionDialog, isOpen}) {
    return (
        <>
            {/*<div className="fixed inset-0 flex items-center justify-center">*/}
            {/*    <button*/}
            {/*        type="button"*/}
            {/*        onClick={handleDirectionDialog}*/}
            {/*        className="px-4 py-2 text-sm font-medium text-white bg-black rounded-md bg-opacity-20 hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"*/}
            {/*    >*/}
            {/*        Open dialog*/}
            {/*    </button>*/}
            {/*</div>*/}

            <Transition appear show={isOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed inset-0 z-10 overflow-y-auto"
                    onClose={handleDirectionDialog}
                >
                    <div className="min-h-screen px-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-80"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-80"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 opacity-20"/>
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span className="inline-block h-screen align-middle" aria-hidden="true">
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div
                                className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                                <Dialog.Title
                                    as="h3"
                                    className="text-lg font-medium leading-6 text-gray-900"
                                >
                                    Direction not available
                                </Dialog.Title>
                                <div className="mt-4 text-sm text-gray-500">
                                    <p>
                                        <strong>Get Direction</strong> feature is currently not available on web
                                        version, please use the mobile app instead to get the direction.
                                    </p>
                                    <div>
                                        <span className="mt-4 mb-2 block">Get the App</span>
                                        <div className="flex space-x-2">
                                            <a href={externalLinks.appGoolge} target="_blank" rel="noreferrer">
                                                <img src="/assets/images/playstore.svg" alt=""/>
                                            </a>
                                            <a href={externalLinks.appApple} target="_blank" rel="noreferrer">
                                                <img src="/assets/images/appstore.svg" alt=""/>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-4">
                                    <button
                                        type="button"
                                        className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                                        onClick={handleDirectionDialog}
                                    >
                                        Got it, thanks!
                                    </button>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}