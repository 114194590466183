import {Link, useParams} from "react-router-dom";
import routes from "../constants/routes";
import {Carousel} from "react-responsive-carousel";
import MainFooter from "../components/MainFooter";
import {useEffect, useState} from "react";
import {
    useGetAllStateListQuery,
    useGetDirectoryListQuery,
    useGetDistrictListQuery,
    useGetNagarListQuery
} from "../services/directoryApi";
import ThreeDotsLoader from "../components/ThreeDotsLoader";
import clsx from "clsx";
import {externalLinks} from "../constants/externalLinks";
import UserMenu from "../components/UserMenu";
import SearchbarOnly2 from "../components/SearchbarOnly2";
import {useQuery} from "../others/useQuery";
import {Helmet} from "react-helmet-async";

export default function SearchByType() {

    const param = useParams();
    const query = useQuery();

    const [filter, setFilter] = useState({type: param.category, q: query.get("q") || "", places: ""});

    const {data: orgList, isFetching} = useGetDirectoryListQuery(filter);
    const [filteredOrgList, setFilteredOrgList] = useState([]);

    const [selectedProvince, setSelectedProvince] = useState(null);
    const [selectedDistrict, setSelectedDistrict] = useState(null);
    const [selectedNagar, setSelectedNagar] = useState(null);

    const {data: stateList, isFetching: isFetchingState} = useGetAllStateListQuery();
    const {data: districtList, isFetching: isFetchingDistrict} = useGetDistrictListQuery(selectedProvince);
    const {data: nagarList, isFetching: isFetchingNagar} = useGetNagarListQuery(selectedDistrict);


    // useEffect(() => {
    //     setSelectedDistrict(null)
    // }, [selectedProvince])


    useEffect(() => {
        setFilteredOrgList(orgList)
    }, [orgList])

    useEffect(() => {
        setFilter({...filter, q: query.get("q")})
    }, [query.get("q")])

    const arrowStyles = {
        position: 'absolute',
        zIndex: 20,
        top: 'calc(50% - 15px)',
        width: 30,
        height: 30,
        cursor: 'pointer',
    }

    const handleFilter = (address) => {
        if (address?.type === "state") {
            setSelectedProvince(address.value)
            setSelectedDistrict(null)
            setSelectedNagar(null)
        }
        if (address?.type === "district") {
            setSelectedDistrict(address.value)
            setSelectedNagar(null)
        }
        if (address?.type === "nagar") setSelectedNagar(address?.value)
        // console.log(btoa(JSON.stringify(address)))
        if (!address) {
            setSelectedProvince(null)
            setSelectedDistrict(null)
        }
        setFilter({...filter, places: address && btoa(JSON.stringify(address))})
        // console.log(btoa(JSON.stringify("")))
    }

    return (
        <>
            <Helmet>
                <meta property="og:title" content={`Search by ${param.category}`}/>
                <meta property="og:image" content="https://findallnepal.com/find_all.png"/>
                <meta property="og:url" content={`https://findallnepal.com/search/${param.category}`}/>
                <link rel="canonical" href={`https://findallnepal.com/${param.category}`}/>
                <title>{`Search by ${param.category}`}</title>
            </Helmet>
            {/*flex-grow: 1, needed for footer to stick to bottom, done on every page*/}
            <div className="flex-grow">
                <section>
                    <div className="three-column-header-grid px-4 py-4 md:px-8 shadow-custom">
                        <div className="flex items-center">
                            <Link to={routes.home} className="w-14 h-10 mr-4 block">
                                <img src="/logo.svg" alt="find all" className="w-14 h-10 block"/>
                            </Link>
                            <SearchbarOnly2 short placeholder={`search ${param.category}`}/>
                            {/*<input type="text" name="search" placeholder="search colleges/schools"*/}
                            {/*       className="rounded-full px-4 py-2 bg-gray-200"/>*/}
                        </div>
                        <div className="flex justify-between">
                            <div className="space-x-4 font-bold">
                            </div>
                            <div>
                                <span className="mr-4 text-sm">Get Our App</span>
                                <a href={externalLinks.appGoolge} title="Get our app from PlayStore">
                                    <img src="/assets/images/playstore.svg" className="inline" alt=""/> </a>
                                <span className="mx-2">|</span>
                                <a href={externalLinks.appApple} title="Get our app from Appstore">
                                    <img src="/assets/images/appstore.svg" className="inline" alt=""/></a>
                            </div>
                        </div>
                        <div className="justify-self-end">
                            <UserMenu/>
                        </div>
                    </div>
                </section>
                <section className="bg-gray-200 mb-4">
                    <div className="m-auto">
                        <Carousel showThumbs={false} showStatus={false} showIndicators={false}
                                  swipeable emulateTouch
                                  renderArrowPrev={(onClickHandler, hasPrev, label) =>
                                      hasPrev && (
                                          <button type="button" onClick={onClickHandler} title={label}
                                                  style={{...arrowStyles, left: 15}}>
                                              <img src="/assets/images/arrow_r.png" alt="featured"/>
                                          </button>
                                      )
                                  }
                                  renderArrowNext={(onClickHandler, hasNext, label) =>
                                      hasNext && (
                                          <button type="button" onClick={onClickHandler} title={label}
                                                  style={{...arrowStyles, right: 15}}>
                                              <img src="/assets/images/arrow_l.png" alt="featured"/>
                                          </button>
                                      )
                                  }
                                  autoPlay infiniteLoop
                        >
                            <div>
                                <img src={`/assets/images/slider/${param.category}.png`} alt="" height={256} className="h-64"/>
                            </div>
                            <div>
                                <img src={`/assets/images/slider/${param.category}.png`} alt="" height={256} className="h-64"/>
                            </div>
                        </Carousel>
                    </div>
                </section>
                <section className="max-w-6xl mx-auto w-full">
                    <p className="font-bold">Search Locations Near by you</p>
                    <div className="bg-gray-100 space-x-8 px-8 py-4 mt-4 text-gray-500">
                        <button className="font-bold relative" onClick={() => handleFilter(null)}>
                            Show All
                            {selectedProvince === null &&
                            <div className={"w-8 border-b-3 border-bColor absolute -bottom-1"}/>
                            }
                        </button>
                        {!isFetchingState
                            ? stateList?.address.map(item => (
                                <button className="relative font-bold"
                                        key={item.slug}
                                        onClick={() => handleFilter(item)}>
                                    {item?.value}
                                    {selectedProvince === item.value &&
                                    <div className={"w-8 border-b-3 border-bColor absolute -bottom-1"}/>
                                    }
                                </button>
                            ))
                            : <ThreeDotsLoader size={6}/>
                        }
                    </div>
                    <div className="mt-4 space-y-4">
                        {selectedProvince &&
                        <div className="flex items-start">
                            <div className="flex items-center mr-4">
                                <img src={`/assets/images/district.svg`} height="24" width="40" alt=""/>
                                <span className="ml-4 block capitalize">district</span>
                            </div>
                            <ul className=" flex flex-wrap items-start justify-center">
                                {(districtList && !isFetchingDistrict)
                                    ? districtList.address.map(item =>
                                        <li key={item.slug}
                                            className={clsx("mb-2 mr-2 px-4 py-2 rounded-full font-medium cursor-pointer",
                                                selectedDistrict === item.value ? "bg-gray-700 text-white" : "bg-gray-200"
                                            )}
                                            onClick={() => handleFilter(item)}>
                                            {/*<button className="mb-2 px-4 py-2 bg-gray-200 rounded-full font-medium"*/}
                                            {/*        onClick={() => handleFilter(item)}>*/}
                                            {item.value}
                                            {/*</button>*/}
                                        </li>
                                    )
                                    : <div className="mt-3"><ThreeDotsLoader size={10}/></div>
                                }
                            </ul>
                        </div>
                        }
                        {selectedDistrict &&
                        <div className="flex items-start">
                            <div className="flex items-center mr-4">
                                <img src={`/assets/images/place.svg`} height="24" width="40" alt=""/>
                                <span className="mx-4 capitalize">place</span>
                            </div>
                            <ul className="flex flex-wrap items-start justify-center">
                                {(nagarList && !isFetchingNagar)
                                    ? nagarList.address.map(item =>
                                        <li key={item.slug}
                                            className={clsx("mb-2 mr-2 px-4 py-2 rounded-full font-medium cursor-pointer",
                                                selectedNagar === item.value ? "bg-gray-700 text-white" : "bg-gray-200"
                                            )}
                                            onClick={() => handleFilter(item)}
                                        >
                                            {item.value}
                                        </li>
                                    )
                                    : <div className="mt-3"><ThreeDotsLoader size={10}/></div>
                                }
                            </ul>
                        </div>
                        }
                    </div>
                    {isFetching
                        ? <div className="flex justify-center items-center flex-col mt-8">
                            <img src="/assets/bars-loader.svg" className="w-10" alt=""/>
                            <span className="text-sm text-gray-400 mt-4 block">loading</span>
                        </div>
                        : !filteredOrgList?.organization?.length ? (
                                <div className="flex justify-center w-full">No results.</div>)
                            : <div className="mt-8 products-grid">
                                {filteredOrgList?.organization?.map(current => (
                                    // <Link to={`/organization/${current.slug}`} className="px-8 py-6 block shadow-custom" key={current.slug}>
                                    <Link to={{pathname: `/${current.slug}`}}
                                          className="px-8 py-6 block shadow-custom"
                                          key={current.slug}>
                                        <article className="h-full grid gap-4 text-center font-bold"
                                                 style={{gridTemplateRows: "1fr 25px"}}>
                                            <div
                                                className="flex justify-center items-center flex-col">
                                                <img className="w-24 h-24" width={96} height={96}
                                                     src={current.logo?.path || "/assets/images/no-image.png"}
                                                     alt="logo"/>
                                                <span className="mt-4 block flex-grow block">{
                                                    current.name.length > 50
                                                        ? current.name.slice(0, 43) + "..."
                                                        : current.name

                                                }</span>
                                            </div>
                                            <span className="block text-bColor">View Details</span>
                                        </article>
                                    </Link>
                                    // </Link>
                                ))
                                }
                            </div>
                    }
                </section>
            </div>
            <MainFooter/>
        </>
    )
}