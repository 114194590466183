import {useGetSearchNewsQuery} from "../services/newsApi";
import {useQuery} from "../others/useQuery";
import NewsArticle from "../components/NewsArticleCard";
import React from "react";
import {Helmet} from "react-helmet-async";

export default function SearchNews() {

    const query = useQuery();

    const {data} = useGetSearchNewsQuery({
        category: query.get("category") || "",
        keyword: query.get("q") || ""
    })

    return (
        <>
            <Helmet>
                <title>{`Results for "${query.get("q") || ""}"`}</title>
            </Helmet>
            <main className="flex-grow">
                <section>
                    <div className="flex items-center">
                        <h1 className="uppercase text-2xl font-light mr-3 my-6">Search Results for
                            "{query.get("q") || ""}"</h1>
                        <div className="h-1.5 border-t-3 border-b border-gray-200 flex-grow"/>
                    </div>
                    <div className="grid grid-cols-2 gap-2">
                        {data?.news?.length > 0
                            ? data?.news?.map(current => (
                                <NewsArticle current={current} key={current.id}/>
                            ))
                            : <div className="max-w-1170 my-12"><p>No news to show.</p></div>
                        }
                    </div>
                </section>
            </main>
        </>
    )
}