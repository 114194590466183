export default function StyledSelect({label, name, placeholder}) {
    return (
        <label className="block">
            <span className="block mb-2">{label}</span>
            <div className="pr-3 border border-gray-300">
                <select className="px-5 py-3 w-full outline-none" name={name} placeholder={placeholder}>
                    <option value="a">Some</option>
                    <option value="b">Some</option>
                    <option value="c">Some</option>
                    <option value="d">Some</option>
                </select>
            </div>
        </label>
    )
}