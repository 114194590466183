import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from "react-redux";
import './index.css';
import App from './App';
// import reportWebVitals from './reportWebVitals';
// import {BrowserRouter} from "react-router-dom";
import SimpleReactLightbox from 'simple-react-lightbox'
import configureStore from "./store/configureStore";
import {HelmetProvider} from "react-helmet-async";

const store = configureStore();

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <SimpleReactLightbox>
                <HelmetProvider>
                    <App/>
                </HelmetProvider>
            </SimpleReactLightbox>
        </Provider>
    </React.StrictMode>,
    document.getElementById('__findAllNepal__')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
