import { useParams} from "react-router-dom";
import {useGetOrgEventSingleQuery} from "../services/directoryApi";
import PageTransitionLoader from "../components/PageTransitionLoader";

export default function GenericOrganizationEventsSingle({data}) {

    const param = useParams();

    const {data: eventData, isFetching} = useGetOrgEventSingleQuery({slug: param.slug, eventSlug: param.eventSlug});
    console.log(param)

    if (isFetching) return (
        <EventLayout data={data}>
            <div className="flex justify-center items-center flex-col h-72 bg-bColor-50">
                <PageTransitionLoader size={28}/>
                <span className="text-sm">LOADING</span>
            </div>
        </EventLayout>
    )

    if (!isFetching && eventData?.organizationWithSingleEvent) {
        return (
            <EventLayout data={data}>
                <section id="single-event">
                    <div className="mx-auto max-w-1000 my-14">
                        {eventData.organizationWithSingleEvent.event.map(current => {
                            return <div className="flex items-center flex-col space-y-12" key={current.name}>
                                <img src={current.featuredImage?.path || "/assets/images/event_thumb2.png"}
                                     alt="" width={300} height={300}
                                     className="rounded-2xl pointer-events-none w-60 h-60"/>
                                <h1 className="font-bold text-3xl">{current.name}</h1>

                                <div dangerouslySetInnerHTML={{__html: current.desc}}/>

                                <div className="flex justify-between space-x-8 items-center font-bold">
                                    <p className="flex space-x-4 items-center"><img
                                        src="/assets/images/event_org.png" alt=""
                                        width={17}/><span>{data.name}</span>
                                    </p>
                                    <p className="flex space-x-4 items-center">
                                        <img src="/assets/images/event_start.png" alt="" width={17}/>
                                        <span>{new Date(current.from).toDateString()}</span>
                                    </p>
                                    <p className="flex space-x-4 items-center">
                                        <img src="/assets/images/event_end.png" alt="" width={17}/>
                                        <span>{new Date(current.to).toDateString()}</span>
                                    </p>
                                    <p className="flex space-x-4 items-center">
                                        <img src="/assets/images/event_location.png" alt="" width={15}/>
                                        <span>{current.location}</span>
                                    </p>
                                </div>
                                <button
                                    className="rounded-full py-3 px-8 font-bold text-bColor bg-bColor-light">Share
                                </button>
                            </div>
                        })}
                    </div>
                </section>
            </EventLayout>
        )
    }

    return (
        <EventLayout data={data}>
            <div className="mx-auto max-w-1000 justify-center items-center flex mt-4"> Nothing to show.</div>
        </EventLayout>
    )
}

function EventLayout({data, children}) {
    return(
        <main>
            <section id="hero">
                <div className="bg-gray-400 relative">
                    <img src={data?.featuredImage?.path || ""} alt="" width={1000}
                         height={150} className="w-full filter brightness-50 h-60 pointer-events-none"/>
                    <h1 className="text-center text-4xl font-bold text-white absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2">
                        Events
                    </h1>
                </div>
            </section>
            {children}
        </main>
    )
}
