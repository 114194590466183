import {Link} from "react-router-dom";
import routes from "../../constants/routes";

export default function EducationMainMenuWithBackground({background, height}) {
    return (
        <div style={{backgroundImage: `url(${background})`, height: `${height}`}} className="relative bg-fixed">
            <div
                className="absolute top-0 bottom-0 left-0 right-0 bg-gradient-to-b from-black via-transparent to-transparent opacity-90"/>
            <header
                className="max-w-1170 mx-auto flex justify-between items-center pt-4 text-white font-light relative">
                <Link to="/" className="flex items-center">
                    <img src="/assets/images/himalaya_logo.png" alt="" width={60} height={60}/>
                    <h1 className="uppercase ml-3 w-40 leading-tight">Himalaya College of Engineering </h1>
                </Link>
                <nav className="space-x-4">
                    <Link to={`${routes.organization}/white-house-college`}>Home</Link>
                    <Link to={`${routes.organization}/white-house-college/events`}>Events</Link>
                    <Link to={`${routes.organization}/white-house-college/career`}>Career</Link>
                    <Link to={`${routes.organization}/white-house-college/gallery`}>Gallery</Link>
                    <Link to={`${routes.organization}/white-house-college/team`}>Team</Link>
                    <Link to={`${routes.organization}/white-house-college/about`}>About</Link>
                </nav>
            </header>
        </div>
    )
}