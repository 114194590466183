import React from "react"
import ContentLoader from "react-content-loader"

function VoiceSkeleton({height}) {
    return (
        <ContentLoader
            speed={2}
            width={636}
            height={height}
            viewBox={`0 0 636 ${height}`}
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
        >
            <circle cx="31" cy="31" r="15"/>
            <rect x="58" y="18" rx="2" ry="2" width="140" height="10"/>
            <rect x="58" y="34" rx="2" ry="2" width="140" height="10"/>
            <rect x="0" y="60" rx="2" ry="2" width="600" height="292"/>
        </ContentLoader>
    )
}


export default VoiceSkeleton;

