import {SRLWrapper} from "simple-react-lightbox";
import {useGetOrgGalleryImagesQuery} from "../services/directoryApi";
import {useParams} from "react-router-dom";
import PageTransitionLoader from "../components/PageTransitionLoader";
import {Helmet} from "react-helmet-async";

export default function GenericOrganizationGallery({data}) {

    const param = useParams();
    const {isFetching, data: galleryData} = useGetOrgGalleryImagesQuery(param.slug);

    if (isFetching) return (
        <GalleryLayout data={data}>
            <div className="flex justify-center items-center flex-col h-72 bg-bColor-50">
                <PageTransitionLoader size={28}/>
                <span className="text-sm">LOADING</span>
            </div>
        </GalleryLayout>
    )

    if (!isFetching && galleryData.organizationWithGalleryImages) {
        return (
            <GalleryLayout data={data}>
                <section id="gallery">
                    <div className="mx-auto max-w-1000">
                        <SRLWrapper>
                            <div className="my-16 grid grid-cols-4 gap-8">
                                {galleryData.organizationWithGalleryImages.gallery?.map((current, i) => {
                                    return <a href={current.path} key={i}>
                                        <img src={current.thumb} alt="" width={200} height={200}
                                             className="rounded-2xl cursor-pointer h-48" loading={"lazy"}/>
                                    </a>

                                })}
                            </div>
                        </SRLWrapper>
                    </div>
                </section>
            </GalleryLayout>
        )
    }

    return (
        <GalleryLayout data={data}>
            <div className="mx-auto max-w-1000 justify-center items-center flex mt-4"> Nothing to show.</div>
        </GalleryLayout>
    )
}

function GalleryLayout({data, children}) {
    return (
        <>
            <Helmet>
                <meta property="og:title" content={`Gallery | ${data.name}`}/>
                <meta property="og:url" content={`https://findallnepal.com/${data.slug}/gallery`}/>
                <link rel="canonical" href={`https://findallnepal.com/${data.slug}/gallery`}/>
                <title>{`Gallery | ${data.name}`}</title>
            </Helmet>
            <main>
                <section id="hero">
                    <div className="bg-gray-400 w-full h-64 relative">
                        <div className="bg-transparent w-full h-64 bg-center bg-no-repeat filter brightness-50"
                             style={{backgroundImage: `url(${data.featuredImage?.path})` || ""}}/>
                        <h1 className="text-center text-4xl font-bold text-white absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2">
                            Gallery
                        </h1>
                    </div>
                </section>
                {children}
            </main>
        </>
    )
}
