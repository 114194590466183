import EducationTopMenu from "../../components/education/EducationTopMenu";
import EducationMainMenuWithBackground from "../../components/education/EducationMainMenuWithBackground";
import {Link} from "react-router-dom";
import EducationFooter from "../../components/education/EducationFooter";
import routes from "../../constants/routes";

export default function Career() {
    return (
        <>
            <section id="hero">
                <EducationTopMenu/>
                <EducationMainMenuWithBackground background="/assets/images/edu_events_bg.png" height="25rem"/>
            </section>
            <main className="my-10">
                <div className="max-w-1170 mx-auto">
                    <h2 className="text-3xl font-bold mb-6 text-black">CAREER</h2>
                    <div className="grid gap-3">
                        <article className="flex bg-gray-100 space-x-4 p-4 items-center">
                            <img src="/assets/images/event_thumb2.png" alt="" className="w-28"/>
                            <div className="flex-grow">
                                <h3 className="text-black text-xl font-bold mb-3">Networking Teacher Wanted</h3>
                                <ul className="text-gray-400 space-y-1.5">
                                    <li>
                                        <img className="inline-block" src="/assets/images/grey_pin.svg" alt=""/>
                                        <span className="ml-3 inline-block">Chyasal, Lalitpur</span>
                                    </li>
                                    <li>
                                        <img className="inline-block w-3.5" src="/assets/images/grey_calender.svg"
                                             alt=""/>
                                        <span className="ml-3 inline-block">15 jul, 2021</span>
                                    </li>
                                </ul>
                            </div>
                            <Link to={`${routes.organization}/white-house-college/career/some-job-title`}
                                  className="px-8 py-2 bg-education text-white rounded-sm">Apply Now</Link>
                        </article>
                        <article className="flex bg-gray-100 space-x-4 p-4 items-center">
                            <img src="/assets/images/event_thumb2.png" alt="" className="w-28"/>
                            <div className="flex-grow">
                                <h3 className="text-black text-xl font-bold mb-3">Networking Teacher Wanted</h3>
                                <ul className="text-gray-400 space-y-1.5">
                                    <li>
                                        <img className="inline-block" src="/assets/images/grey_pin.svg" alt=""/>
                                        <span className="ml-3 inline-block">Chyasal, Lalitpur</span>
                                    </li>
                                    <li>
                                        <img className="inline-block w-3.5" src="/assets/images/grey_calender.svg"
                                             alt=""/>
                                        <span className="ml-3 inline-block">15 jul, 2021</span>
                                    </li>
                                </ul>
                            </div>
                            <Link to={`${routes.organization}/white-house-college/career/some-job-title`}
                                  className="px-8 py-2 bg-education text-white rounded-sm">Apply Now</Link>
                        </article>
                        <article className="flex bg-gray-100 space-x-4 p-4 items-center">
                            <img src="/assets/images/event_thumb2.png" alt="" className="w-28"/>
                            <div className="flex-grow">
                                <h3 className="text-black text-xl font-bold mb-3">Networking Teacher Wanted</h3>
                                <ul className="text-gray-400 space-y-1.5">
                                    <li>
                                        <img className="inline-block" src="/assets/images/grey_pin.svg" alt=""/>
                                        <span className="ml-3 inline-block">Chyasal, Lalitpur</span>
                                    </li>
                                    <li>
                                        <img className="inline-block w-3.5" src="/assets/images/grey_calender.svg"
                                             alt=""/>
                                        <span className="ml-3 inline-block">15 jul, 2021</span>
                                    </li>
                                </ul>
                            </div>
                            <Link to={`${routes.organization}/white-house-college/career/some-job-title`}
                                  className="px-8 py-2 bg-education text-white rounded-sm">Apply Now</Link>
                        </article>
                        <article className="flex bg-gray-100 space-x-4 p-4 items-center">
                            <img src="/assets/images/event_thumb2.png" alt="" className="w-28"/>
                            <div className="flex-grow">
                                <h3 className="text-black text-xl font-bold mb-3">Networking Teacher Wanted</h3>
                                <ul className="text-gray-400 space-y-1.5">
                                    <li>
                                        <img className="inline-block" src="/assets/images/grey_pin.svg" alt=""/>
                                        <span className="ml-3 inline-block">Chyasal, Lalitpur</span>
                                    </li>
                                    <li>
                                        <img className="inline-block w-3.5" src="/assets/images/grey_calender.svg"
                                             alt=""/>
                                        <span className="ml-3 inline-block">15 jul, 2021</span>
                                    </li>
                                </ul>
                            </div>
                            <Link to={`${routes.organization}/white-house-college/career/some-job-title`}
                                  className="px-8 py-2 bg-education text-white rounded-sm">Apply Now</Link>
                        </article>
                        <article className="flex bg-gray-100 space-x-4 p-4 items-center">
                            <img src="/assets/images/event_thumb2.png" alt="" className="w-28"/>
                            <div className="flex-grow">
                                <h3 className="text-black text-xl font-bold mb-3">Networking Teacher Wanted</h3>
                                <ul className="text-gray-400 space-y-1.5">
                                    <li>
                                        <img className="inline-block" src="/assets/images/grey_pin.svg" alt=""/>
                                        <span className="ml-3 inline-block">Chyasal, Lalitpur</span>
                                    </li>
                                    <li>
                                        <img className="inline-block w-3.5" src="/assets/images/grey_calender.svg"
                                             alt=""/>
                                        <span className="ml-3 inline-block">15 jul, 2021</span>
                                    </li>
                                </ul>
                            </div>
                            <Link to={`${routes.organization}/white-house-college/career/some-job-title`}
                                  className="px-8 py-2 bg-education text-white rounded-sm">Apply Now</Link>
                        </article>
                    </div>
                </div>
            </main>
            <EducationFooter/>
        </>
    )
}