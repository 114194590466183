import {configureStore as reduxConfigureStore} from "@reduxjs/toolkit";
// import reducer from "./reducer";
import {directoryApi} from "../services/directoryApi";
import userReducers from "./userSlice";
import voiceReducers from "./voiceSlice";
import newsReducers from "./newsSlice";
import {newsApi} from "../services/newsApi";
import {voiceApi} from "../services/voiceApi";


export default function configureStore() {
    return reduxConfigureStore({
        reducer: {
            [directoryApi.reducerPath]: directoryApi.reducer,
            [newsApi.reducerPath]: newsApi.reducer,
            [voiceApi.reducerPath]: voiceApi.reducer,
            user: userReducers,
            news: newsReducers,
            voice: voiceReducers,
        },
        middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(directoryApi.middleware, newsApi.middleware, voiceApi.middleware),
        devTools: process.env.NODE_ENV === "development",
    })
}