import GenericOrgProductTab from "../components/GenericOrgProductTab";
import GenericOrgViewMoreBtn from "../components/GenericOrgViewMoreBtn";
import routes from "../constants/routes";
import {SRLWrapper} from "simple-react-lightbox";
import millify from "millify";
import Carousel from "react-multi-carousel";
import {Helmet} from "react-helmet-async";

export default function GenericOrganizationHome({data}) {

    return (
        <>
            <Helmet>
                <meta property="og:title" content={`${data.name}`}/>
                <meta property="og:url" content={`https://findallnepal.com/${data.slug}`}/>
                <link rel="canonical" href={`https://findallnepal.com/${data.slug}`}/>
                <title>{`${data.name}`}</title>
            </Helmet>
            <main>
                <section id="hero">
                    <div className="bg-gray-400 w-full h-80 bg-center bg-no-repeat"
                         style={{backgroundImage: `url(${data.featuredImage?.path})` || ""}}/>
                    <div className="bg-gray-200">
                        <div className="mx-auto max-w-1000 flex justify-center text-center py-6 space-x-16">
                            <div>
                                <span className="block text-2xl">{millify(data.likes)}</span><span
                                className="text-gray-400">Likes</span>
                            </div>
                            <div>
                                <span className="block text-2xl">{millify(data.views)}</span><span
                                className="text-gray-400">Views</span>
                            </div>
                            <div>
                                <span className="block text-2xl">{millify(data.searches)}</span><span
                                className="text-gray-400">Searches</span>
                            </div>
                            <div>
                                <span className="block text-2xl">{millify(data.comments)}</span><span
                                className="text-gray-400">Comments</span>
                            </div>
                            <div>
                                <span className="block text-2xl">2</span><span className="text-gray-400">Shares</span>
                            </div>
                        </div>
                    </div>
                </section>

                <GenericOrgProductTab items={data.featured_slider}/>

                {(data.featuredEvents?.length > 0) &&
                    <section id="events" className="py-8 bg-gray-200 mt-4">
                        <div className="mx-auto max-w-1000 relative flex">
                            <div className="mr-4 text-right">
                                <span className="bg-bColor text-white rounded-full px-3.5 py-1 text-sm">New</span>
                                <strong className="block text-2xl">Events:</strong>
                            </div>
                            <div className="flex justify-between space-x-4">
                                {data.featuredEvents?.map(current => {
                                    return (
                                        <article className="max-w-xs bg-white rounded-full px-3 py-1 flex items-center"
                                                 key={current.slug}>
                                            <div className="flex justify-center items-center">
                                                <img
                                                    src={`${current.featuredImage?.path || "/assets/images/logo_only.png"}`}
                                                    alt=""
                                                    width={38} className="w-9 h-9 rounded-full pointer-events-none"/>
                                            </div>
                                            <div className="mx-4">
                                                <strong
                                                    className="block">{`${current.name.slice(0, 18)}${current.name.length > 25 ? "..." : ""}`}</strong>
                                                <span
                                                    className="text-xs text-gray-400">{new Date(current.from).toDateString()}</span>
                                            </div>
                                        </article>
                                    )
                                })}
                            </div>
                            <div
                                className="absolute right-0 bg-gradient-to-r from-transparent via-gray-200 to-gray-200 transform translate-y-1/2 bottom-1/2 pl-24 py-10">
                                <GenericOrgViewMoreBtn to={`/${data.slug}/events`}/>
                            </div>
                        </div>
                    </section>
                }

                <section id="gallery" className="py-20">
                    <div className="mx-auto max-w-1000">
                        <h2 className="text-center text-4xl font-bold">Gallery</h2>
                        <SRLWrapper>
                            <div className="my-16 grid grid-cols-4 gap-8">
                                {data.featuredGallery?.filter((current, index) => index < 4).map((current, index) => {
                                    return <a href={current.path} key={index}>
                                        <img src={current.thumb} alt="" width={200} height={200} key={current.slug}
                                             className="rounded-2xl cursor-pointer h-48" loading={"lazy"}/>
                                    </a>


                                })}
                            </div>
                        </SRLWrapper>
                        <GenericOrgViewMoreBtn to={`/${data.slug}/gallery`}/>
                    </div>
                </section>

                <section id="message-from-ceo" className="py-20 bg-gray-200">
                    <div className="mx-auto max-w-1000">
                        <Carousel
                            responsive={{desktop: {breakpoint: {max: 3000, min: 0}, items: 1}}} showDots
                            slidesToSlide={1} infinite arrows={false} autoPlay pauseOnHover
                        >
                            {data.feature_message?.map(current => {
                                return (
                                    <article key={current.slug}>
                                        <h2 className="text-center text-4xl font-bold">{`${current.message || "Message from"} ${current?.from}`}</h2>
                                        <div className="my-16 bg-white rounded-2xl p-10 flex justify-start space-x-8">
                                            <div>
                                                <div className="relative w-200 mt-5">
                                                    <img src="/assets/images/white-quote.png" alt="" width={100}
                                                         height={100}
                                                         className="absolute -top-5 left-0"/>
                                                    <img src={current.image.path} alt="" width={200} height={200}
                                                         className="rounded-full w-full"/>
                                                </div>
                                            </div>
                                            <div className="text-gray-500 flex-grow"
                                                 dangerouslySetInnerHTML={{__html: current.desc}}/>
                                        </div>
                                    </article>
                                )
                            })}
                        </Carousel>
                    </div>
                </section>
                {/*<section id="testimonials" className="py-20">*/}
                {/*    <div className="mx-auto max-w-1000">*/}
                {/*        <h2 className="text-center text-4xl font-bold">Customer Testimonials</h2>*/}
                {/*        <div className="my-16 flex justify-center space-x-10">*/}
                {/*            <article className="border border-gray-400 rounded-2xl p-9 w-full max-w-xs space-y-6">*/}
                {/*                <img src="/assets/images/white-quote.png" alt="" width={100} height={100}/>*/}
                {/*                <div className="text-center text-gray-500">*/}
                {/*                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium aliquid aperiam*/}
                {/*                    dolorum eos, et facilis id laborum magni non nulla quaerat quam rem, tenetur ullam*/}
                {/*                    veniam veritatis voluptate voluptates voluptatibus?*/}
                {/*                </div>*/}
                {/*                <div className="flex space-x-4">*/}
                {/*                    <div>*/}
                {/*                        <img src="https://picsum.photos/50/50" alt="" width={50} height={50}*/}
                {/*                             className="rounded-full"/>*/}
                {/*                    </div>*/}
                {/*                    <div>*/}
                {/*                        <strong className="text-2xl block">Nirajan Rawal</strong>*/}
                {/*                        <span className="italic text-gray-400">Create Director</span>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </article>*/}
                {/*        </div>*/}
                {/*        <GenericOrgViewMoreBtn to={`/${data.slug}/gallery`}/>*/}
                {/*    </div>*/}
                {/*</section>*/}

                <section id="review" className="py-20 bg-gray-200">
                    <div className="mx-auto max-w-1000">
                        <h2 className="text-center text-4xl font-bold">Give Review & Comment</h2>
                        <div className="my-16 flex justify-center space-x-9">
                            <div className="bg-white rounded-2xl px-8 py-14 max-w-xs w-full text-center space-y-6">
                                <span>If not Logged In</span>
                                <a href={`//account.${routes.siteUrl}/signin?goto=${window.location.href}`}
                                   className="rounded-full font-bold bg-bColor text-white py-3.5 px-6 block">
                                    Login
                                </a>
                                <span className="block">Or Using</span>
                                <div className="space-y-4">
                                    <input type="email" className="rounded-full bg-gray-200 w-full py-3.5 px-7"
                                           placeholder="Email/Username"/>
                                    <input type="text" className="rounded-full bg-gray-200 w-full py-3.5 px-7"
                                           placeholder="Full Name"/>
                                </div>
                            </div>
                            <div className="bg-white rounded-2xl px-8 py-14 flex-grow">
                            <textarea name="message" id="message" rows="7" className="w-full p-4"
                                      placeholder="Write your message"/>
                                <div className="text-right mt-4">
                                    <button type="submit"
                                            className="bg-gray-200 rounded-full py-3 px-8 font-bold">Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}