function handleError(error) {
    if (error.response) {
        return {message: error.response.data.payload?._io || "Error occurred."}
    } else if (error.request) {
        console.log(error.request)
        return {message: "Check your internet connection."};
    } else {
        return {message: "Something went wrong."};
    }
}

export default handleError;