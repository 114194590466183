import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import {baseUrlVoice} from "../constants/endpoints";
import {doGetComments, doGetMoreComments, doLikeUnLikeCommentPopup, doPostCommentPopUp} from "./voiceSlice";
import _ from "lodash";
import handleError from "../network/handleError";

const initialState = {
    fetch: {
        isLoading: false,
        isFetchingMore: false,
        list: {comments: []},
        isError: {status: false, message: ""},
    },
    create: {
        isSuccess: false,
        isLoading: false,
        isError: {status: false},
        text: "",
    }
}


const doGetNewsComments = createAsyncThunk(
    "news/doGetNewsComments",
    async (data = null, thunkAPI) => {
        try {
            const response = await axios.get(`${baseUrlVoice}/comment?superman=nw&_sp=${data.slug}`, {
                withCredentials: true,
            });
            return response.data;
        } catch (error) {
            // const {message} = error.payload
            // console.log(message)
            return thunkAPI.rejectWithValue(error)
        }
    }
)

const doGetMoreNewsComments = createAsyncThunk(
    "news/doGetMoreNewsComments",
    async (data = null, thunkAPI) => {
        try {
            const response = await axios.get(`${baseUrlVoice}/comment?superman=nw&_sp=${data.slug}&next=${data.token}`, {
                withCredentials: true,
            });
            return response.data;
        } catch (error) {
            // const {message} = error.payload
            // console.log(message)
            return thunkAPI.rejectWithValue(error)
        }
    }
)

const doLikeUnLikeCommentPopupNews = createAsyncThunk(
    "news/doLikeUnLikeCommentPopupNews",
    async (data, thunkAPI) => {
        try {
            if (data.action === "like") {
                const response = await axios.post(`${baseUrlVoice}/like?_sp=${data.id}&superman=nc`, "", {
                    withCredentials: true,
                });
                return response.data;
            } else if (data.action === "unlike") {
                const response = await axios.delete(`${baseUrlVoice}/like?_sp=${data.id}&superman=nc`, {
                    withCredentials: true,
                });
                return response.data;
            }
        } catch (error) {
            const errorMessage = handleError(error)
            return thunkAPI.rejectWithValue(errorMessage);
        }
    }
)

const doPostCommentPopUpNews = createAsyncThunk(
    "news/doPostCommentPopUpNews",
    async (data = null, thunkAPI) => {
        try {
            const payload = data.id
                ? {_sp: data.slug, _olo: 2, _io: data.comment, _ol: data.id}
                : {_sp: data.slug, _olo: 2, _io: data.comment}
            const response = await axios.post(`${baseUrlVoice}/comment?superman=nw`,
                payload, {withCredentials: true,}
            );
            return response.data;
        } catch (error) {
            // const {message} = error.payload
            // console.log(message)
            return thunkAPI.rejectWithValue(error)
        }
    }
)



const newsSlice = createSlice({
        name: "voice",
        initialState,
        reducers: {},
        extraReducers: {
            //fetch comments on popup
            [doGetNewsComments.pending]: (state) => {
                state.fetch = {
                    isLoading: true,
                    list: {comments: []},
                    isError: {status: false, message: ""},
                }
            },
            [doGetNewsComments.fulfilled]: (state, {payload}) => {
                state.fetch = {
                    isLoading: false,
                    list: {...payload.payload},
                    isError: {status: false, message: ""},
                }
            },
            [doGetNewsComments.rejected]: (state) => {
                state.fetch = {
                    isLoading: false,
                    list: {comments: []},
                    isError: {status: true, message: "Sorry some error occurred. Reload the page to try again."},
                }
            },


            //fetch more previous comments on popup
            [doGetMoreNewsComments.pending]: (state) => {
                state.fetch = {
                    ...state.fetch,
                    isFetchingMore: true,
                    isError: {status: false, message: ""},
                }
            },
            [doGetMoreNewsComments.fulfilled]: (state, {payload}) => {
                state.fetch = {
                    ...state.fetch,
                    isFetchingMore: false,
                    list: {
                        comments: [...state.fetch.list.comments, ...payload.payload.comments],
                        hasMore: payload.payload.hasMore,
                        next: payload.payload.next,
                    },
                    isError: {status: false, message: ""},
                }
            },
            [doGetMoreNewsComments.rejected]: (state) => {
                state.fetch = {
                    isFetchingMore: false,
                    isLoading: false,
                    list: {comments: []},
                    isError: {status: true, message: "Sorry some error occurred. Reload the page to try again."},
                }
            },


            //like a comment
            [doLikeUnLikeCommentPopupNews.pending]: (state, action) => {
                const {id, index, path, action: actionType} = action.meta.arg
                const fullPath = `${path}[${index}]`;
                const comment = _.get(state.fetch.list, fullPath)
                comment.isLiked = (actionType === "like")
                comment.likes = (actionType === "like") ? comment.likes + 1 : comment.likes - 1
                _.set(state.fetch.list, fullPath, comment)
            },
            [doLikeUnLikeCommentPopupNews.fulfilled]: (state, {payload}) => {
            },
            [doLikeUnLikeCommentPopupNews.rejected]: (state, action) => {
                const {id, index, path, action: actionType} = action.meta.arg
                const fullPath = `${path}[${index}]`;
                const comment = _.get(state.fetch.list, fullPath)
                comment.isLiked = !(actionType === "like")
                comment.likes = (actionType === "like") ? comment.likes - 1 : comment.likes + 1
                _.set(state.fetch.list, fullPath, comment)
            },


            //do post create new comment
            [doPostCommentPopUpNews.pending]: (state, action) => {
                // state.comments.create.isLoading = true;
                // state.comments.create.isSuccess = false;
                // state.comments.create.isError.status = false;
            },
            [doPostCommentPopUpNews.fulfilled]: (state, action) => {
                // const currentSlug = action.meta.arg.slug;
                // state.comments.create.text = "";
                // state.data.posts = state.data.posts.map(item => {
                //     if (item.slug === currentSlug) {
                //         item.comments.push(action.payload.payload)
                //         return item
                //     }
                //     return item
                // })
                // state.comments.create.isLoading = false;
                // state.comments.create.isSuccess = true;
                const {path, id} = action.meta.arg;
                if (id) {
                    const comment = _.get(state.fetch.list, path)
                    if (comment.reply) {
                        comment.reply.push(action.payload.payload)
                    }else {
                        comment.reply = []
                        comment.reply.push(action.payload.payload)
                    }
                    _.set(state.fetch.list, path, comment)
                } else {
                    const comment = _.get(state.fetch.list, path)
                    comment.unshift(action.payload.payload)
                    _.set(state.fetch.list, path, comment)
                }
            },
            [doPostCommentPopUpNews.rejected]: (state, action) => {
                // state.comments.create.isLoading = false;
                // state.comments.create.isError.status = true;
                // state.comments.create.isSuccess = false;
            },
        }
    });


export default newsSlice.reducer;

export {doGetMoreNewsComments, doGetNewsComments, doLikeUnLikeCommentPopupNews, doPostCommentPopUpNews};