import JobsHeader from "./JobsHeader";
import MainFooter from "./MainFooter";

export default function JobsSingle() {
    return (
        <>
            <div className="flex-grow">
                <JobsHeader/>
                <main className="mx-4 mx-auto max-w-4xl space-y-16 ">
                    <div className="my-8">
                        <img className="max-w-4xl w-full" src="/assets/images/post-image.png" alt="post_image"/>
                    </div>
                    <section id="heading" className="flex justify-between items-start">
                        <div>
                            <h2 className="font-bold text-2xl">Marketing Manager</h2>
                            <span>Find All Nepal Pvt. Ltd.</span>
                        </div>
                        <button className="font-bold text-bColor">
                            <img className="w-5 mr-2 inline" src="/assets/images/ICON_SHARE.svg" alt=""/>
                            Share
                        </button>
                    </section>
                    <section id="specification">
                        <h3 className="font-bold text-xl mb-4">Normal Specification</h3>
                        <div className="grid grid-cols-3 max-w-3xl mb-1">
                            <span>Salary</span>
                            <span>:</span>
                            <span>25,000-40,000</span>
                        </div>
                        <div className="grid grid-cols-3 max-w-3xl mb-1">
                            <span>Start Data</span>
                            <span>:</span>
                            <span>15th Jul, 2021</span>
                        </div>
                        <div className="grid grid-cols-3 max-w-3xl mb-1">
                            <span>End Data</span>
                            <span>:</span>
                            <span>25th Jul, 2021</span>
                        </div>
                        <div className="grid grid-cols-3 max-w-3xl mb-1">
                            <span>Job Location</span>
                            <span>:</span>
                            <span>Pokhara</span>
                        </div>
                        <div className="grid grid-cols-3 max-w-3xl mb-1">
                            <span>Experience</span>
                            <span>:</span>
                            <span>2 Years</span>
                        </div>
                        <div className="grid grid-cols-3 max-w-3xl mb-1">
                            <span>Education Level</span>
                            <span>:</span>
                            <span>Bachelor's Degree Complete</span>
                        </div>
                    </section>
                    <section id="responsibility">
                        <h3 className="font-bold text-xl mb-4">Responsibility</h3>
                        <ul className="max-w-3xl">
                            <li>Develop and implement creative ideas and concepts.</li>
                            <li>Collaborate with graphic designer to develop design concepts for defined projects.</li>
                            <li>Conceptualize visuals based on requirements.</li>
                            <li>Develop and assist graphic designer in presentation design, infographic design, brochure
                                design, logo design, icon design, video editing.
                            </li>
                            <li>Ensure final graphics and layouts are visually appealing and on-brand.</li>
                            <li>Understanding of layout, design, and typography.</li>
                            <li>Helping Graphics Designer with daily jobs.</li>
                            <li>Assist in providing training to staffs as and when required.</li>
                        </ul>
                    </section>
                    <section id="description">
                        <h3 className="font-bold text-xl mb-4">Job Description</h3>
                        <ul className="max-w-3xl">
                            <li>Bachelor’s Degree in Arts of Industrial Design, Management, or similar field.</li>
                            <li>Proven graphic designing experience.</li>
                            <li>Excellent communication skills.</li>
                            <li>Commitment to quality work and strong attention to detail.</li>
                            <li>Ability, initiative, and commitment to be a part of a culture of excellence.</li>
                            <li>Ability to work well within a team.</li>
                            <li>Excellent time management skills.</li>
                            <li>A talent for creativity and problem-solving.</li>
                            <li>Must be very comfortable with continuously changing opportunities and priorities.</li>
                            <li>Ability to manage multiple projects, and to meet deadlines.</li>
                        </ul>
                    </section>
                    <button className="bg-red-600 font-bold text-xl py-2 px-10 text-white">Apply Now</button>
                    <section id="recommended" className="pt-12 border-t border-gray-200">
                        <h3 className="font-bold text-xl mb-4">Recommended For You</h3>
                        <div className="flex justify-between mt-6 font-medium space-x-6 text-center">
                            <a href="/" className="w-40">
                                <div
                                    className="p-4 bg-gray-200 w-full h-auto rounded-md flex justify-center items-center">
                                    <img className="w-20" src="/assets/images/aaa.png" alt="logo"/>
                                </div>
                                <span className="mt-2 block">Graphics Designer</span>
                            </a>
                            <a href="/" className="w-40">
                                <div
                                    className="p-4 bg-gray-200 w-full h-auto rounded-md flex justify-center items-center">
                                    <img className="w-20" src="/assets/images/aaa.png" alt="logo"/>
                                </div>
                                <span className="mt-2 block">Graphics Designer</span>
                            </a>
                            <a href="/" className="w-40">
                                <div
                                    className="p-4 bg-gray-200 w-full h-auto rounded-md flex justify-center items-center">
                                    <img className="w-20" src="/assets/images/aaa.png" alt="logo"/>
                                </div>
                                <span className="mt-2 block">Graphics Designer</span>
                            </a>
                            <a href="/" className="w-40">
                                <div
                                    className="p-4 bg-gray-200 w-full h-auto rounded-md flex justify-center items-center">
                                    <img className="w-20" src="/assets/images/aaa.png" alt="logo"/>
                                </div>
                                <span className="mt-2 block">Graphics Designer</span>
                            </a>
                            <a href="/" className="w-40">
                                <div
                                    className="p-4 bg-gray-200 w-full h-auto rounded-md flex justify-center items-center">
                                    <img className="w-20" src="/assets/images/aaa.png" alt="logo"/>
                                </div>
                                <span className="mt-2 block">Graphics Designer</span>
                            </a>
                        </div>

                    </section>
                </main>
            </div>
            <MainFooter/>
        </>
    )
}